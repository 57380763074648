import React, { Fragment, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { ODRDirector, ODRMIIEmpanelment, Organisation } from '../../../swagger/models';
import { useCombinedStore } from '../../../store';
import { useTranslation } from 'react-i18next';
import { Tooltip } from "react-tippy";

interface ODRInstitutionDetailsInterface {
  orgId: number;
  orgDetail: Organisation;
  errors: any;
  register: any;
  setValue: any;
}

interface ODRFormData {
  incorporationNumber: string;
  ISINNumber: string;
  directors: ODRDirector[];
  kmps: ODRDirector[];
  selectedMIIEmpanelments: ODRMIIEmpanelment[];
}

export interface ODRInstitutionDetailsRef {
  getODRData: () => ODRFormData;
}
/**
 * Form component for ODR Institution Details.
 *
 * This component renders a form for editing ODR Institution Details.
 * The form is divided into two columns: one for ODR Institution Details
 * and the other for Other MII with whom Empaneled.
 *
 * The component uses the `useState` hook to store the form data in the
 * component's state. The `handleChange` function is used to update the
 * state when the user types something in one of the form fields.
 *
 * The component also renders a "Submit" button that triggers a form
 * submission event when clicked. The event handler is passed as a prop
 * to the component.
 *
 * The component expects the following props:
 * - `onSubmit`: A function that is called when the form is submitted.
 *
 * The component returns a JSX element containing the form.
 */

const ODRInstitutionDetails = forwardRef<ODRInstitutionDetailsRef, ODRInstitutionDetailsInterface>(
  ({ orgId, orgDetail, errors, register, setValue }, ref) => {
    const { t } = useTranslation();
    const listOrganisations = useCombinedStore((state: any) => state.listMIIs)
    const organisations: Organisation[] = useCombinedStore((state: any) => state.miis);
    const empanelledMIIs: ODRMIIEmpanelment[] = useCombinedStore((state: any) => state.empanelledMIIs);
    const odrDirectors: ODRDirector[] = useCombinedStore((state: any) => state.odrDirectors);
    const [selectedMIIEmpanelments, setSelectedMIIEmpanlements] = useState<ODRMIIEmpanelment[]>([])
    const [formData, setFormData] = useState<ODRFormData>({
      incorporationNumber: orgDetail.incorporationNumber || '',
      ISINNumber: orgDetail.ISINNumber || '',
      directors: [{
        name: '',
        panNumber: '',
        userType: 'Director',
        orgId: 1
      }],
      kmps: [{
        name: '',
        panNumber: '',
        userType: 'KMP',
        orgId: 1
      }],
      selectedMIIEmpanelments: []
    });

    // Handle changes for simple text inputs (and date inputs)
    const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    };

    // Handle director changes
    const handleDirectorChange = (
      index: number,
      type: string,
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const { name, value } = e.target;
      const updatedDirectors = [...formData.directors];
      updatedDirectors[index] = {
        ...updatedDirectors[index],
        [name]: value,
        orgId: orgId,
        userType: type
      };
      setFormData((prev: ODRFormData) => ({
        ...prev,
        directors: updatedDirectors,
      }));
      // 2. Update form library state
      setValue('directors', updatedDirectors, { shouldValidate: true })
    };

    // Add a new director
    const addDirector = () => {
      setFormData((prev) => ({
        ...prev,
        directors: [
          ...prev.directors,
          {
            name: '',
            panNumber: '',
            userType: 'Director',
            orgId: orgId
          },
        ],
      }));
    };

    // Remove a director
    const removeDirector = (index: number) => {
      const updatedDirectors = [...formData.directors];
      updatedDirectors[index] = {
        ...updatedDirectors[index],
        isDeleted: true
      };
      setFormData((prev) => ({
        ...prev,
        directors: updatedDirectors,
      }));
    };


    // Handle director changes
    const handleKMPChange = (
      index: number,
      type: string,
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const { name, value } = e.target;
      const updatedKMPs = [...formData.kmps];
      updatedKMPs[index] = {
        ...updatedKMPs[index],
        [name]: value,
        orgId: orgId,
        userType: type
      };
      setFormData((prev) => ({
        ...prev,
        kmps: updatedKMPs,
      }));
      // 2. Update form library state
      setValue('kmps', updatedKMPs, { shouldValidate: true })
    };

    // Add a new director
    const addKMP = () => {
      setFormData((prev) => ({
        ...prev,
        kmps: [
          ...prev.kmps,
          {
            name: '',
            panNumber: '',
            userType: 'KMP',
            orgId: orgId
          },
        ],
      }));
    };

    // Remove a director
    const removeKMP = (index: number) => {
      const updatedKMPs = [...formData.kmps];
      updatedKMPs[index] = {
        ...updatedKMPs[index],
        isDeleted: true
      };
      setFormData((prev) => ({
        ...prev,
        kmps: updatedKMPs,
      }));
    };

    // Handle changes for additional MII fields
    const handleMiiFieldChange = (
      index: number,
      e: React.ChangeEvent<any>
    ) => {
      const { name, value } = e.target;
      const updatedMiiDetails = [...selectedMIIEmpanelments];
      updatedMiiDetails[index] = {
        ...updatedMiiDetails[index],
        [name]: value,
      }
      setFormData({
        ...formData,
        selectedMIIEmpanelments: updatedMiiDetails
      })
      console.log(updatedMiiDetails)
      setSelectedMIIEmpanlements(updatedMiiDetails)
    };

    const selectMII = (e: any, orgId: number | null) => {
      const orgParents = selectedMIIEmpanelments || []
      if (e.checked) {
        // Add the Parent MII to the array list
        orgParents.push({
          parentId: orgId,
        })
        setSelectedMIIEmpanlements(orgParents)
      } else {
        const index = orgParents.findIndex((p: ODRMIIEmpanelment) => p.parentId === orgId)
        orgParents.splice(index, 1)
        // Remove the Parent MII from Array list
        setSelectedMIIEmpanlements(orgParents)
      }
      setFormData({
        ...formData,
        selectedMIIEmpanelments: orgParents
      })
    }

    useEffect(() => {
      listOrganisations({
        orgType: 'MII',
        limit: 10,
        offset: 0,
      });
    }, [])

    // Expose the method to get child data
    useImperativeHandle(ref, () => ({
      getODRData: () => {
        return formData
      },
    }));

    useEffect(() => {
      if (odrDirectors?.length > 0) {
        setFormData({
          ...formData,
          directors: odrDirectors.filter((d: ODRDirector) => d.userType === 'Director'),
          kmps: odrDirectors.filter((d: ODRDirector) => d.userType === 'KMP'),
        })
      }
    }, [odrDirectors])

    useEffect(() => {
      if (empanelledMIIs?.length > 0) {
        setSelectedMIIEmpanlements(empanelledMIIs)
      }
    }, [empanelledMIIs])

    useEffect(() => {
      if (orgDetail.orgId) {
        setFormData({
          ...formData,
          ISINNumber: orgDetail.ISINNumber,
          incorporationNumber: orgDetail.incorporationNumber
        })
      }
    }, [orgDetail])

    return (
      <div
        className="flex flex-row justify-between  w-full"
      >
        {/* Left Column: ODR Institution Details */}
        <div className="flex flex-col w-1/4 gap-2 pr-8">
          {/* Registration Certificate Number */}
          <div className="flex flex-col gap-2 mb-4">
            <label className="text-xs">
              {t('RegistrationCertificateNumber')}
            </label>
            <input
              type="text"
              {...register('incorporationNumber')}
              required
              name="incorporationNumber"
              value={formData.incorporationNumber}
              onChange={handleChange}
              className={`rounded-lg border p-2 border-solid border-border-grey`}
            />
            <span className="text-xs text-highlight-red">
              {errors.incorporationNumber && typeof errors.incorporationNumber.message === 'string' ? errors.incorporationNumber.message : ''}
            </span>
          </div>
          {/* Legal Entity Identity Number */}
          <div className="flex flex-col gap-2 mb-4">
            <label className="text-xs">
              {t('LegalEntityIdentityNumber')}
            </label>
            <input
              type="text"
              {...register('ISINNumber')}
              required
              name="ISINNumber"
              value={formData.ISINNumber}
              onChange={handleChange}
              className={`rounded-lg border p-2 border-solid border-border-grey`}
            />
            <span className="text-xs text-highlight-red">
              {errors.ISINNumber && typeof errors.ISINNumber.message === 'string' ? errors.ISINNumber.message : ''}
            </span>
          </div>
          {/* Directors */}
          {formData.directors.map((director: ODRDirector, index: number) => (
            <Fragment key={index}>
              {!director.isDeleted && <Fragment>
                <div className="flex flex-col gap-1 mb-2">
                  <div className='flex justify-between items-center'>
                    <label className="text-xs">{t('NameofDirector', {
                      ind: index + 1
                    })}</label>
                    {index > 0 && (
                      <div className='justify-end flex'>
                        <Tooltip
                          html={
                            <div className=" text-left flex flex-col gap-1">
                              <p>{t('RemoveDirector')}</p>
                            </div>
                          }
                          position="top"
                          arrow={false}
                          animation="shift"
                          size="small"
                          {...({ children: <i onClick={() => removeDirector(index)} className="ph hover:text-highlight-red/[0.4] text-highlight-red cursor-pointer border ph-trash h-fit text-base p-0.5" style={{ borderRadius: '50%' }}></i> } as any)} // Type assertion to bypass TS errors
                        >
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <input
                    type="text"
                    {...register(`directors.${index}.name`)}
                    name="name"
                    value={director.name}
                    onChange={(e) => handleDirectorChange(index, 'Director', e)}
                    className={`rounded-lg border p-2 border-solid border-border-grey`}
                  />
                  <span className="text-xs text-highlight-red">
                    {errors.directors?.[index]?.name?.message}
                  </span>
                </div>
                <div className="flex flex-col gap-1 mb-2">
                  <label className="text-xs">{t('PANofDirector', {
                    ind: index + 1
                  })}</label>
                  <input
                    type="text"
                    {...register(`directors.${index}.panNumber`)}
                    name="panNumber"
                    value={director.panNumber}
                    onChange={(e) => handleDirectorChange(index, 'Director', e)}
                    className={`rounded-lg border p-2 border-solid border-border-grey`}
                  />
                  <span className="text-xs text-highlight-red">
                    {errors.directors?.[index]?.panNumber?.message}
                  </span>
                </div>
              </Fragment>}
            </Fragment>
          ))}
          <div className='w-full justify-end flex'>
            <Tooltip
              html={
                <div className=" text-left flex flex-col gap-1">
                  <p>{t('AddDirector')}</p>
                </div>
              }
              position="top"
              arrow={false}
              animation="shift"
              size="small"
              {...({
                children:
                  <div onClick={addDirector} className='flex gap-1 items-center text-label-grey hover:text-gray-900 cursor-pointer'>
                    <i className="ph ph-plus h-fit text-xs"></i>
                    <p className='text-xs'>Add</p>
                  </div>
              } as any)} // Type assertion to bypass TS errors
            >
            </Tooltip>
          </div>
          {formData.kmps.map((kmp, index) => (
            <Fragment key={index}>
              {!kmp.isDeleted &&
                <Fragment>
                  <div className="flex flex-col gap-1 mb-2">
                    <div className='flex justify-between items-center'>
                      <label className="text-xs">{t('NameofKMP', {
                        ind: index + 1
                      })}</label>
                      {index > 0 && (
                        <div className='justify-end flex'>
                          <Tooltip
                            html={
                              <div className=" text-left flex flex-col gap-1">
                                <p>{t('RemoveKMP')}</p>
                              </div>
                            }
                            position="top"
                            arrow={false}
                            animation="shift"
                            size="small"
                            {...({ children: <i onClick={() => removeKMP(index)} className="ph hover:text-highlight-red/[0.4] text-highlight-red cursor-pointer border ph-trash h-fit text-base p-0.5" style={{ borderRadius: '50%' }}></i> } as any)} // Type assertion to bypass TS errors
                          >
                          </Tooltip>
                        </div>
                      )}
                    </div>

                    <input
                      type="text"
                      {...register(`kmps.${index}.name`)}
                      name="name"
                      value={kmp.name}
                      onChange={(e) => handleKMPChange(index, 'KMP', e)}
                      className={`rounded-xl border p-2 border-solid border-border-grey`}
                    />
                    <span className="text-xs text-highlight-red">
                      {errors.kmps?.[index]?.name?.message}
                    </span>
                  </div>
                  <div className="flex flex-col gap-1 mb-2">
                    <label className="text-xs">{t('PANofKMP', {
                      ind: index + 1
                    })}</label>
                    <input
                      type="text"
                      {...register(`kmps.${index}.panNumber`)}
                      name="panNumber"
                      value={kmp.panNumber}
                      onChange={(e) => handleKMPChange(index, 'KMP', e)}
                      className={`rounded-xl border p-2 border-solid border-border-grey`}
                    />
                    <span className="text-xs text-highlight-red">
                      {errors.kmps?.[index]?.panNumber?.message}
                    </span>
                  </div>
                </Fragment>
              }
            </Fragment>
          ))}
          <div className='w-full justify-end flex'>
            <Tooltip
              html={
                <div className=" text-left flex flex-col gap-1">
                  <p>{t('AddKMP')}</p>
                </div>
              }
              position="top"
              arrow={false}
              animation="shift"
              size="small"
              {...({
                children:
                  <div onClick={addKMP} className='flex gap-1 items-center text-label-grey hover:text-gray-900 cursor-pointer'>
                    <i className="ph ph-plus h-fit text-xs"></i>
                    <p className='text-xs'>Add</p>
                  </div>
              } as any)} // Type assertion to bypass TS errors
            >
            </Tooltip>
          </div>
        </div>

        {/* Right Column: Other MII with whom Empaneled */}
        <div className="flex flex-col space-y-4 w-3/4">
          <p className="text-s font-semibold">{t('MIIEmpanelmentDetails')}</p>
          {/* Render checkboxes for each MII */}
          <div className='grid grid-flow-rows grid-cols-7 gap-2'>
            {organisations.map((mii: Organisation) => (
              <div key={mii.orgId}>
                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    disabled={selectedMIIEmpanelments?.some(i => i.parentId === mii.orgId && i.empanelmentId)}
                    checked={selectedMIIEmpanelments?.some(i => i.parentId === mii.orgId)}
                    onChange={(e) => selectMII(e.target, mii?.orgId)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <span className="font-medium">{mii.organisationName}</span>
                </div>
              </div>
            ))}
          </div>

          {/* All input fields for selected MIIs after all checkboxes */}
          <div className="mt-6 space-y-2">
            {selectedMIIEmpanelments?.length > 0 && (
              <div className="space-y-0">
                {organisations
                  .filter(mii => selectedMIIEmpanelments?.some(i => i.parentId === mii.orgId))
                  .map((mii, index) => (
                    <div key={mii.orgId} className="pr-4 py-4">
                      <div className="space-y-4">
                        <div className="flex flex-col">
                          <label className="text-xs">{t('TypeofEmpanelmentWith', {
                            miiName: mii.organisationName
                          })}</label>
                          <select
                            onChange={(e) => handleMiiFieldChange(index, e)}
                            value={selectedMIIEmpanelments.find(i => i.parentId === mii.orgId)?.typeOfEmpanelment}
                            disabled={selectedMIIEmpanelments.find(i => i.parentId === mii.orgId)?.typeOfEmpanelment === 'Primary' && selectedMIIEmpanelments.find(i => i.parentId === mii.orgId)?.empanelmentId}
                            aria-label={'typeOfEmpanelment'}
                            required
                            name='typeOfEmpanelment'
                            id='typeOfEmpanelment'
                            className="text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg"
                          >
                            <option selected disabled>{t('Select')}</option>
                            <option value="Primary">Primary</option>
                            <option value="Secondary">Secondary</option>
                          </select>
                        </div>
                        <div className="flex flex-col">
                          <label className="text-xs">{t('DateofEmpanelmentWith', {
                            miiName: mii.organisationName
                          })}</label>
                          <input
                            type="date"
                            name="empanelmentDate"
                            value={selectedMIIEmpanelments.find(i => i.parentId === mii.orgId)?.empanelmentDate}
                            onChange={(e) => handleMiiFieldChange(index, e)}
                            className="text-xs py-3 text-label-grey pl-3 border border-border-grey rounded-lg"
                          />
                        </div>
                        <div className="flex flex-col">
                          <label className="text-xs">{t('DateofLeaving', {
                            miiName: mii.organisationName
                          })}</label>
                          <input
                            type="date"
                            name="leavingEmpanelmentDate"
                            value={selectedMIIEmpanelments.find(i => i.parentId === mii.orgId)?.leavingEmpanelmentDate}
                            onChange={(e) => handleMiiFieldChange(index, e)}
                            className="text-xs py-3 text-label-grey pl-3 border border-border-grey rounded-lg"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>

        </div>
      </div>
    );
  });

export default ODRInstitutionDetails;
