import {
  AdminApi,
  AuthApi,
  CategoryApi,
  ComplainApi,
  ConArbApi,
  IndexApi,
  OpenApi,
  OrganisationApi,
  RoleApi,
  UserApi,
} from './swagger/api'
import { BehaviorSubject } from 'rxjs'
import { JwtPayload, jwtDecode } from "jwt-decode";
import { Configuration } from './swagger/configuration'
import { Complain, User } from './swagger/models'

export class ApiController {
  private static instance: ApiController
  private config: Configuration = new Configuration()
  public authApi: AuthApi = new AuthApi()
  public openApi: OpenApi = new OpenApi()
  public complainApi: ComplainApi = new ComplainApi()
  public categoryApi: CategoryApi = new CategoryApi()
  public roleApi: RoleApi = new RoleApi()
  public userApi: UserApi = new UserApi()
  public organisationApi: OrganisationApi = new OrganisationApi()
  public indexApi: IndexApi = new IndexApi()
  public adminApi: AdminApi = new AdminApi()
  public conArbApi: ConArbApi = new ConArbApi()

  public user: User | null = null
  public user$ = new BehaviorSubject<User | null>(null)
  public UserLoggedIn: User | null = null
  public UserLoggedIn$ = new BehaviorSubject<User | null>(null)
  public ComplainFiled : Complain | null = null
  public ComplainFiled$ = new BehaviorSubject<Complain | null>(null)

  private constructor() {
    const token = this.getSavedToken()
    if (token) {
      this.initApis(token)
      this.loadSavedUser()
    } else {
      this.clearToken()
    }
  }

  public updateUserProfile(user: User | null): void {
    this.user = user
    this.user$.next(user)
  }

  public updateLoggedInStatus(user: User | null): void {
    this.UserLoggedIn = user
    this.UserLoggedIn$.next(user)
    this.saveUser(user)
  }

  public updateComplainFileStatus(complain : Complain | null):void {
    this.ComplainFiled = complain
    this.ComplainFiled$.next(complain)
  }

  private saveUser(user: User | null): void {
    if (user) {
      user.UserParents = []
    }
    localStorage.setItem('USER', JSON.stringify(user))
  }

  public static getInstance(): ApiController {
    if (!ApiController.instance) {
      ApiController.instance = new ApiController()
    }
    return ApiController.instance
  }

  private loadSavedUser(): User {
    const strUser = localStorage.getItem('USER')
    if (!strUser) {
      return {}
    } else {
      const user = JSON.parse(strUser)
      this.user = user
      this.updateLoggedInStatus(user)
      this.user$.next(user)
      if (user.userType === 'Intermediary') {
        localStorage.setItem('SELECTED MP PROFILE', JSON.stringify(user))
      }
      return user
    }
  }

  private saveToken(token: string | null): void {
    // tslint:disable-next-line: no-console
    token = token ? token : JSON.stringify(null)
    localStorage.setItem('TOKEN', token)
    localStorage.setItem('TOKEN_TS', JSON.stringify(Date.now()))
  }

  public getSavedToken(): string {
    const base64token = localStorage.getItem('TOKEN')
    if (base64token && base64token !== 'null') {
      const token: any = jwtDecode<JwtPayload>(base64token)
      if (token?.exp * 1000 <= new Date().getTime()) {
        return ''
      }
      return base64token
    }
    return ''
  }

  public setToken(token: string | null): void {
    this.saveToken(token)
    this.setApiConfig(token)
  }

  public clearToken(): void {
    this.config = {}
    this.updateUserProfile(null)
    this.setToken(null)
    this.updateLoggedInStatus(null)
    localStorage.removeItem('TOKEN')
    localStorage.removeItem('TOKEN_TS')
    localStorage.removeItem('UUID')
    localStorage.removeItem('USER')
    // localStorage.removeItem('selectProfile')
    localStorage.removeItem('selectMIIProfiles')
    localStorage.removeItem('SELECTED MP PROFILE')
    this.setApiConfig('')
    // localStorage.clear()
  }

  public initApis(token: string | null) {
    this.setToken(token)
  }

  private setApiConfig(token: string | null): void {
    this.config.baseOptions = {
      headers: {
        authorization: token,
      },
    }
    this.authApi = new AuthApi(this.config, import.meta.env.VITE_BASE_PATH)
    this.openApi = new OpenApi(this.config, import.meta.env.VITE_BASE_PATH)
    this.complainApi = new ComplainApi(this.config, import.meta.env.VITE_BASE_PATH)
    this.categoryApi = new CategoryApi(this.config, import.meta.env.VITE_BASE_PATH)
    this.roleApi = new RoleApi(this.config, import.meta.env.VITE_BASE_PATH)
    this.indexApi = new IndexApi(this.config, import.meta.env.VITE_BASE_PATH)
    this.userApi = new UserApi(this.config, import.meta.env.VITE_BASE_PATH)
    this.adminApi = new AdminApi(this.config, import.meta.env.VITE_BASE_PATH)
    this.conArbApi = new ConArbApi(this.config, import.meta.env.VITE_BASE_PATH)
    this.organisationApi = new OrganisationApi(this.config, import.meta.env.VITE_BASE_PATH)
    // Add other api services here
  }
}
