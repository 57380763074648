import { Fragment, useEffect, useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { ApiController } from "../../../apiController";
import { useCombinedStore } from "../../../store";
import { Complain, ComplainComment, ComplainNature, User } from "../../../swagger/models";
import { MII_SUB_STATUS, dateHelper, dateTimeHelper, downloadJSONFile, titleCase } from "../../common-module/helper";
import ComplaintUpdates from "./ComplaintUpdates";
import PushToODRModal from "./PushToODRModal";
import SendMIIReviewModal from "./sendMIIReviewModal";
import RejectComplaintModal from "./RejectComplaintModal";
import ReviewRejection from "./ReviewRejectionModal";
import PreConciliation from "./PreConciliation";
import InfoTooltip from "../../../helpers/Tooltip";
import ArbitrationInfoModal from "./ArbitrationInfoModal";
import ResolvedPreConciliationModal from "./ResolvedPreConciliationModal";
import TransferDisputeModal from "./TransferDisputeModal";
import { Tooltip } from "react-tippy";
import { saveAs } from 'file-saver'
import { useTranslation } from 'react-i18next';
import PreScoresConciliation from "./PreScoresConciliation";
import { notifyError } from "../../../helpers/helper";
import PDFPreview from '../../PDFPreview';
import DocumentViewerModal from "../../DocumentViewerModal";
import moment from "moment";

const COMPLAIN_STATUS = [
  {
    status: 'AWAITING_MII_REVIEW', // When Investor files the complain & waiting for MII to accept it
    icon: 'border-solid border-status-orange border',
    subIcon: "",
    color: 'bg-status-white text-status-orange'
  },
  {
    status: 'DISPUTE_RESOLVED_-_PRECONCILIATION', // Complain got resolved
    icon: "border-solid border-highlight-blue border",
    subIcon: "",
    color: 'bg-light-blue text-highlight-blue'
  },
  {
    status: 'CONCILIATION_INITIATED', // Conciliation started
    icon: "border-dashed border-status-green border",
    subIcon: "",
    color: 'bg-status-pale-grey text-status-green'
  },
  {
    status: 'CONCILIATION_IN_PROGRESS', // Conciliation In progress
    icon: "border-solid border-status-green border",
    subIcon: "text-xs whitespace-nowrap",
    color: 'bg-status-pale-green text-status-green'
  },
  // {
  //   status: 'AWAITING_ARBITRATION_INITIATION', // Waitinf for party to start the arbitration process
  //   icon: "border-solid border-arb-init-brown border",
  //   subIcon: "",
  //   color: 'bg-arb-init-brown/[0.8] text-white'
  // },
  {
    status: 'CONCILIATION_FAILED', // Waitinf for party to start the arbitration process
    icon: "border-solid border-highlight-red border",
    subIcon: "",
    color: 'bg-status-fail-white text-highlight-red'
  },
  {
    status: 'ARBITRATION_REQUESTED', // Arbitration requested by Investor
    icon: "border-dashed border-status-green border",
    subIcon: "",
    color: 'bg-status-pale-green text-status-green'
  },
  {
    status: 'ARBITRATION_INITIATED', // Arbitration started
    icon: "border-dashed border-status-green border",
    subIcon: "",
    color: 'bg-status-pale-green text-status-green'
  },
  {
    status: 'ARBITRATION_IN_PROGRESS', // Arbitration in progress
    icon: "border-solid border-status-green border",
    subIcon: "text-xs whitespace-nowrap",
    color: 'bg-status-pale-green text-status-green'
  },
  {
    status: 'DISPUTE_RESOLVED_-_CONCILIATION', // Complain got resolved
    icon: "border-solid border-highlight-blue border",
    subIcon: "",
    color: 'bg-light-blue text-highlight-blue'
  },
  {
    status: 'DISPUTE_RESOLVED_-_ARBITRATION', // Complain got resolved
    icon: "border-solid border-highlight-blue border",
    subIcon: "",
    color: 'bg-light-blue text-highlight-blue'
  },
  {
    status: 'COMPLAINT_RESOLVED', // Complain got resolved
    icon: "border-solid border-highlight-blue border",
    subIcon: "",
    color: 'bg-light-blue text-highlight-blue'
  },
  {
    status: 'DISPUTE_RETURNED', // When Complain is rejected by Intermediary
    icon: "border-solid border-status-grey border",
    subIcon: "",
    color: 'bg-F5F5F5 text-status-grey'
  },
  {
    status: 'DISPUTE_WITHDRAWN', // When Complain is withdrawn by Intermediary
    icon: "border-solid border-status-grey border",
    subIcon: "",
    color: 'bg-F5F5F5 text-status-grey'
  },
  // {
  //   status: 'AWARD_CHALLENGED', // When Complain is rejected by Intermediary
  //   icon: "border-solid border-status-grey border",
  //   subIcon: "",
  //   color: 'bg-F5F5F5 text-status-grey'
  // }
]

const COMPLAIN_STATUS1 = [
  {
    status: 'CONCILIATION_IN_PROGRESS', // Conciliation In progress
    icon: "border-solid border-status-green border",
    subIcon: "text-xs whitespace-nowrap",
    color: 'bg-status-pale-green text-status-green'
  },
  {
    status: 'DISPUTE_RESOLVED_-_CONCILIATION', // Complain got resolved
    icon: "border-solid border-highlight-blue border",
    subIcon: "",
    color: 'bg-light-blue text-highlight-blue'
  },
  {
    status: 'CONCILIATION_FAILED', // Waitinf for party to start the arbitration process
    icon: "border-solid border-highlight-red border",
    subIcon: "",
    color: 'bg-status-fail-white text-highlight-red'
  },
  {
    status: 'ARBITRATION_INITIATED', // Arbitration started
    icon: "border-dashed border-status-green border",
    subIcon: "",
    color: 'bg-status-pale-green text-status-green'
  },
  {
    status: 'ARBITRATION_IN_PROGRESS', // Arbitration in progress
    icon: "border-solid border-status-green border",
    subIcon: "text-xs whitespace-nowrap",
    color: 'bg-status-pale-green text-status-green'
  },
  {
    status: 'DISPUTE_RESOLVED_-_ARBITRATION', // Complain got resolved
    icon: "border-solid border-highlight-blue border",
    subIcon: "",
    color: 'bg-light-blue text-highlight-blue'
  },
  {
    status: 'DISPUTE_WITHDRAWN', // When Complain is withdrawn by Intermediary
    icon: "border-solid border-status-grey border",
    subIcon: "",
    color: 'bg-F5F5F5 text-status-grey'
  },
]

function ComplaintDetails() {
  const params = useParams()
  const { t } = useTranslation();
  const complainUUID: string = params.complainUUID!
  const currentURL = window.location.href.split('/')[3]

  const [openTab, setOpenTab] = useState(3)
  const [user, setUser] = useState<User>({})

  const [complaintDetails, setComplaintDetails] = useState<Complain>({})
  const [complaintUpdateDetails, setUpdateComplaintDetails] = useState<Complain>({})

  const complaintDetail: Complain = useCombinedStore((state: any) => state.complaint);
  const commentDetail: ComplainComment = useCombinedStore((state: any) => state.comment);
  const complaintStatus = useCombinedStore((state: any) => state.complaintStatus)
  const getComplaint = useCombinedStore((state: any) => state.getComplaint);
  const extendDisputeTimeline = useCombinedStore((state: any) => state.extendDisputeTimeline);
  const [collapsedComments, setCollapsedComments] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isDisputeActionsOpen, setIsDisputeActionsOpen] = useState(false);
  const [isConDisputeActionsOpen, setIsConDisputeActionsOpen] = useState(false);
  const [isSubStatusOpen, setIsSubStatusOpen] = useState(false);
  const dropdownRef = useRef<any>(null);
  const disputeActionsdropdownRef = useRef<any>(null);
  const [selectedstatus, setStatus] = useState({ status: "", icon: "", subIcon: "", color: "" })

  const [initiateArb, setInititateArb] = useState(false)
  const [sendMIIReview, setSendMIIReview] = useState(false)
  const [pushToODR, setPushToODR] = useState(false)
  const [transferDispute, setTransferDispute] = useState(false)
  const [rejectComplaint, setRejectComplaint] = useState(false)
  const [resolvedComplaint, setResolvedComplaint] = useState(false)
  const [reviewRejection, setReviewRejection] = useState(false)
  const [arbInfoModal, setArbInfoModal] = useState(false)
  const [extendTimelineModal, setExtendTimelineModal] = useState<boolean>(false)
  const [isAuthorizedDetailsEditing, setIsAuthorizedDetailsEditing] = useState<boolean>(false)

  const transferDisputeToODR = useCombinedStore((state: any) => state.transferDisputeToODR)
  const updatedDisputeNatureType = useCombinedStore((state: any) => state.updatedDisputeNatureType)
  const updateStatusofComplain = useCombinedStore((state: any) => state.updateStatusofComplain)
  const updateSubStatusofComplain = useCombinedStore((state: any) => state.updateSubStatusofComplain)
  const updateAuthorizedPersonDetails = useCombinedStore((state: any) => state.updateAuthorizedPersonDetails)

  const downloadFiles = useCombinedStore((state: any) => state.downloadFiles)
  const downloadFileOfComplaint = useCombinedStore((state: any) => state.downloadFile)
  const fetchSubCategoryMaster = useCombinedStore((state: any) => state.fetchSubCategoryMaster)
  const [remainingDays, setRemainingDays] = useState(0)
  const [complainNatureId, setComplainNatureId] = useState<number>()
  const [selectedFile, setSelectedFile] = useState<any>({})

  const Miis = useCombinedStore((state: any) => state.allMiis)

  const [allMiis, setMiis] = useState<any>([]);
  const [previewLink, setPreviewLink] = useState<string>('')
  const [attachmentPreviewLink, setAttachmentPreviewLink] = useState<string>('')

  const parseDispute = (complaintDetails: any) => {

    let json = {
      service: complaintDetails.status.includes("ARBITRATION") ? "online_arbitration" : complaintDetails.status.includes("CONCILIATION") ? "online_conciliation" : "Other",
      disputeId: complaintDetails.complaintUUID,
      status: complaintDetails.status,
      claimAmount: complaintDetails.claimAmount,
      category: complaintDetails.CategoryMaster.categoryName,
      subCategory: complaintDetails.SubCategoryMaster.categoryName,
      miiDetails: {
        name: complaintDetails.MII.organisationName,
        phone: complaintDetails.MII.primaryContact,
        email: complaintDetails.MII.primaryEmail,
        address: complaintDetails.MII.registeredAddress,
        poc: {
          name: complaintDetails.MII.Users[0].userName,
          email: complaintDetails.MII.Users[0].email,
          phone: complaintDetails.MII.Users[0].phoneNumber
        }
      },
      complainant: {
        type: complaintDetails.complainantType,
        name: complaintDetails.Complainant.userName,
        email: complaintDetails.Complainant.email,
        phone: complaintDetails.Complainant.phoneNumber,
        organisation: complaintDetails.complainantType === 'Investor' ? {} : {
          name: complaintDetails.Complainant.Organisation.organisationName,
          address: complaintDetails.Complainant.Organisation.registeredAddress,
          poc: {
            name: complaintDetails.Complainant.Organisation.Users[0].userName,
            phone: complaintDetails.Complainant.Organisation.Users[0].phoneNumber,
            email: complaintDetails.Complainant.Organisation.Users[0].email
          }
        }
      },
      respondent: complaintDetails.respondentType === "Intermediary" ? {
        type: complaintDetails.respondentType,
        name: complaintDetails.Respondent.Users[0].userName,
        email: complaintDetails.Respondent.primaryEmail,
        phone: complaintDetails.Respondent.primaryContact,
        organisation: {
          name: complaintDetails.Respondent.organisationName,
          address: complaintDetails.Respondent.registeredAddress,
          poc: {
            name: complaintDetails.Respondent.Users[0].userName,
            email: complaintDetails.Respondent.Users[0].email,
            phone: complaintDetails.Respondent.Users[0].phone
          }
        }
      } : {
        type: complaintDetails.respondentType,
        name: complaintDetails.RespondentUser.userName,
        email: complaintDetails.RespondentUser.email,
        phone: complaintDetails.RespondentUser.phoneNumber,
        address: complaintDetails.RespondentUser.address,
        organisation: {}
      },
      natureofDispute: complaintDetails.ComplainNatures
    }
    return json

  }

  const exportToJson = async (e: any, type: string) => {
    e.preventDefault()

    setIsDisputeActionsOpen(false)

    const zipFile = await downloadFiles(complaintDetails.complainId)

    if (zipFile !== undefined && zipFile !== "") window.open(zipFile)

    if (type === 'old') {
      const complaintObject = complaintDetails
      delete complaintObject.miiId;
      delete complaintObject.odrId;
      delete complaintObject.categoryId;
      delete complaintObject.complainId;
      delete complaintObject.intermediaryId;
      delete complaintObject.complainBrief;
      delete complaintObject.complainantId;
      delete complaintObject.respondentId;
      delete complaintObject.natureOfComplaint;
      delete complaintObject.subCategoryId;
      delete complaintObject.subSubCategoryId;
      complaintObject.ComplainNatures.map((complainNature: ComplainNature) => {
        const nature = complainNature
        delete nature.complainId
        delete nature.complainNatureId
        return nature
      })
      downloadJSONFile(
        JSON.stringify(complaintObject),
        `${complaintObject.complaintUUID}.json`,
        'text/json',
      )
    } else {
      let json = parseDispute(complaintDetails)
      downloadJSONFile(
        JSON.stringify(json),
        `${json.disputeId}.json`,
        'text/json',
      )
    }

  }

  function exportToPDF() {
    fetch(`${import.meta.env.VITE_BASE_PATH}/complain-management/export_dispute_pdf`, {
      method: 'POST',
      headers: {
        Authorization: localStorage.getItem('TOKEN') || '',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        complaintUUID: complainUUID
      })
    })
      .then((response) => {
        return response.blob()
      })
      .then((blob) => {
        saveAs(blob, 'Disputes.pdf', { autoBom: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getComplaint(complainUUID);
  }, [complainUUID]);

  useEffect(() => {
    ApiController.getInstance().user$.subscribe((user: any) => {
      if (user) {
        setUser(user)
      }
    });
  }, [])

  useEffect(() => {
    const natureIndex = complaintDetails?.ComplainNatures?.findIndex((nature: ComplainNature) => nature?.complainNatureId === commentDetail?.complainNatureId)
    if (natureIndex > -1 && commentDetail?.complainCommentId) {
      const complainNature = complaintDetails?.ComplainNatures[natureIndex]
      if (commentDetail?.parentId) {
        const commentIndex = complainNature?.ComplainComments?.findIndex((comment: ComplainComment) => comment?.complainCommentId === commentDetail?.parentId)
        if (commentIndex > -1) {
          complainNature?.ComplainComments[commentIndex].NestedComments?.push(commentDetail)
        }
      } else {
        complainNature?.ComplainComments?.push(commentDetail)
      }
      setComplaintDetails({
        ...complaintDetails,
        ComplainNatures: [
          ...complaintDetails.ComplainNatures.slice(0, natureIndex),
          complainNature,
          ...complaintDetails.ComplainNatures.slice(natureIndex + 1),
        ],
      })
    }
  }, [commentDetail])

  useEffect(() => {
    setComplaintDetails(complaintDetail)
    setUpdateComplaintDetails(complaintDetail)
    const statusObj = COMPLAIN_STATUS.find(
      (statusObj) => statusObj.status === complaintStatus
    );

    if (statusObj) {
      setStatus(statusObj);
    } else {
      setStatus({
        status: 'CHANGE_STATUS',
        icon: '',
        subIcon: '',
        color: 'bg-highlight-blue/[0.4]',
      })
    }
    const createdAtDate = new Date(complaintDetail.createdAt);
    const targetDate = calculateTargetDate(createdAtDate, 21);
    setRemainingDays(calculateRemainingDays(targetDate))
    if (complaintDetail.subCategoryId) {
      fetchSubCategoryMaster(complaintDetail?.subCategoryId)
    }
  }, [complaintDetail])

  const calculateTargetDate = (baseDate: any, days: any) => {
    const newDate = new Date(baseDate);
    newDate.setDate(baseDate.getDate() + days);
    return newDate;
  };

  const calculateRemainingDays = (targetDate: any) => {
    const currentDate: any = new Date();
    const timeDifference = targetDate - currentDate;
    const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return remainingDays >= 0 ? remainingDays : 0;
  };

  const handleCollapse = (index: any) => {
    setCollapsedComments((prevCollapsed) => {
      const isCollapsed = prevCollapsed.includes(index);
      if (isCollapsed) {
        return prevCollapsed.filter((item) => item !== index);
      } else {
        return [...prevCollapsed, index];
      }
    });
  };

  const isCollapsed = (index: any) => {
    return collapsedComments.includes(index);
  };

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
    if (disputeActionsdropdownRef.current && !disputeActionsdropdownRef.current.contains(event.target)) {
      setIsDisputeActionsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleSubStatusDropdown = () => {
    setIsSubStatusOpen(!isSubStatusOpen);
  };

  const addStatusFilter = (status: any) => {
    setStatus(status)
  }

  const handleStatusChange = async (e: any, status: string, type: string, statusUpdate?: string) => {
    e.preventDefault();
    let statusObject: { [key: string]: any } = {};
    statusObject.complainId = complaintDetails.complaintUUID;
    statusObject.status = status
    statusObject.statusUpdate = statusUpdate
    if (type === 'PushToODR') statusObject.pushToODR = true

    const statusRes = await updateStatusofComplain(statusObject)
    if (statusRes.data.success) {
      const payload = {
        complaintUUID: complaintDetails.complaintUUID
      }
      if (type === 'PushToODR') {
        await transferDisputeToODR(payload)
      }
    }
    setPushToODR(false)
    setRejectComplaint(false)
    setReviewRejection(false)
    setResolvedComplaint(false)
    setSendMIIReview(false)
  }

  const transfer_to_odr = async (e: any) => {
    e.preventDefault()
    setIsDisputeActionsOpen(false)
    const payload = { complaintUUID: complaintDetails.complaintUUID }
    await transferDisputeToODR(payload)
  }

  const handleSubStatusChange = async (e: any, status: string) => {
    e.preventDefault();
    let statusObject: Complain = {};
    statusObject.complainId = complaintDetails.complaintUUID;
    statusObject.status = status
    statusObject.subStatus = status
    updateSubStatusofComplain(statusObject)
  }


  const downloadFile = async (key: any, complainNatureId?: number, fileName?: string) => {
    let res = await downloadFileOfComplaint(key, complaintDetail?.complainId, complainNatureId, null, null, null, fileName)
    if (!res.error) window.open(res.file)
  }

  const extendTimeline = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    extendDisputeTimeline(complaintDetails?.complaintUUID)
  }

  const saveAuthorizedDetails = (e: any) => {
    updateAuthorizedPersonDetails(complaintUpdateDetails)
    setIsAuthorizedDetailsEditing(false)
    setComplaintDetails({
      ...complaintDetail,
      authorizedPersonName: complaintUpdateDetails.authorizedPersonName,
      authorizedPersonPAN: complaintUpdateDetails.authorizedPersonPAN
    })
  }

  useEffect(() => {
    if (updatedDisputeNatureType && updatedDisputeNatureType.complainNatureId) {
      const natureIndex = complaintDetails.ComplainNatures.findIndex((nature: ComplainNature) => nature.complainNatureId === updatedDisputeNatureType.complainNatureId)
      const complaintNature = complaintDetails.ComplainNatures[natureIndex]
      complaintNature.otherMetaData = updatedDisputeNatureType.otherMetaData
      setComplaintDetails({
        ...complaintDetails,
        ComplainNatures: [
          ...complaintDetail.ComplainNatures.slice(0, natureIndex),
          complaintNature,
          ...complaintDetail.ComplainNatures.slice(natureIndex + 1),
        ]
      })
    }
  }, [updatedDisputeNatureType])

  const generateLink = async (key: string, fileName: string) => {
    try {
      let res = await downloadFileOfComplaint(key, complaintDetail?.complainId, null, null, null, fileName)
      setPreviewLink(res.file)
    } catch (error: any) {
      notifyError(error?.response?.data?.message);
    }
  }

  const generateAttachmentLink = async (key: string, complainNatureId: number, file: any) => {
    try {
      let res = await downloadFileOfComplaint(key, complaintDetails?.complainId, complainNatureId, undefined, undefined)
      setAttachmentPreviewLink(res.file)
      setSelectedFile(file)
      setComplainNatureId(complainNatureId)
    } catch (error: any) {
      notifyError(error?.response?.data?.message);
    }
  }

  useEffect(() => {
    document.body.style.overflow = ((previewLink && previewLink !== "") || (attachmentPreviewLink && attachmentPreviewLink !== "")) ? "hidden" : "unset";
  }, [previewLink, attachmentPreviewLink]);

  return (
    <div className="pt-7 w-full h-full sm:pr-4 pr-1 pl-1 pb-7">
      <div
        style={{ background: '#F4F4F4' }}
        className="flex w-full h-full flex-initial  px-8 py-7 flex-col gap-2 rounded-2xl"
      >
        <p className='hidden sm:block text-xs text-back-gray'><Link to={`/${currentURL}/disputes`}>{t('disputeHistory')}</Link> &gt; {complaintDetails?.complaintUUID}</p>
        <p className="sm:hidden block text-lg text-back-gray"><Link to={`/${currentURL}/disputes`}><i className="ph ph-arrow-circle-left"></i></Link></p>
        <div className="flex flex-col gap-2">
          <div className="flex sm:flex-row flex-row gap-4 items-center sm:justify-between">
            <div className="flex sm:flex-row flex-col gap-4">
              <div className="flex flex-col gap-0.5 sm:hidden">
                <h1 className={`${complaintDetails.status === "CONCILIATION_FAILED" ? "text-lg font-semibold text-highlight-red" : "text-lg font-semibold text-highlight-blue"}`}>{complaintDetails?.complainantName} {t('vs')} {complaintDetails?.respondentName}</h1>
                <p className={`${complaintDetails.status === "CONCILIATION_FAILED" ? "sm:hidden text-xs text-highlight-red" : "sm:hidden text-xs text-highlight-blue"}`}>{complaintDetails?.complaintUUID}
                  {complaintDetails?.isFromScores && <span className="text-xs text-back-gray"> <i>{t('viaSCORES')}</i></span>}</p>
              </div>
              <div className="flex flex-col gap-2">
                <h1 className={`${complaintDetails.status === "CONCILIATION_FAILED" ? "text-3xl hidden sm:block font-semibold text-highlight-red" : "text-3xl hidden sm:block font-semibold text-highlight-blue"}`}>{complaintDetails?.complainantName} {t('vs')} {complaintDetails?.respondentName}</h1>
                <p className={`${complaintDetails.status === "CONCILIATION_FAILED" ? "hidden sm:block text-xs text-highlight-red" : "hidden sm:block text-xs text-highlight-blue"}`}>{complaintDetails?.complaintUUID}
                  {complaintDetails?.isFromScores && <span className="text-xs text-back-gray"> <i>{t('viaSCORES')}</i></span>}
                </p>

              </div>
              <div className="relative" ref={dropdownRef}>
                {user.userType === 'ODR' &&
                  <button
                    type="button"
                    onClick={toggleDropdown}
                    className={`inline-flex font-bold items-center justify-center sm:w-full w-fit px-8 py-2.5 text-xs text-label-grey ${selectedstatus?.color} ${selectedstatus?.icon} rounded-full`}
                    id="dropdown-menu-button"
                    aria-expanded={isOpen}
                    aria-haspopup="true"
                  >

                    <p>{titleCase(selectedstatus?.status)}</p>
                  </button>
                }
                {user.userType === 'MII' && complaintDetails?.status === 'AWAITING_MII_REVIEW' &&
                  <button
                    type="button"
                    onClick={toggleSubStatusDropdown}
                    className={`inline-flex font-bold items-center justify-center sm:w-full w-fit px-8 py-2.5 text-xs text-label-grey ${selectedstatus?.color} ${selectedstatus?.icon} rounded-full`}
                    id="dropdown-menu-button"
                    aria-expanded={isOpen}
                    aria-haspopup="true"
                  >

                    <p>{titleCase(selectedstatus?.status)}</p>
                  </button>
                }
                {(user.userType !== 'ODR' && !(user.userType === 'MII' && complaintDetails?.status === 'AWAITING_MII_REVIEW')) &&
                  <button
                    type="button"
                    className={`inline-flex cursor-default items-center justify-center sm:w-full w-fit px-8 py-2.5 text-xs font-bold text-label-grey ${selectedstatus?.color} ${selectedstatus?.icon} rounded-full`}
                    id="status-button"
                  >
                    <p>{titleCase(selectedstatus?.status)}</p>
                  </button>
                }

                {complaintDetails?.status === 'AWAITING_MII_REVIEW' && complaintDetails?.subStatus && user.userType === 'MII' &&
                  <div className="flex" onClick={toggleSubStatusDropdown}>
                    <div className={`h-6 ${selectedstatus?.icon} w-fit border-l border-solid ml-4`}>{''}</div>
                    <div className="flex items-center">
                      <div className={`w-4 ${selectedstatus?.icon} h-fit border-l border-solid`}>{''}</div>
                      <button
                        type="button"
                        className={`inline-flex items-center justify-center px-8 mt-2 py-2.5 text-xs font-bold text-label-grey ${selectedstatus?.color} ${selectedstatus?.icon} rounded-full`}
                        id="status-button"
                      >
                        <p className="whitespace-nowrap">{titleCase(complaintDetails?.subStatus)}</p>
                      </button>
                    </div>
                  </div>
                }
                {complaintDetails?.status === 'AWAITING_MII_REVIEW' && user.userType === 'MII' &&
                  <div className="flex gap-2 items-center pt-2 ml-4 text-xs text-low-grey cursor-pointer" onClick={toggleSubStatusDropdown}>
                    <i className="ph ph-plus"></i>
                    <p>{t('AddSubStatus')}</p>
                  </div>
                }
                {isOpen && (
                  <div
                    className="origin-top-right absolute left-0 mt-2 w-fit rounded-lg bg-EBEBEB"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="dropdown-menu-button"
                  >
                    <div className="py-1" role="none">

                      <div className="flex flex-col gap-1 py-2 px-3 cursor-pointer">
                        {COMPLAIN_STATUS1?.map((status: any, index: number) => {
                          return (
                            <div className="flex flex-row gap-2 py-2 whitespace-nowrap px-3 text-xs rounded-lg cursor-pointer  hover:bg-white" key={status?.status} onClick={(e: any) => {
                              handleStatusChange(e, status.status, '')
                              addStatusFilter(status)
                              setIsOpen(false)
                            }}>
                              <div className={`border relative w-7 rounded-full ${status.icon}`}>
                                {status?.status === 'CONCILIATION_IN_PROGRESS' &&
                                  <div className="w-2/3 rounded-full h-full bg-conc-green"></div>
                                }
                                {status?.status === 'ARBITRATION_IN_PROGRESS' &&
                                  <div className="w-2/3 rounded-full h-full bg-highlight-blue"></div>
                                }
                              </div>
                              <p className={`${status?.subIcon || ''}`}>{titleCase(status.status)}</p>
                            </div>
                          )
                        })}
                      </div>

                    </div>
                  </div>
                )}
                {isSubStatusOpen && (
                  <div
                    className="origin-top-right absolute z-50 left-0 mt-2 w-fit rounded-lg bg-EBEBEB"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="dropdown-menu-button"
                  >
                    <div className="py-1" role="none">
                      <div className="flex flex-col gap-1 py-2 px-3 cursor-pointer">
                        <div className="flex flex-row gap-2 py-2 whitespace-nowrap px-3 text-xs rounded-lg cursor-pointer text-low-grey">
                          <p>Change sub-status</p>
                        </div>
                        <hr />
                        {MII_SUB_STATUS?.map((status: any, index: number) => {
                          return (
                            <div className={`${complaintDetails?.subStatus === status.status ? 'bg-white flex flex-row gap-2 py-2 whitespace-nowrap px-3 text-xs rounded-lg cursor-pointer  hover:bg-white' : 'flex flex-row gap-2 py-2 whitespace-nowrap px-3 text-xs rounded-lg cursor-pointer  hover:bg-white'}`} key={status?.status} onClick={(e: any) => {
                              handleSubStatusChange(e, status.status)
                              addStatusFilter(status)
                              setIsSubStatusOpen(false)
                            }}>
                              <p>{titleCase(status.status)}</p>
                            </div>
                          )
                        })}
                      </div>

                    </div>
                  </div>
                )}
              </div>
            </div>
            {complaintDetails?.status === 'AWAITING_MII_REVIEW' && (
              <div className="flex flex-col justify-end w-32 gap-2 items-center pt-5">
                <p className="rounded-full px-4 py-2.5 font-bold border-4 border-highlight-blue text-highlight-blue text-xl bg-highlight-blue/10">{remainingDays}</p>
                <p className="text-xs text-center text-highlight-blue">{t('DaysforMIItoReviewDispute')}</p>
              </div>
            )}
            {complaintDetails?.status === 'CONCILIATION_INITIATED' && (
              <div className="flex flex-col w-32 justify-end gap-2 items-center pt-5">
                <p className="rounded-full px-4 py-2.5 font-bold border-4 border-status-green text-status-green text-xl bg-status-pale-green">{calculateRemainingDays(new Date(complaintDetails?.caseAllocateToODR))}</p>
                <p className="text-xs text-center text-status-green">{t('DaysforConciliatorAppointment')}</p>
              </div>
            )}
            {complaintDetails?.status === 'CONCILIATION_IN_PROGRESS' && (
              <div className="flex flex-col justify-end w-32 gap-2 items-center pt-5">
                <div className="flex">
                  <p className="rounded-full mt-3 px-4 py-2.5 font-bold border-4 border-status-green text-status-green text-xl bg-status-pale-green">{calculateRemainingDays(new Date(complaintDetails?.caseInConciliation))}</p>
                  {complaintDetails?.conciliationExtension?.timelineExtendedAt &&
                    <Tooltip
                      html={
                        <div className=" text-left flex flex-col gap-1">
                          <p className="font-semibold">Timeline Extension</p>
                          <p>This symbol indicates that the timeline of your dispute has been extended by the ODR Institution.</p>
                        </div>
                      }
                      position="top"
                      arrow={false}
                      animation="shift"
                      size="small"
                      {...({ children: <i className="ph text-status-green border-status-green border ph-plus h-fit text-xs p-0.5" style={{ borderRadius: '50%' }}></i> } as any)} // Type assertion to bypass TS errors
                    >
                    </Tooltip>
                  }
                </div>
                <p className="text-xs text-center text-status-green">Days for Conciliation to be Completed</p>
                {user.userType === 'ODR' && complaintDetails?.odrId === user.orgId && !complaintDetails?.conciliationExtension && <button className="text-xs hover:underline text-back-gray" onClick={(e) => setExtendTimelineModal(true)}>Extend</button>}
              </div>
            )}
            {complaintDetails?.status === 'ARBITRATION_REQUESTED' && (
              <div className="flex flex-col justify-end w-32 gap-2 items-center pt-5">
                <p className="rounded-full px-4 py-2.5 font-bold border-4 border-highlight-red text-highlight-red text-xl bg-highlight-red/10">{calculateRemainingDays(new Date(complaintDetails?.paymentWindowDay))}</p>
                <p className="text-xs text-center text-highlight-red">{t('DaysforCompletingPaymenttoInitiateArbitration')}</p>
              </div>
            )}
            {complaintDetails?.status === 'ARBITRATION_INITIATED' && (
              <div className="flex flex-col justify-end w-32 gap-2 items-center pt-5">
                <p className="rounded-full px-4 py-2.5 font-bold border-4 border-status-green text-status-green text-xl bg-status-pale-green">{calculateRemainingDays(new Date(complaintDetails?.arbitrationInitiated))}</p>
                <p className="text-xs text-center text-status-green">{t('DaysforArbitratorAppointment')}</p>
              </div>
            )}
            {complaintDetails?.status === 'ARBITRATION_IN_PROGRESS' && (
              <div className="flex flex-col justify-end w-32 gap-2 items-center pt-5">
                <div className="flex">
                  <p className="rounded-full mt-3 px-4 py-2.5 font-bold border-4 border-status-green text-status-green text-xl bg-status-pale-green">{calculateRemainingDays(new Date(complaintDetails?.caseInArbitrationDay))}</p>
                  {complaintDetails?.arbitrationExtension?.timelineExtendedAt &&
                    <Tooltip
                      html={
                        <div className=" text-left flex flex-col gap-1">
                          <p className="font-semibold">Timeline Extension</p>
                          <p>This symbol indicates that the timeline of your dispute has been extended by the ODR Institution.</p>
                        </div>
                      }
                      position="top"
                      arrow={false}
                      animation="shift"
                      size="small"
                      {...({ children: <i className="ph text-status-green border-status-green border ph-plus h-fit text-xs p-0.5" style={{ borderRadius: '50%' }}></i> } as any)} // Type assertion to bypass TS errors
                    >
                    </Tooltip>
                  }
                </div>

                <p className="text-xs text-center text-status-green">Days for Arbitration to be Completed</p>
                {user.userType === 'ODR' && complaintDetails?.odrId === user.orgId && !complaintDetails?.arbitrationExtension && <button className="text-xs hover:underline text-back-gray" onClick={(e) => setExtendTimelineModal(true)}>Extend</button>}
              </div>
            )}
            {complaintDetails?.status === 'AWARD_CHALLENGED' && (
              <div className="flex flex-col justify-end w-32 gap-2 items-center pt-5">
                <p className="rounded-full px-4 py-2.5 font-bold border-4 border-highlight-blue text-highlight-blue text-xl bg-highlight-blue/10">{calculateRemainingDays(new Date(complaintDetails?.awardChallengedDate))}</p>
                <p className="text-xs text-center text-highlight-blue">{t('DaysWithinwhichArbitrationAwardcanbeChallenged')}</p>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-2">
            {user.userType === "MII" && complaintDetails?.status === 'AWAITING_MII_REVIEW' && (
              <div className="w-1/5 relative" ref={disputeActionsdropdownRef}>
                <button
                  type="button"
                  className={`inline-flex items-center justify-center w-48 h-12 px-8 py-3 text-xs text-label-grey bg-highlight-blue text-white rounded-lg`}
                  id="status-button"
                  onClick={() => setIsDisputeActionsOpen(!isDisputeActionsOpen)}
                >
                  <p>{t('DisputeActions')}</p>
                </button>
                {isDisputeActionsOpen && (
                  <div
                    className="origin-top-right absolute left-0 mt-2 w-fit z-50 rounded-lg bg-EBEBEB"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="dropdown-menu-button"
                  >
                    <div className="py-1" role="none">

                      <div className="flex flex-col gap-1 py-2 px-3 cursor-pointer">

                        <div className="flex flex-row gap-2 py-2 whitespace-nowrap px-3 text-xs rounded-lg cursor-pointer items-center hover:bg-white" onClick={(e: any) => {
                          setIsDisputeActionsOpen(false)
                          setResolvedComplaint(true)
                        }}>
                          <i className="ph ph-thumbs-up"></i>
                          <p>{t('DisputeResolvedinPreconciliation')}</p>
                        </div>
                        <div className="flex flex-row gap-2 py-2 whitespace-nowrap px-3 text-xs rounded-lg cursor-pointer items-center hover:bg-white" onClick={(e: any) => {
                          setIsDisputeActionsOpen(false)
                          setPushToODR(true)
                        }}>
                          <i className="ph ph-handshake"></i>
                          <p>{t('ReferDisputeforConciliation')}</p>
                        </div>
                        <div className="flex flex-row gap-2 py-2 whitespace-nowrap px-3 text-xs rounded-lg cursor-pointer items-center hover:bg-white" onClick={(e: any) => {
                          setIsDisputeActionsOpen(false)
                          setRejectComplaint(true)
                        }}>
                          <i className="ph ph-arrow-u-up-left"></i>
                          <p>{t('ReturnDispute')}</p>
                        </div>
                      </div>

                    </div>
                  </div>
                )}
              </div>
            )}

            {user.userType === "ODR" && complaintDetails?.odrId === user.orgId && (
              <div className="w-1/5 relative" ref={disputeActionsdropdownRef}>
                <button
                  type="button"
                  className={`inline-flex items-center justify-center w-48 h-12 px-8 py-3 text-xs text-label-grey bg-highlight-blue text-white rounded-lg`}
                  id="status-button"
                  onClick={() => setIsDisputeActionsOpen(!isDisputeActionsOpen)}
                >
                  <p className="mr-2">{t('DisputeActions')}</p>
                  <i className="ph ph-caret-down text-base"></i>
                </button>
                {isDisputeActionsOpen && (
                  <div
                    className="origin-top-right absolute left-0 z-50 mt-2 w-fit rounded-lg bg-EBEBEB"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="dropdown-menu-button"
                  >
                    <div className="py-1" role="none">
                      <div className="flex flex-col gap-1 py-2 px-3 cursor-pointer">
                        <div className="flex flex-row gap-2 py-2 whitespace-nowrap px-3 text-xs rounded-lg cursor-pointer items-center hover:bg-white" onClick={(e) => {
                          exportToJson(e, 'old')
                        }
                        }>
                          <i className="ph ph-download-simple"></i>
                          <p>{t('DownloadJSON')} ({t('old')})</p>
                        </div>
                        <div className="flex flex-row gap-2 py-2 whitespace-nowrap px-3 text-xs rounded-lg cursor-pointer items-center hover:bg-white" onClick={(e) => {
                          exportToJson(e, 'new')
                        }}>
                          <i className="ph ph-download"></i>
                          <p>{t('DownloadJSON')} ({t('new')})</p>
                        </div>
                        <div className="flex flex-row gap-2 py-2 whitespace-nowrap px-3 text-xs rounded-lg cursor-pointer items-center hover:bg-white" onClick={(e: any) => {
                          transfer_to_odr(e)

                        }}>
                          <i className="ph ph-arrow-u-up-left"></i>
                          <p>{t('TriggerDisputeTransferAPI')}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {user.userType === "Investor" && complaintDetails?.status === 'DISPUTE_RETURNED' && (
              <p onClick={() => setReviewRejection(true)} className="text-xs text-white text-center bg-highlight-red hover:bg-highlight-red/50 px-8 py-4 rounded-lg w-full sm:w-fit cursor-pointer">
                {t('ReviewRejection')}
              </p>
            )}
          </div>
          {(complaintDetails.status === "CONCILIATION_FAILED") && (user.userType === "Investor" || user.userType === "Intermediary") && (
            <div className="w-1/5 relative" ref={disputeActionsdropdownRef}>
              <button
                type="button"
                className={`inline-flex cursor-default items-center justify-between w-42 px-8 py-2 text-xs text-label-grey bg-highlight-blue text-white rounded-lg`}
                id="status-button"
                onClick={() => setIsConDisputeActionsOpen(!isConDisputeActionsOpen)}
              >
                <p className="mr-2">{t('DisputeActions')}</p>
                <i className="ph ph-caret-down text-base"></i>
              </button>
              {isConDisputeActionsOpen && (
                <div
                  className="origin-top-right absolute left-0 mt-2 w-fit rounded-lg bg-EBEBEB"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="dropdown-menu-button"
                >
                  <div className="py-1" role="none">
                    <div className="flex flex-col gap-1 py-2 px-3 cursor-pointer">
                      <div className="flex flex-row gap-2 py-2 whitespace-nowrap px-3 text-xs rounded-lg cursor-pointer items-center bg-disable-grey cursor-not-allowed"> 
                        {/* onClick={(e) => handleStatusChange(e, 'DISPUTE_WITHDRAWN', '')} */}
                        <i className="ph ph-thumbs-up"></i>
                        <p>{t('WithdrawDispute')}</p>
                      </div>
                      <Tooltip
                        html={
                          <p>{t('InAnArbitrationProcess')}</p>
                        }
                        position="top"
                        arrow={false}
                        animation="shift"
                        size="small"
                        {...({
                          children: <div className="flex flex-row gap-2 py-2 whitespace-nowrap px-3 text-xs rounded-lg cursor-pointer items-center hover:bg-white" onClick={() => { setInititateArb(true); setIsConDisputeActionsOpen(false) }}>
                            <i className="ph ph-handshake"></i>
                            <p>{t('RequestForArbitration')}</p>
                          </div>
                        } as any)} // Type assertion to bypass TS errors
                      >
                      </Tooltip>
                    </div>
                  </div>
                </div>
              )}
            </div>
            // <div className="flex flex-row gap-2 items-center text-highlight-red">
            //   <p onClick={() => setInititateArb(true)} className="text-xs cursor-pointer text-highlight-red underline hover:text-highlight-red/90"></p>
            //   <InfoTooltip title={t('InAnArbitrationProcess')} />
            // </div>
          )}
          {complaintDetails.status === "ARBITRATION_INITIATED" && (user.userType === "Investor" || user.userType === "Intermediary") && (
            <div className="flex flex-row gap-2 items-center text-highlight-blue">
              <p onClick={() => setArbInfoModal(true)} className="text-xs cursor-pointer text-highlight-blue underline hover:text-highlight-blue/90">{t('ArbititrationHasBeenInitiated')}</p>
              <InfoTooltip title={t('OnceArbitrationInitiated')} />
            </div>
          )}
        </div>
        <div className="flex flex-row border-b sm:gap-12 gap-5 border-b-low-grey pt-3">
          <div onClick={() => setOpenTab(3)} className={`${openTab === 3 ? 'flex flex-row gap-2 items-center text-highlight-blue border-b-2 border-b-highlight-blue cursor-pointer text-left sm:text-sm text-xs w-fit sm:py-3 pt-5' : 'flex flex-row gap-2 items-center sm:text-sm text-xs w-fit sm:py-3 pt-5 text-label-grey text-left hover:text-highlight-blue hover:border-highlight-blue hover:border-b-2 cursor-pointer'}`}>
            <p>{t('Pre-Conciliation')}</p>
            <i className="sm:ph-bold ph-gear-fine"></i>
          </div>
          <div onClick={() => setOpenTab(2)} className={`${openTab === 2 ? 'flex flex-row gap-2 items-center text-highlight-blue border-b-2 border-b-highlight-blue cursor-pointer text-left sm:text-sm text-xs w-fit sm:py-3 pt-5' : 'flex flex-row gap-2 items-center sm:text-sm text-xs w-fit sm:py-3 pt-5 text-label-grey text-left hover:text-highlight-blue hover:border-highlight-blue hover:border-b-2 cursor-pointer'}`}>
            <p>{t('DisputeTimeline')}</p>
            <i className="sm:ph-bold ph-clock-counter-clockwise"></i>
          </div>
          <div onClick={() => setOpenTab(1)} className={`${openTab === 1 ? 'flex flex-row gap-2 items-center text-highlight-blue border-b-2 border-b-highlight-blue cursor-pointer text-left sm:text-sm text-xs w-fit sm:py-3 pt-5' : 'flex flex-row gap-2 items-center sm:text-sm text-xs w-fit sm:py-3 pt-5 text-label-grey text-left hover:text-highlight-blue hover:border-highlight-blue hover:border-b-2 cursor-pointer'}`}>
            <p>{t('DisputeOverview')}</p>
            <i className="sm:ph-bold ph-chart-pie"></i>
          </div>
          {complaintDetails?.isFromScores &&
            <div onClick={() => setOpenTab(4)} className={`${openTab === 4 ? 'flex flex-row gap-2 items-center text-highlight-blue border-b-2 border-b-highlight-blue cursor-pointer text-left sm:text-sm text-xs w-fit sm:py-3 pt-5' : 'flex flex-row gap-2 items-center sm:text-sm text-xs w-fit sm:py-3 pt-5 text-label-grey text-left hover:text-highlight-blue hover:border-highlight-blue hover:border-b-2 cursor-pointer'}`}>
              <p>{t('ScoresHistory')}</p>
              <i className="sm:ph-bold ph-chart-pie"></i>
            </div>
          }
        </div>

        {openTab === 1 &&
          (
            <div className="flex flex-col w-full h-fit gap-14 overflow-hidden overflow-y-scroll">
              <div className="flex flex-col gap-2 w-full sm:w-2/3">
                <div className="flex sm:flex-row flex-col w-full gap-2 sm:gap-0">
                  <p className="text-xs text-label-grey sm:w-1/3">{t('DateofRegistration')}: </p>
                  <p className="text-xs text-label-grey sm:w-2/3">{dateTimeHelper(complaintDetails?.createdAt)}</p>
                </div>

                {/* pain is life life is pain */}
                {complaintDetails?.otherMetaData?.IntermediatorystatusUpdatedAt && complaintDetails?.otherMetaData?.IntermediatoryrejectionStatusUpdate &&
                  <div className="flex sm:flex-row flex-col w-full gap-2 sm:gap-0">
                    <p className="text-xs text-label-grey sm:w-1/3">{t('IntermediaryReasonforRejection')} : </p>
                    <p className="text-xs text-label-grey sm:w-2/3">{complaintDetails?.otherMetaData?.IntermediatoryrejectionStatusUpdate}</p>
                  </div>
                }
                {complaintDetails?.otherMetaData?.MIIstatusUpdatedAt && complaintDetails?.otherMetaData?.MIIrejectionStatusUpdate &&
                  <div className="flex sm:flex-row flex-col w-full gap-2 sm:gap-0">
                    <p className="text-xs text-label-grey sm:w-1/3">{t('MIIReasonforRejection')} : </p>
                    <div className="flex flex-col gap-1 sm:w-2/3">
                      <p className="text-xs text-label-grey">{complaintDetails?.otherMetaData?.MIIrejectionStatusUpdate}</p>
                    </div>

                  </div>
                }
                {complaintDetails?.otherMetaData?.MIIstatusUpdatedAt && complaintDetails?.otherMetaData?.MIIpreConResolvedtatusUpdate &&
                  <div className="flex sm:flex-row flex-col w-full gap-2 sm:gap-0">
                    <p className="text-xs text-label-grey sm:w-1/3">{t('MIIReasonforResolutioninPre-Conciliation')} : </p>
                    <div className="flex flex-col gap-1 sm:w-2/3">
                      <p className="text-xs text-label-grey">{complaintDetails?.otherMetaData?.MIIpreConResolvedtatusUpdate}</p>
                    </div>

                  </div>
                }
                {complaintDetails?.primaryCaseRefId &&
                  <div className="flex sm:flex-row flex-col w-full gap-2 sm:gap-0">
                    <p className="text-xs text-label-grey sm:w-1/3">{t('IdofPrimaryDispute')}: </p>
                    <p className="text-xs text-label-grey sm:w-2/3">{complaintDetails?.primaryCaseRefId}</p>
                  </div>
                }
                {complaintDetails?.primaryCaseRefDate &&
                  <div className="flex sm:flex-row flex-col w-full gap-2 sm:gap-0">
                    <p className="text-xs text-label-grey sm:w-1/3">{t('DateofPrimaryDispute')}: </p>
                    <p className="text-xs text-label-grey sm:w-2/3">{dateHelper(complaintDetails?.primaryCaseRefDate)}</p>
                  </div>
                }
                {complaintDetails?.caseAllocateToODR &&

                  <div className="flex sm:flex-row flex-col w-full gap-2 sm:gap-0">
                    <p className="text-xs text-label-grey sm:w-1/3">{t('DateofConciliationInitiated')}: </p>
                    <p className="text-xs text-label-grey sm:w-2/3">{dateTimeHelper(moment(complaintDetails?.caseAllocateToODR).add(-5, 'days'))}</p>
                  </div>
                }
                {complaintDetails?.escalatedAt &&
                  <div className="flex sm:flex-row flex-col w-full gap-2 sm:gap-0">
                    <p className="text-xs text-label-grey sm:w-1/3">{t('DateofArbitrationInitiated')}: </p>
                    <p className="text-xs text-label-grey sm:w-2/3">{dateTimeHelper(complaintDetails?.escalatedAt)}</p>
                  </div>
                }
                {complaintDetails?.otherMetaData?.resolvedAt &&
                  <div className="flex sm:flex-row flex-col w-full  gap-2 sm:gap-0">
                    <p className="text-xs text-label-grey sm:w-1/3">{t('DateofResolution')}: </p>
                    <p className="text-xs text-label-grey sm:w-2/3">{dateTimeHelper(complaintDetails?.otherMetaData?.resolvedAt)}</p>
                  </div>
                }
                {complaintDetails?.otherMetaData?.cancelledAt &&
                  <div className="flex sm:flex-row flex-col w-full gap-2 sm:gap-0">
                    <p className="text-xs text-label-grey sm:w-1/3">{t('DateofCancellation')}: </p>
                    <p className="text-xs text-label-grey sm:w-2/3">{dateTimeHelper(complaintDetails?.otherMetaData?.cancelledAt)}</p>
                  </div>
                }
              </div>

              <div className="flex flex-col gap-4 sm:w-2/3">
                <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                  <p className="text-xs sm:w-1/3 text-label-grey">{t('Name')}:</p>
                  <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.complainantName}</p>
                </div>
                {complaintDetails?.otherMetaData?.authLetterFile && (
                  <div className="flex sm:flex-row flex-col w-full gap-2 sm:gap-0">
                    <p className="text-xs sm:w-1/3 text-label-grey">{t('AuthorizationLetter')}:</p>
                    <div
                      onClick={(e) => generateLink(complaintDetails?.otherMetaData?.authLetterFile?.file, complaintDetails?.otherMetaData?.authLetterFile?.fileName)}
                      className="relative flex sm:w-2/3 flex-col cursor-pointer gap-2 text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey bg-white rounded-lg group overflow-hidden"
                    >
                      {/* File Name and Upload Info */}
                      <p>{complaintDetails?.otherMetaData?.authLetterFile?.fileName?.length > 20 ? (complaintDetails?.otherMetaData?.authLetterFile?.fileName).slice(0, 20) + '...' : complaintDetails?.otherMetaData?.authLetterFile?.fileName}</p>
                      <p className="text-back-gray">
                        {t('uploadedby', {
                          name: complaintDetails?.otherMetaData?.authLetterFile?.uploadedBy,
                          date: dateTimeHelper(complaintDetails?.otherMetaData?.authLetterFile?.uploadedAt)
                        })}
                      </p>
                      <div className="absolute gap-1 inset-0 bg-attachment-grey bg-opacity-70 flex items-center justify-center opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                        <i className="ph ph-eye text-label-grey text-lg"></i>
                        <p className="text-label-grey text-xs">{t('ViewDocument')}</p>
                      </div>
                    </div>
                  </div>
                )}
                {complaintDetails?.CategoryMaster?.categoryName && (
                  <div className="flex sm:flex-row flex-col w-full gap-2 sm:gap-0">
                    <p className="text-xs sm:w-1/3 text-label-grey">{t('DisputeCategory')}:</p>
                    <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.CategoryMaster?.categoryName}</p>
                  </div>
                )}
                {complaintDetails?.SubCategoryMaster?.categoryName && (
                  <div className="flex sm:flex-row flex-col w-full gap-2 sm:gap-0">
                    <p className="text-xs sm:w-1/3 text-label-grey">{t('DisputeSub-Category')}:</p>
                    <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.SubCategoryMaster?.categoryName}</p>
                  </div>
                )}
                <div className="flex sm:flex-row flex-col w-full gap-2 sm:gap-0">
                  <p className="text-xs sm:w-1/3 text-label-grey">{t('CompanyName')}:</p>
                  <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.respondentName}</p>
                </div>

                {complaintDetails?.ComplainNatures?.filter((complainNature: ComplainNature) => !complainNature.otherMetaData.isFromScores)?.map((complainNature: ComplainNature, index: number) => {
                  return (<Fragment key={`${complainNature?.complainNatureId}-${index}`}>
                    <div className="flex sm:flex-row flex-col w-full">
                      <div className="flex flex-col gap-1 sm:w-1/3">
                        <p className="text-xs text-label-grey">{index === 0 ? `${t('DisputeDetails')}:` : ''}</p>
                        <p className="text-xs text-back-gray">{index === 0 ? `${complaintDetails?.ComplainNatures?.filter((complainNature: ComplainNature) => !complainNature.otherMetaData.isFromScores)?.length} ${complaintDetails?.ComplainNatures?.filter((complainNature: ComplainNature) => !complainNature.otherMetaData.isFromScores)?.length > 1 ? t('Disputes') : t('Dispute')} ${t('Filed')}` : ''}</p>
                      </div>
                      <div className="flex flex-col sm:w-2/3 gap-3">
                        <p className="text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complainNature?.natureType} / {complainNature?.subNatureType}</p>
                        {currentURL === 'mii' && complainNature?.otherMetaData?.miiNatureType &&
                          <p className="text-xs py-3 text-label-blue pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complainNature?.otherMetaData?.miiNatureType}</p>
                        }
                        <textarea className="border border-border-grey disabled:bg-disable-grey py-3 px-5 text-xs text-label-grey rounded-xl"
                          rows={7} disabled={true}
                          value={complainNature?.description}></textarea>
                        {complainNature?.ComplainComments?.map((comment: ComplainComment, parentIndex: any) => {
                          return (<>
                            {!comment?.parentId &&
                              <div className="flex flex-col pl-3 gap-3 border-l border-border-grey">
                                <div className="flex flex-row gap-2">
                                  <p className="text-xs text-highlight-blue font-bold">{comment?.User?.userName}, {comment?.User?.Organisation?.organisationName || 'Investor'}</p>
                                  <p className="text-xs text-back-gray">{dateTimeHelper(comment?.createdAt)}</p>
                                </div>
                                <p className="text-xs text-highlight-blue px-4 py-2 rounded-lg border border-highlight-blue">{comment?.description}</p>
                                {/* Use this div to multithread the comments */}
                                {comment?.NestedComments?.map((nestedComment: ComplainComment, nestedIndex: any) => {
                                  return (
                                    <>
                                      {
                                        !isCollapsed(parentIndex) && (
                                          <div key={nestedComment.createdAt}
                                            className={`flex flex-col gap-3 pl-3 border-l border-border-grey 
                                      }`}>
                                            <div className="flex flex-row gap-2">
                                              <p className="text-xs text-highlight-blue font-bold">{nestedComment?.User?.userName}, {nestedComment?.User?.Organisation?.organisationName || 'Investor'}</p>
                                              <p className="text-xs text-back-gray">{dateTimeHelper(nestedComment?.createdAt)}</p>
                                            </div>

                                            <p className="text-xs text-highlight-blue px-4 py-2 rounded-lg border border-highlight-blue">{nestedComment?.description}</p>
                                          </div>
                                        )
                                      }

                                    </>
                                  )
                                })}
                                {comment?.NestedComments?.length > 0 && (
                                  <>
                                    <p
                                      className={`text-xs text-back-gray cursor-pointer hover:text-back-gray hover:underline ${isCollapsed(index) ? '' : 'hidden'
                                        }`}
                                      onClick={() => handleCollapse(parentIndex)}
                                    >
                                      {comment?.NestedComments?.length} {t('replies')}
                                    </p>
                                    <p
                                      className={`text-xs text-back-gray cursor-pointer hover:text-back-gray hover:underline ${isCollapsed(index) ? 'hidden' : ''
                                        }`}
                                      onClick={() => handleCollapse(parentIndex)}
                                    >
                                      {t('Collapse')}
                                    </p>
                                  </>

                                )}
                              </div>
                            }
                          </>
                          )
                        })}
                      </div>
                    </div>
                    <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                      <p className="text-xs sm:w-1/3 text-label-grey">{index === 0 ? `${t('Attachments')}:` : ''}</p>
                      <div className="flex flex-col sm:w-2/3 gap-2">
                        {complainNature?.files?.map((file: any) => {
                          return (
                            // <div key={complainNature?.complainNatureId} className="flex flex-col gap-2 text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey bg-white rounded-xl">
                            //   <p onClick={(e: any) => downloadFile(file.file, complainNature?.complainNatureId)} className="cursor-pointer">{file?.fileName}</p>
                            //   <p className="text-back-gray">{t('uploadedby', {
                            //     name: file?.uploadedBy,
                            //     date: dateTimeHelper(file?.uploadedAt)
                            //   })}</p>
                            // </div>
                            <div className="flex sm:flex-row flex-col w-full gap-2 sm:gap-0">
                              <div
                                onClick={(e) => generateAttachmentLink(file.file, complainNature?.complainNatureId, file)}
                                className="relative flex flex-col cursor-pointer gap-2 text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey bg-white rounded-xl group overflow-hidden"
                              >
                                {/* File Name and Upload Info */}
                                <p>{file?.fileName?.length > 20 ? (file?.fileName).slice(0, 20) + '...' : file?.fileName}</p>
                                <p className="text-back-gray">
                                  {t('uploadedby', {
                                    name: file?.uploadedBy,
                                    date: dateTimeHelper(file?.uploadedAt)
                                  })}
                                </p>
                                <div className="absolute gap-1 inset-0 bg-attachment-grey bg-opacity-70 flex items-center justify-center opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                                  <i className="ph ph-eye text-label-grey text-lg"></i>
                                  <p className="text-label-grey text-xs">{t('ViewDocument')}</p>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    <hr />
                  </Fragment>)
                })}
                {complaintDetails?.typeOfSecurity &&
                  <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                    <p className="text-xs sm:w-1/3 text-label-grey">{t('TypeofSecurities')}:</p>
                    <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.typeOfSecurity}</p>
                  </div>
                }
                {complaintDetails?.modeOfHoldingSecurities?.mode &&
                  <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                    <p className="text-xs sm:w-1/3 text-label-grey">{t('ModeofHoldingSecurities')}:</p>
                    <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.modeOfHoldingSecurities?.mode}</p>
                  </div>
                }
                {
                  complaintDetails?.modeOfHoldingSecurities?.mode === "Physical" &&
                  <>
                    {complaintDetails?.modeOfHoldingSecurities?.folioNo &&
                      <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                        <p className="text-xs sm:w-1/3 text-label-grey">{t('FolioNo')}:</p>
                        <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.modeOfHoldingSecurities?.folioNo}</p>
                      </div>
                    }
                    {complaintDetails?.modeOfHoldingSecurities?.distinctiveNo &&

                      <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                        <p className="text-xs sm:w-1/3 text-label-grey">{t('DistinctiveNo')}:</p>
                        <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.modeOfHoldingSecurities?.distinctiveNo}</p>
                      </div>
                    }
                    {complaintDetails?.modeOfHoldingSecurities?.certificateNo &&

                      <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                        <p className="text-xs sm:w-1/3 text-label-grey">{t('CertificateNo')}:</p>
                        <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.modeOfHoldingSecurities?.certificateNo}</p>
                      </div>
                    }
                  </>
                }
                {complaintDetails?.modeOfHoldingSecurities?.mode === 'Demat' &&
                  <>
                    {complaintDetails?.modeOfHoldingSecurities?.dpId &&

                      <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                        <p className="text-xs sm:w-1/3 text-label-grey">{t('DP ID')}:</p>
                        <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.modeOfHoldingSecurities?.dpId}</p>
                      </div>
                    }
                    {complaintDetails?.modeOfHoldingSecurities?.clientId &&

                      <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                        <p className="text-xs sm:w-1/3 text-label-grey">{t('ClientID')}:</p>
                        <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.modeOfHoldingSecurities?.clientId}</p>
                      </div>
                    }
                    <hr />
                  </>
                }
                {/* {complaintDetails?.claimAmount && complaintDetails?.claimAmount > 0 && */}
                <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                  <p className="text-xs sm:w-1/3 text-label-grey">{t('ClaimAmount')}:</p>
                  <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">₹ {complaintDetails?.claimAmount}</p>
                </div>
                {/* } */}
                <p className="text-xs text-back-gray">{t('Complainant')} - {t(complaintDetail?.complainantType)}</p>
                {complaintDetail?.complainantType === 'Investor' &&
                  <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                    <p className="text-xs sm:w-1/3 text-label-grey">{t('MobileNumber')}:</p>
                    <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.Complainant?.phoneNumber}</p>
                  </div>
                }
                {complaintDetail?.complainantType === 'Investor' &&
                  <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                    <p className="text-xs sm:w-1/3 text-label-grey">{t('email')}:</p>
                    <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.Complainant?.email}</p>
                  </div>
                }
                {complaintDetail?.complainantType === 'Intermediary' && currentURL === 'odr' &&
                  <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                    <p className="text-xs sm:w-1/3 text-label-grey">{t('MobileNumber')}:</p>
                    <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.Complainant?.phoneNumber}</p>
                  </div>
                }
                {complaintDetail?.complainantType === 'Intermediary' && currentURL === 'odr' &&
                  <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                    <p className="text-xs sm:w-1/3 text-label-grey">{t('email')}:</p>
                    <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.Complainant?.email}</p>
                  </div>
                }
                {complaintDetails?.Complainant?.panNumber &&
                  <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                    <p className="text-xs sm:w-1/3 text-label-grey">{t('PAN')}:</p>
                    <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.Complainant?.panNumber}</p>
                  </div>
                }
                {complaintDetails?.Complainant?.Organisation?.gstNumber &&
                  <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                    <p className="text-xs sm:w-1/3 text-label-grey">{t('GST')}:</p>
                    <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.Complainant?.Organisation?.gstNumber}</p>
                  </div>
                }
                {complaintDetails?.Complainant?.city &&
                  <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                    <p className="text-xs sm:w-1/3 text-label-grey">{t('City')}:</p>
                    <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.Complainant?.city}</p>
                  </div>
                }
                {complaintDetails?.Complainant?.state &&
                  <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                    <p className="text-xs sm:w-1/3 text-label-grey">{t('State')}:</p>
                    <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.Complainant?.state}</p>
                  </div>
                }
                {complaintDetails?.Complainant?.address && complaintDetail?.complainantType === 'Investor' &&
                  <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                    <p className="text-xs sm:w-1/3 text-label-grey">{t('Address')}:</p>
                    <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.Complainant?.address}</p>
                  </div>
                }
                {complaintDetails?.Complainant?.Organisation?.city && currentURL === 'odr' &&
                  <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                    <p className="text-xs sm:w-1/3 text-label-grey">{t('City')}:</p>
                    <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.Complainant?.Organisation?.city}</p>
                  </div>
                }
                {complaintDetails?.Complainant?.Organisation?.state && currentURL === 'odr' &&
                  <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                    <p className="text-xs sm:w-1/3 text-label-grey">{t('State')}:</p>
                    <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.Complainant?.Organisation?.state}</p>
                  </div>
                }
                {complaintDetails?.Complainant?.Organisation?.registeredAddress && complaintDetail?.complainantType === 'Intermediary' && currentURL === 'odr' &&
                  <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                    <p className="text-xs sm:w-1/3 text-label-grey">{t('Address')}:</p>
                    <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.Complainant?.Organisation?.registeredAddress}</p>
                  </div>
                }
                <p className="text-xs text-back-gray">{t('Respondent')} - {t(complaintDetail?.respondentType)}</p>
                {complaintDetail?.respondentType === 'Investor' ? (
                  <>
                    {complaintDetails?.RespondentUser?.userName &&
                      <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                        <p className="text-xs sm:w-1/3 text-label-grey">{t('Name')}:</p>
                        <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.RespondentUser?.userName}</p>
                      </div>
                    }
                    {complaintDetails?.RespondentUser?.phoneNumber &&
                      <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                        <p className="text-xs sm:w-1/3 text-label-grey">{t('MobileNumber')}:</p>
                        <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.RespondentUser?.phoneNumber}</p>
                      </div>
                    }
                    <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                      <p className="text-xs sm:w-1/3 text-label-grey">{t('email')}:</p>
                      <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.RespondentUser?.email}</p>
                    </div>
                    {complaintDetails?.RespondentUser?.address &&
                      <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                        <p className="text-xs sm:w-1/3 text-label-grey">{t('Address')}:</p>
                        <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.RespondentUser?.address}</p>
                      </div>
                    }
                    {complaintDetails?.RespondentUser?.panNumber &&
                      <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                        <p className="text-xs sm:w-1/3 text-label-grey">{t('PAN')}:</p>
                        <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.RespondentUser?.panNumber}</p>
                      </div>
                    }
                  </>
                ) : (
                  <>
                    {complaintDetails?.respondentNumber && currentURL !== 'investor' &&
                      <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                        <p className="text-xs sm:w-1/3 text-label-grey">{t('MobileNumber')}:</p>
                        <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.respondentNumber}</p>
                      </div>
                    }
                    {currentURL !== 'investor' &&
                      <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                        <p className="text-xs sm:w-1/3 text-label-grey">{t('email')}:</p>
                        <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.respondentEmail}</p>
                      </div>
                    }
                    {complaintDetails?.Intermediatory?.registeredAddress && currentURL !== 'investor' &&
                      <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                        <p className="text-xs sm:w-1/3 text-label-grey">{t('Address')}:</p>
                        <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.Intermediatory?.registeredAddress}</p>
                      </div>
                    }
                    {complaintDetails?.Intermediatory?.gstNumber &&
                      <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                        <p className="text-xs sm:w-1/3 text-label-grey">{t('GST')}:</p>
                        <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.Intermediatory?.gstNumber}</p>
                      </div>
                    }
                    {complaintDetails?.Intermediatory?.city &&
                      <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                        <p className="text-xs sm:w-1/3 text-label-grey">{t('City')}:</p>
                        <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.Intermediatory?.city}</p>
                      </div>
                    }
                    {complaintDetails?.Intermediatory?.state &&
                      <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                        <p className="text-xs sm:w-1/3 text-label-grey">{t('State')}:</p>
                        <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.Intermediatory?.state}</p>
                      </div>
                    }
                  </>
                )}
                <div className="flex sm:flex-row sm:gap-0 w-full items-center">
                  <p className="text-xs text-back-gray sm:w-1/3">{t('AuthorizedPersonDetails')}</p>
                  {currentURL === 'mii' && <button onClick={(e: any) => setIsAuthorizedDetailsEditing(true)} className="text-xs text-label-grey pl-3">{t('Edit')}</button>}
                  {currentURL === 'mii' && isAuthorizedDetailsEditing &&
                    <button onClick={saveAuthorizedDetails} className="text-xs text-label-blue pl-3 pr-3">{t('Save')}</button>
                  }
                </div>
                {!isAuthorizedDetailsEditing &&
                  <>
                    {complaintDetails?.authorizedPersonName &&
                      <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                        <p className="text-xs sm:w-1/3 text-label-grey">{t('NameOfAuthorizedPerson')}:</p>
                        <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.authorizedPersonName}</p>
                      </div>
                    }
                    {complaintDetails?.authorizedPersonPAN && currentURL === 'mii' &&
                      <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                        <p className="text-xs sm:w-1/3 text-label-grey">{t('PANOfAuthorizedPerson')}:</p>
                        <p className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey bg-disable-grey rounded-lg">{complaintDetails?.authorizedPersonPAN}</p>
                      </div>
                    }
                  </>
                }
                {isAuthorizedDetailsEditing && currentURL === 'mii' &&
                  <>
                    <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                      <p className="text-xs sm:w-1/3 text-label-grey">{t('NameOfAuthorizedPerson')}:</p>
                      <input type="text" name="authorizedPersonName" id="authorizedPersonName" onChange={(e) => setUpdateComplaintDetails({
                        ...complaintUpdateDetails,
                        authorizedPersonName: e.target.value
                      })} value={complaintUpdateDetails?.authorizedPersonName} className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                    </div>
                    <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 w-full">
                      <p className="text-xs sm:w-1/3 text-label-grey">{t('PANOfAuthorizedPerson')}:</p>
                      <input type="text" name="authorizedPersonPAN" id="authorizedPersonPAN" onChange={(e) => setUpdateComplaintDetails({
                        ...complaintUpdateDetails,
                        authorizedPersonPAN: e.target.value
                      })} value={complaintUpdateDetails?.authorizedPersonPAN} className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                    </div>
                  </>
                }
              </div>
            </div>
          )
        }
        {openTab === 2 && <ComplaintUpdates user={user} complaintDetail={complaintDetail}></ComplaintUpdates>}
        {openTab === 3 && <PreConciliation user={user} complaintDetails={complaintDetails} setComplaintDetails={setComplaintDetails} />}
        {openTab === 4 &&
          <PreScoresConciliation complaintDetails={complaintDetails} setComplaintDetails={setComplaintDetails} />
        }
        {/* Send to MII Modal  */}
        {sendMIIReview && (
          <SendMIIReviewModal setSendMIIReview={setSendMIIReview} handleStatusChange={handleStatusChange} />
        )}

        {/* Push to ODR Modal  */}
        {pushToODR && (
          <PushToODRModal setPushToODR={setPushToODR} handleStatusChange={handleStatusChange} />
        )}

        {/* Reject Complaint Modal  */}
        {rejectComplaint && (
          <RejectComplaintModal user={user} setRejectComplaint={setRejectComplaint} handleStatusChange={handleStatusChange} />
        )}

        {/* Push to ODR Modal  */}
        {resolvedComplaint && (
          <ResolvedPreConciliationModal setResolvedComplaint={setResolvedComplaint} handleStatusChange={handleStatusChange} />
        )}

        {/* Review Rejection Modal  */}
        {reviewRejection && (
          <ReviewRejection reason={complaintDetails?.otherMetaData?.MIIrejectionStatusUpdate} setReviewRejection={setReviewRejection} />
        )}

        {/* Transfer Dispute Modal  */}
        {transferDispute && (
          <TransferDisputeModal miis={allMiis} setTransferDispute={setTransferDispute} complainId={complainUUID} />
        )}

        {arbInfoModal && (
          <ArbitrationInfoModal setArbInfoModal={setArbInfoModal} />
        )}
        {/* Initiate Arbitration Modal */}
        {initiateArb && (
          <div
            className="fixed z-10 inset-0 flex items-center justify-center "
            id="modal"
          >
            <div className="px-8 sm:px-0 sm:w-1/3">
              <div className="flex justify-center pt-4 pb-20 w-fit h-fit">
                <div
                  className="fixed inset-0 transition-opacity"
                  onClick={() => setInititateArb(false)}
                >
                  <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <div
                  className="inline-block align-center w-fit bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <form >
                    <div className="flex flex-col gap-1 px-8 pb-5">
                      <h1 className='font-semibold sm:text-2xl text-lg pt-3'>{t('WouldInitiateArbitration')}</h1>
                      <div className='flex flex-col gap-4 pb-3 pt-4 text-label-grey'>
                        <p className="text-xs text-label-grey">{t('YourDisputeUnableResolved')} </p>

                      </div>
                      <div className='flex flex-col sm:flex-row w-full pt-4 gap-4'>

                        <Link to='initiate-arbitration' type="submit" className="hover:bg-highlight-blue/[0.4] bg-highlight-blue justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12" aria-label={t('Proceed')}
                          onClick={(e) => {

                          }}
                        >
                          <p>{t('Proceed')}</p>
                        </Link>

                        <button aria-label={t('cancel')} onClick={() => setInititateArb(false)} className=" hover:bg-black/[0.1] cursor-pointer hover:text-white justify-center text-xs bg-white text-back-gray px-12 py-3 rounded-xl">
                          {t('cancel')}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        )}

        {extendTimelineModal && (
          <div
            className="fixed z-10 inset-0 flex items-center justify-center "
            id="modal"
          >
            <div className="px-8 sm:px-0 sm:w-1/3">
              <div className="flex justify-center pt-4 pb-20 w-fit h-fit">
                <div
                  className="fixed inset-0 transition-opacity"
                  onClick={() => setExtendTimelineModal(false)}
                >
                  <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <div
                  className="inline-block align-center w-fit bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <form >
                    <div className="flex flex-col gap-1 px-8 pb-5">
                      <h1 className='font-semibold sm:text-2xl text-lg pt-3'>{t('ExtendTimelineDispute')}</h1>
                      <div className='flex flex-col gap-4 pb-3 pt-4 text-label-grey'>
                        <p className="text-xs text-label-grey">
                          {t('ByClickingProceedTimelineExtended', {
                            days: complaintDetails?.status === 'CONCILIATION_IN_PROGRESS' ? 10 : 30
                          })}
                        </p>
                      </div>
                      <div className='flex flex-col sm:flex-row w-full pt-4 gap-4'>

                        <button type="submit" className="hover:bg-highlight-blue/[0.4] bg-highlight-blue justify-center text-white flex flex-row gap-2 text-xs py-3 rounded-xl disabled:bg-highlight-blue/[0.4] px-12" aria-label="Proceed"
                          onClick={(e) => {
                            extendTimeline(e)
                          }}
                        >
                          <p>{t('Proceed')}</p>
                        </button>
                        <button aria-label="Cancel" onClick={() => setExtendTimelineModal(false)} className=" hover:bg-black/[0.1] cursor-pointer hover:text-white justify-center text-xs bg-white text-back-gray px-12 py-3 rounded-xl">
                          {t('Cancel')}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>
        )}
        {previewLink && (
          <div className="fixed z-10 inset-0 flex items-center justify-center " id="modal">
            <div className="px-8 sm:px-0 sm:w-2/3 h-3/4">
              <div className="flex justify-center pt-4 w-full h-full">
                <div
                  className="fixed inset-0 transition-opacity"
                  onClick={() => setPreviewLink("")}
                >
                  <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <div
                  className="inline-block align-center w-full bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <div className="flex flex-col gap-1 px-8 pb-5" style={{ height: "-webkit-fill-available" }}>
                    <div className="flex flex-row w-full justify-between items-center">
                      <h1 className='font-semibold sm:text-2xl text-lg pt-3 mr-2'>{t('AuthorizationLetter')}&nbsp;&nbsp;
                        <Tooltip
                          html={
                            <div className=" text-left flex flex-col gap-1">
                              <p className="font-semibold">{t('DownloadAuthorizationLetter')}</p>
                            </div>
                          }
                          position="top"
                          arrow={false}
                          animation="shift"
                          size="small"
                          {...({
                            children: <i onClick={() => downloadFile(complaintDetails?.otherMetaData?.authLetterFile?.file, undefined, complaintDetails?.otherMetaData?.authLetterFile?.fileName)}
                              className="ph ph-download-simple text-label-grey h-fit p-0.5" style={{ borderRadius: '50%' }}></i>
                          } as any)} // Type assertion to bypass TS errors
                        >
                        </Tooltip>
                      </h1>
                      <i className={`ph-bold ph-x text-lg text-label-grey cursor-pointer`} onClick={() => setPreviewLink("")}></i>
                    </div>
                    <div className="p-4 overflow-hidden" style={{ maxHeight: "calc(90vh - 80px)" }}>
                      {complaintDetails?.otherMetaData?.authLetterFile?.fileType === 'application/pdf' ?
                        (<div className="w-full h-full">
                          <PDFPreview url={previewLink} />
                        </div>) :
                        complaintDetails?.otherMetaData?.authLetterFile?.fileType.startsWith('image/') ?
                          (<img src={previewLink} alt={complaintDetails?.otherMetaData?.authLetterFile?.fileName}
                            className="w-full h-full object-contain" />) : (<p>Unsupported file type</p>)} </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {attachmentPreviewLink && (
          <div className="fixed z-10 inset-0 flex items-center justify-center " id="modal">
            <div className="px-8 sm:px-0 sm:w-2/3 h-3/4">
              <div className="flex justify-center pt-4 w-full h-full">
                <div
                  className="fixed inset-0 transition-opacity"
                  onClick={() => setAttachmentPreviewLink("")}
                >
                  <div className="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <div
                  className="inline-block align-center w-full bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
                  role="dialog"
                  aria-modal="true"
                  aria-labelledby="modal-headline"
                >
                  <div className="flex flex-col gap-1 px-8 pb-5" style={{ height: "-webkit-fill-available" }}>
                    <div className="flex flex-row w-full justify-between items-center">
                      <h1 className='font-semibold sm:text-2xl text-lg pt-3 mr-2'>{selectedFile?.fileName}&nbsp;&nbsp;
                        <Tooltip
                          html={
                            <div className=" text-left flex flex-col gap-1">
                              <p className="font-semibold">{t('Download')} {selectedFile?.fileName}</p>
                            </div>
                          }
                          position="top"
                          arrow={false}
                          animation="shift"
                          size="small"
                          {...({
                            children: <i onClick={() => downloadFile(selectedFile?.file, complainNatureId!)}
                              className="ph ph-download-simple text-label-grey h-fit p-0.5" style={{ borderRadius: '50%' }}></i>
                          } as any)} // Type assertion to bypass TS errors
                        >
                        </Tooltip>
                      </h1>
                      <i className={`ph-bold ph-x text-lg text-label-grey cursor-pointer`} onClick={() => setAttachmentPreviewLink("")}></i>
                    </div>
                    <DocumentViewerModal selectedFile={selectedFile} previewLink={attachmentPreviewLink}></DocumentViewerModal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
    </div>
  )
}

export default ComplaintDetails