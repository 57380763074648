import { toast } from "react-hot-toast";
import { ApiController } from "../apiController";
import { loader, notifyError, notifySuccess } from "../helpers/helper";
import { ListConArbParams, ODRConArbitrator } from "../swagger/models";

export const ConArbStore = (set: any, get: any, store: any) => ({
  conciliatorArbitrators: [],
  searchConArbitrators: [],
  conArbCount: 0,
  conArbProfile: {},
  /**
   *
   */
  listConciliatorArbitrators: async (conArbParams: ListConArbParams) => {
    const toastId = loader("Loading Categories...");
    try {
      const arbData =
        await ApiController.getInstance().conArbApi.allConArbitrators(
          conArbParams
        );
      set({
        conciliatorArbitrators: arbData.data.Admins,
        conArbCount: arbData.data.adminCount
      });
      toast.dismiss(toastId);
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   * Get list of arbitrators based on search query.
   *
   * @param {string} userType - Type of user (CONCILIATOR or ARBITRATOR)
   * @param {string} searchQuery - Search query
   *
   * @returns {Promise<void>}
   */
  getListConciliatorArbitrators: async (userType: string, searchQuery: string) => {
    const toastId = loader("Loading Categories...");
    try {
      const arbData =
        await ApiController.getInstance().conArbApi.conArbitrators(
          userType,
          searchQuery
        );
      set({
        searchConArbitrators: arbData.data,
      });
      toast.dismiss(toastId);
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  /**
   *
   * @param odrConArbId
   */
  getConArbProfile: async (odrConArbId: number) => {
    const toastId = loader("Loading Categories...");
    try {
      const categoryData =
        await ApiController.getInstance().conArbApi.getConArbitrator(
          odrConArbId
        );
      set({
        conArbProfile: categoryData.data,
      });
      toast.dismiss(toastId);
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
  addConArbProfile: async (odrConArbDetail: ODRConArbitrator) => {
    const toastId = loader("Creating profile...");
    try {
      const categoryData =
        await ApiController.getInstance().conArbApi.createConArbitrator(
          odrConArbDetail
        );
      set({
        conArbProfile: categoryData.data,
      });
      toast.dismiss(toastId);
      notifySuccess(`${odrConArbDetail.userType} profile added successfully!`)
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },

  updateConArbProfile: async (odrConArbDetail: ODRConArbitrator) => {
    const toastId = loader("Updating details...");
    try {
      const categoryData =
        await ApiController.getInstance().conArbApi.updateConArbitrator(
          odrConArbDetail
        );
      set({
        conArbProfile: categoryData.data,
      });
      toast.dismiss(toastId);
      notifySuccess(`${odrConArbDetail.userType} profile updated successfully!`)
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },

  deleteConArbProfile: async (odrConArbId: number) => {
    const toastId = loader("Deleting detail...");
    try {
      await ApiController.getInstance().conArbApi.deleteConArbitrator(
        odrConArbId
      );
      toast.dismiss(toastId);
      notifySuccess("Profile deleted successfully!");
      store.listConciliatorArbitrators();
    } catch (error: any) {
      toast.dismiss(toastId);
      notifyError(error?.response?.data?.message);
    }
  },
});
