/* tslint:disable */
/* eslint-disable */
/**
 * OCP Module
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@odrways.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ArbitrationReport } from '../models';
import { Complain } from '../models';
import { ComplainComment } from '../models';
import { ComplainNature } from '../models';
import { ComplainUpdate } from '../models';
import { ConciliationReport } from '../models';
import { DisputeArbitrator } from '../models';
import { ListComplainParams } from '../models';
import { ListComplaintOutput } from '../models';
import { NatureFile } from '../models';
import { SignedUrl } from '../models';
/**
 * ComplainApi - axios parameter creator
 * @export
 */
export const ComplainApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all files for a complainId
         * @summary Get files for a complaint
         * @param {string} complainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allFiles: async (complainId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'complainId' is not null or undefined
            if (complainId === null || complainId === undefined) {
                throw new RequiredError('complainId','Required parameter complainId was null or undefined when calling allFiles.');
            }
            const localVarPath = `/complain-management/all_files`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (complainId !== undefined) {
                localVarQueryParameter['complainId'] = complainId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all updates for a complainId
         * @summary Get case updates for a complaint
         * @param {string} complainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUpdates: async (complainId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'complainId' is not null or undefined
            if (complainId === null || complainId === undefined) {
                throw new RequiredError('complainId','Required parameter complainId was null or undefined when calling allUpdates.');
            }
            const localVarPath = `/complain-management/all_updates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (complainId !== undefined) {
                localVarQueryParameter['complainId'] = complainId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get details of an handbook
         * @summary Get handbook's detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKey: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/complain-management/apiKey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the authorized person detais
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizedPersonDetais: async (body: Complain, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authorizedPersonDetais.');
            }
            const localVarPath = `/complain-management/authorized_person_detais`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Trigger Beckn Status and Update APIs
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cascade: async (body: Complain, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling cascade.');
            }
            const localVarPath = `/complain-management/cascade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the complaints
         * @param {ComplainNature} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complainDescription: async (body: ComplainNature, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling complainDescription.');
            }
            const localVarPath = `/complain-management/complain_description`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate complain excel for MII
         * @summary Generate complain excel for MII
         * @param {ListComplainParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complainExcel: async (body: ListComplainParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling complainExcel.');
            }
            const localVarPath = `/complain-management/complain_excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get details of requested complaint
         * @summary Get complaint's detail
         * @param {string} complaintUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complaint: async (complaintUUID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'complaintUUID' is not null or undefined
            if (complaintUUID === null || complaintUUID === undefined) {
                throw new RequiredError('complaintUUID','Required parameter complaintUUID was null or undefined when calling complaint.');
            }
            const localVarPath = `/complain-management/complaint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (complaintUUID !== undefined) {
                localVarQueryParameter['complaintUUID'] = complaintUUID;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create the arbitral award detais
         * @param {ArbitrationReport} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createArbitralAward: async (body: ArbitrationReport, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createArbitralAward.');
            }
            const localVarPath = `/complain-management/arbitral_award`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create the arbitral award detais
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createArbitrators: async (body: Complain, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createArbitrators.');
            }
            const localVarPath = `/complain-management/arbitrators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add the details of specific comment
         * @param {ComplainComment} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment: async (body: ComplainComment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createComment.');
            }
            const localVarPath = `/complain-management/comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create the conciliator award detais
         * @param {ConciliationReport} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConciliatorAward: async (body: ConciliationReport, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createConciliatorAward.');
            }
            const localVarPath = `/complain-management/conciliator_award`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a complaint update of a complaint
         * @summary Create a complaint update
         * @param {ComplainUpdate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUpdate: async (body: ComplainUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createUpdate.');
            }
            const localVarPath = `/complain-management/create_update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the details of specific comment
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment: async (orgId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling deleteComment.');
            }
            const localVarPath = `/complain-management/comment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Download File of a complaint
         * @summary Download files of a complaint
         * @param {NatureFile} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile: async (body: NatureFile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling downloadFile.');
            }
            const localVarPath = `/complain-management/download_file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Extend the timeline of the dispute
         * @param {string} complaintUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDisputePdf: async (complaintUUID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'complaintUUID' is not null or undefined
            if (complaintUUID === null || complaintUUID === undefined) {
                throw new RequiredError('complaintUUID','Required parameter complaintUUID was null or undefined when calling exportDisputePdf.');
            }
            const localVarPath = `/complain-management/export_dispute_pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (complaintUUID !== undefined) {
                localVarQueryParameter['complaintUUID'] = complaintUUID;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Extend the timeline of the dispute
         * @param {string} complaintUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendTimeline: async (complaintUUID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'complaintUUID' is not null or undefined
            if (complaintUUID === null || complaintUUID === undefined) {
                throw new RequiredError('complaintUUID','Required parameter complaintUUID was null or undefined when calling extendTimeline.');
            }
            const localVarPath = `/complain-management/extend_timeline`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (complaintUUID !== undefined) {
                localVarQueryParameter['complaintUUID'] = complaintUUID;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the Nature Type detais
         * @param {string} complaintNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchScoresComplain: async (complaintNumber: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'complaintNumber' is not null or undefined
            if (complaintNumber === null || complaintNumber === undefined) {
                throw new RequiredError('complaintNumber','Required parameter complaintNumber was null or undefined when calling fetchScoresComplain.');
            }
            const localVarPath = `/complain-management/fetch_scores_complain`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (complaintNumber !== undefined) {
                localVarQueryParameter['complaintNumber'] = complaintNumber;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the complaints
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileComplaint: async (body: Complain, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling fileComplaint.');
            }
            const localVarPath = `/complain-management/file_complaint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the complaints
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileComplaintIntermediary: async (body: Complain, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling fileComplaintIntermediary.');
            }
            const localVarPath = `/complain-management/file_complaint_intermediary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the complaints
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileComplaintInvestor: async (body: Complain, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling fileComplaintInvestor.');
            }
            const localVarPath = `/complain-management/file_complaint_investor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the complaints
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileScoresComplaint: async (body: Complain, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling fileScoresComplaint.');
            }
            const localVarPath = `/complain-management/file_scores_complaint`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the arbitral award detais
         * @param {number} complainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArbitralAward: async (complainId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'complainId' is not null or undefined
            if (complainId === null || complainId === undefined) {
                throw new RequiredError('complainId','Required parameter complainId was null or undefined when calling getArbitralAward.');
            }
            const localVarPath = `/complain-management/arbitral_award`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (complainId !== undefined) {
                localVarQueryParameter['complainId'] = complainId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the arbitral award detais
         * @param {number} complainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArbitrators: async (complainId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'complainId' is not null or undefined
            if (complainId === null || complainId === undefined) {
                throw new RequiredError('complainId','Required parameter complainId was null or undefined when calling getArbitrators.');
            }
            const localVarPath = `/complain-management/arbitrators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (complainId !== undefined) {
                localVarQueryParameter['complainId'] = complainId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the conciliator award detais
         * @param {number} complainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConciliatorAward: async (complainId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'complainId' is not null or undefined
            if (complainId === null || complainId === undefined) {
                throw new RequiredError('complainId','Required parameter complainId was null or undefined when calling getConciliatorAward.');
            }
            const localVarPath = `/complain-management/conciliator_award`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (complainId !== undefined) {
                localVarQueryParameter['complainId'] = complainId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get details of an handbook
         * @summary Get handbook's detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handbooks: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/complain-management/handbooks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of all the complaints
         * @param {ListComplainParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComplaints: async (body: ListComplainParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling listComplaints.');
            }
            const localVarPath = `/complain-management/list_complaints`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all MII Names
         * @summary Get case updates for a complaint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        miis: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/complain-management/miis`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload profile of the user
         * @param {string} fileData 
         * @param {number} complainNatureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        natureAttachment: async (fileData: string, complainNatureId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileData' is not null or undefined
            if (fileData === null || fileData === undefined) {
                throw new RequiredError('fileData','Required parameter fileData was null or undefined when calling natureAttachment.');
            }
            // verify required parameter 'complainNatureId' is not null or undefined
            if (complainNatureId === null || complainNatureId === undefined) {
                throw new RequiredError('complainNatureId','Required parameter complainNatureId was null or undefined when calling natureAttachment.');
            }
            const localVarPath = `/complain-management/nature_attachment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (complainNatureId !== undefined) {
                localVarQueryParameter['complainNatureId'] = complainNatureId;
            }


            if (fileData !== undefined) { 
                localVarFormParams.append('fileData', fileData as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save Odr Info
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odrInfo: async (body: Complain, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling odrInfo.');
            }
            const localVarPath = `/complain-management/odr-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Redirect the user to respective ODR Platform
         * @param {string} complaintUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectToOdr: async (complaintUUID: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'complaintUUID' is not null or undefined
            if (complaintUUID === null || complaintUUID === undefined) {
                throw new RequiredError('complaintUUID','Required parameter complaintUUID was null or undefined when calling redirectToOdr.');
            }
            const localVarPath = `/complain-management/redirect_to_odr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (complaintUUID !== undefined) {
                localVarQueryParameter['complaintUUID'] = complaintUUID;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary MII Disputes to ODR
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/complain-management/regenerate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Transfer Disputes to ODR
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toOdr: async (body: Complain, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling toOdr.');
            }
            const localVarPath = `/complain-management/to_odr`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary MII Disputes to ODR
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transfer: async (body: Complain, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling transfer.');
            }
            const localVarPath = `/complain-management/transfer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the arbitral award detais
         * @param {ArbitrationReport} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArbitralAward: async (body: ArbitrationReport, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateArbitralAward.');
            }
            const localVarPath = `/complain-management/arbitral_award`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the conciliator award detais
         * @param {ConciliationReport} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConciliatorAward: async (body: ConciliationReport, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateConciliatorAward.');
            }
            const localVarPath = `/complain-management/conciliator_award`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the Nature Type detais
         * @param {ComplainNature} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNatureType: async (body: ComplainNature, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateNatureType.');
            }
            const localVarPath = `/complain-management/update_natureType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the Pre Con outcome Type detais
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProconOutcome: async (body: Complain, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateProconOutcome.');
            }
            const localVarPath = `/complain-management/update_procon_outcome`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Complaint Status
         * @summary Update Complaint Status
         * @param {ComplainUpdate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatus: async (body: ComplainUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateStatus.');
            }
            const localVarPath = `/complain-management/update_status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Complaint Sub Status
         * @summary Update Complaint Status
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubStatus: async (body: Complain, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateSubStatus.');
            }
            const localVarPath = `/complain-management/update_sub_status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Files upload for complain description
         * @summary Files upload for complain description
         * @param {string} file 
         * @param {number} [arbitrationReportId] 
         * @param {string} [documentType] 
         * @param {string} [summary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadArbitralFile: async (file: string, arbitrationReportId?: number, documentType?: string, summary?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadArbitralFile.');
            }
            const localVarPath = `/complain-management/upload_arbitral_file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (arbitrationReportId !== undefined) {
                localVarQueryParameter['arbitrationReportId'] = arbitrationReportId;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }

            if (summary !== undefined) {
                localVarQueryParameter['summary'] = summary;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Files upload for complain description
         * @summary Files upload for complain description
         * @param {string} file 
         * @param {number} [conciliationReportId] 
         * @param {string} [documentType] 
         * @param {string} [summary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConciliatorFile: async (file: string, conciliationReportId?: number, documentType?: string, summary?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadConciliatorFile.');
            }
            const localVarPath = `/complain-management/upload_conciliator_file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (conciliationReportId !== undefined) {
                localVarQueryParameter['conciliationReportId'] = conciliationReportId;
            }

            if (documentType !== undefined) {
                localVarQueryParameter['documentType'] = documentType;
            }

            if (summary !== undefined) {
                localVarQueryParameter['summary'] = summary;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * Files upload for complain description
         * @summary Files upload for complain description
         * @param {string} file 
         * @param {number} [subCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (file: string, subCategoryId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadFile.');
            }
            const localVarPath = `/complain-management/upload_file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (subCategoryId !== undefined) {
                localVarQueryParameter['subCategoryId'] = subCategoryId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * ODR Handbook file upload for complain description
         * @summary ODR Handbook file upload for complain description
         * @param {string} file 
         * @param {number} [subCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadOdrDemographic: async (file: string, subCategoryId?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadOdrDemographic.');
            }
            const localVarPath = `/complain-management/upload-odr-demographic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (subCategoryId !== undefined) {
                localVarQueryParameter['subCategoryId'] = subCategoryId;
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComplainApi - functional programming interface
 * @export
 */
export const ComplainApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get all files for a complainId
         * @summary Get files for a complaint
         * @param {string} complainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allFiles(complainId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrl>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).allFiles(complainId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all updates for a complainId
         * @summary Get case updates for a complaint
         * @param {string} complainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allUpdates(complainId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ComplainUpdate>>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).allUpdates(complainId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get details of an handbook
         * @summary Get handbook's detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKey(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complain>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).apiKey(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update the authorized person detais
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authorizedPersonDetais(body: Complain, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complain>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).authorizedPersonDetais(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Trigger Beckn Status and Update APIs
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cascade(body: Complain, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complain>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).cascade(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the complaints
         * @param {ComplainNature} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async complainDescription(body: ComplainNature, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComplainNature>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).complainDescription(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Generate complain excel for MII
         * @summary Generate complain excel for MII
         * @param {ListComplainParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async complainExcel(body: ListComplainParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).complainExcel(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get details of requested complaint
         * @summary Get complaint's detail
         * @param {string} complaintUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async complaint(complaintUUID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complain>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).complaint(complaintUUID, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create the arbitral award detais
         * @param {ArbitrationReport} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createArbitralAward(body: ArbitrationReport, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArbitrationReport>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).createArbitralAward(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create the arbitral award detais
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createArbitrators(body: Complain, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DisputeArbitrator>>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).createArbitrators(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Add the details of specific comment
         * @param {ComplainComment} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComment(body: ComplainComment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComplainComment>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).createComment(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create the conciliator award detais
         * @param {ConciliationReport} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConciliatorAward(body: ConciliationReport, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConciliationReport>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).createConciliatorAward(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Create a complaint update of a complaint
         * @summary Create a complaint update
         * @param {ComplainUpdate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUpdate(body: ComplainUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComplainUpdate>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).createUpdate(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete the details of specific comment
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComment(orgId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComplainComment>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).deleteComment(orgId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Download File of a complaint
         * @summary Download files of a complaint
         * @param {NatureFile} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFile(body: NatureFile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NatureFile>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).downloadFile(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Extend the timeline of the dispute
         * @param {string} complaintUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportDisputePdf(complaintUUID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).exportDisputePdf(complaintUUID, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Extend the timeline of the dispute
         * @param {string} complaintUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async extendTimeline(complaintUUID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complain>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).extendTimeline(complaintUUID, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update the Nature Type detais
         * @param {string} complaintNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchScoresComplain(complaintNumber: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complain>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).fetchScoresComplain(complaintNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the complaints
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileComplaint(body: Complain, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complain>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).fileComplaint(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the complaints
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileComplaintIntermediary(body: Complain, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complain>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).fileComplaintIntermediary(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the complaints
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileComplaintInvestor(body: Complain, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complain>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).fileComplaintInvestor(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the complaints
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fileScoresComplaint(body: Complain, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complain>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).fileScoresComplaint(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the arbitral award detais
         * @param {number} complainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArbitralAward(complainId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArbitrationReport>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).getArbitralAward(complainId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the arbitral award detais
         * @param {number} complainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArbitrators(complainId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DisputeArbitrator>>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).getArbitrators(complainId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the conciliator award detais
         * @param {number} complainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConciliatorAward(complainId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConciliationReport>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).getConciliatorAward(complainId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get details of an handbook
         * @summary Get handbook's detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handbooks(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complain>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).handbooks(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List of all the complaints
         * @param {ListComplainParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listComplaints(body: ListComplainParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListComplaintOutput>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).listComplaints(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get all MII Names
         * @summary Get case updates for a complaint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async miis(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complain>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).miis(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary upload profile of the user
         * @param {string} fileData 
         * @param {number} complainNatureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async natureAttachment(fileData: string, complainNatureId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComplainNature>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).natureAttachment(fileData, complainNatureId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Save Odr Info
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async odrInfo(body: Complain, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complain>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).odrInfo(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Redirect the user to respective ODR Platform
         * @param {string} complaintUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redirectToOdr(complaintUUID: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrl>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).redirectToOdr(complaintUUID, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary MII Disputes to ODR
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regenerate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complain>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).regenerate(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Transfer Disputes to ODR
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toOdr(body: Complain, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complain>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).toOdr(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary MII Disputes to ODR
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async transfer(body: Complain, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complain>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).transfer(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update the arbitral award detais
         * @param {ArbitrationReport} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateArbitralAward(body: ArbitrationReport, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArbitrationReport>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).updateArbitralAward(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update the conciliator award detais
         * @param {ConciliationReport} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConciliatorAward(body: ConciliationReport, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConciliationReport>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).updateConciliatorAward(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update the Nature Type detais
         * @param {ComplainNature} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNatureType(body: ComplainNature, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComplainNature>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).updateNatureType(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update the Pre Con outcome Type detais
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProconOutcome(body: Complain, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complain>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).updateProconOutcome(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update Complaint Status
         * @summary Update Complaint Status
         * @param {ComplainUpdate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStatus(body: ComplainUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComplainUpdate>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).updateStatus(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Update Complaint Sub Status
         * @summary Update Complaint Status
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubStatus(body: Complain, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Complain>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).updateSubStatus(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Files upload for complain description
         * @summary Files upload for complain description
         * @param {string} file 
         * @param {number} [arbitrationReportId] 
         * @param {string} [documentType] 
         * @param {string} [summary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadArbitralFile(file: string, arbitrationReportId?: number, documentType?: string, summary?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrl>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).uploadArbitralFile(file, arbitrationReportId, documentType, summary, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Files upload for complain description
         * @summary Files upload for complain description
         * @param {string} file 
         * @param {number} [conciliationReportId] 
         * @param {string} [documentType] 
         * @param {string} [summary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadConciliatorFile(file: string, conciliationReportId?: number, documentType?: string, summary?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrl>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).uploadConciliatorFile(file, conciliationReportId, documentType, summary, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Files upload for complain description
         * @summary Files upload for complain description
         * @param {string} file 
         * @param {number} [subCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(file: string, subCategoryId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrl>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).uploadFile(file, subCategoryId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * ODR Handbook file upload for complain description
         * @summary ODR Handbook file upload for complain description
         * @param {string} file 
         * @param {number} [subCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadOdrDemographic(file: string, subCategoryId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrl>> {
            const localVarAxiosArgs = await ComplainApiAxiosParamCreator(configuration).uploadOdrDemographic(file, subCategoryId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ComplainApi - factory interface
 * @export
 */
export const ComplainApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Get all files for a complainId
         * @summary Get files for a complaint
         * @param {string} complainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allFiles(complainId: string, options?: any): AxiosPromise<SignedUrl> {
            return ComplainApiFp(configuration).allFiles(complainId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all updates for a complainId
         * @summary Get case updates for a complaint
         * @param {string} complainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allUpdates(complainId: string, options?: any): AxiosPromise<Array<ComplainUpdate>> {
            return ComplainApiFp(configuration).allUpdates(complainId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get details of an handbook
         * @summary Get handbook's detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKey(options?: any): AxiosPromise<Complain> {
            return ComplainApiFp(configuration).apiKey(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the authorized person detais
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorizedPersonDetais(body: Complain, options?: any): AxiosPromise<Complain> {
            return ComplainApiFp(configuration).authorizedPersonDetais(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Trigger Beckn Status and Update APIs
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cascade(body: Complain, options?: any): AxiosPromise<Complain> {
            return ComplainApiFp(configuration).cascade(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the complaints
         * @param {ComplainNature} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complainDescription(body: ComplainNature, options?: any): AxiosPromise<ComplainNature> {
            return ComplainApiFp(configuration).complainDescription(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate complain excel for MII
         * @summary Generate complain excel for MII
         * @param {ListComplainParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complainExcel(body: ListComplainParams, options?: any): AxiosPromise<string> {
            return ComplainApiFp(configuration).complainExcel(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get details of requested complaint
         * @summary Get complaint's detail
         * @param {string} complaintUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complaint(complaintUUID: string, options?: any): AxiosPromise<Complain> {
            return ComplainApiFp(configuration).complaint(complaintUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create the arbitral award detais
         * @param {ArbitrationReport} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createArbitralAward(body: ArbitrationReport, options?: any): AxiosPromise<ArbitrationReport> {
            return ComplainApiFp(configuration).createArbitralAward(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create the arbitral award detais
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createArbitrators(body: Complain, options?: any): AxiosPromise<Array<DisputeArbitrator>> {
            return ComplainApiFp(configuration).createArbitrators(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add the details of specific comment
         * @param {ComplainComment} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment(body: ComplainComment, options?: any): AxiosPromise<ComplainComment> {
            return ComplainApiFp(configuration).createComment(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create the conciliator award detais
         * @param {ConciliationReport} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConciliatorAward(body: ConciliationReport, options?: any): AxiosPromise<ConciliationReport> {
            return ComplainApiFp(configuration).createConciliatorAward(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a complaint update of a complaint
         * @summary Create a complaint update
         * @param {ComplainUpdate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUpdate(body: ComplainUpdate, options?: any): AxiosPromise<ComplainUpdate> {
            return ComplainApiFp(configuration).createUpdate(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the details of specific comment
         * @param {number} orgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(orgId: number, options?: any): AxiosPromise<ComplainComment> {
            return ComplainApiFp(configuration).deleteComment(orgId, options).then((request) => request(axios, basePath));
        },
        /**
         * Download File of a complaint
         * @summary Download files of a complaint
         * @param {NatureFile} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile(body: NatureFile, options?: any): AxiosPromise<NatureFile> {
            return ComplainApiFp(configuration).downloadFile(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Extend the timeline of the dispute
         * @param {string} complaintUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDisputePdf(complaintUUID: string, options?: any): AxiosPromise<void> {
            return ComplainApiFp(configuration).exportDisputePdf(complaintUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Extend the timeline of the dispute
         * @param {string} complaintUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendTimeline(complaintUUID: string, options?: any): AxiosPromise<Complain> {
            return ComplainApiFp(configuration).extendTimeline(complaintUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the Nature Type detais
         * @param {string} complaintNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchScoresComplain(complaintNumber: string, options?: any): AxiosPromise<Complain> {
            return ComplainApiFp(configuration).fetchScoresComplain(complaintNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the complaints
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileComplaint(body: Complain, options?: any): AxiosPromise<Complain> {
            return ComplainApiFp(configuration).fileComplaint(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the complaints
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileComplaintIntermediary(body: Complain, options?: any): AxiosPromise<Complain> {
            return ComplainApiFp(configuration).fileComplaintIntermediary(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the complaints
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileComplaintInvestor(body: Complain, options?: any): AxiosPromise<Complain> {
            return ComplainApiFp(configuration).fileComplaintInvestor(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the complaints
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fileScoresComplaint(body: Complain, options?: any): AxiosPromise<Complain> {
            return ComplainApiFp(configuration).fileScoresComplaint(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the arbitral award detais
         * @param {number} complainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArbitralAward(complainId: number, options?: any): AxiosPromise<ArbitrationReport> {
            return ComplainApiFp(configuration).getArbitralAward(complainId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the arbitral award detais
         * @param {number} complainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArbitrators(complainId: number, options?: any): AxiosPromise<Array<DisputeArbitrator>> {
            return ComplainApiFp(configuration).getArbitrators(complainId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the conciliator award detais
         * @param {number} complainId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConciliatorAward(complainId: number, options?: any): AxiosPromise<ConciliationReport> {
            return ComplainApiFp(configuration).getConciliatorAward(complainId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get details of an handbook
         * @summary Get handbook's detail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handbooks(options?: any): AxiosPromise<Complain> {
            return ComplainApiFp(configuration).handbooks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of all the complaints
         * @param {ListComplainParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listComplaints(body: ListComplainParams, options?: any): AxiosPromise<ListComplaintOutput> {
            return ComplainApiFp(configuration).listComplaints(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all MII Names
         * @summary Get case updates for a complaint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        miis(options?: any): AxiosPromise<Complain> {
            return ComplainApiFp(configuration).miis(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary upload profile of the user
         * @param {string} fileData 
         * @param {number} complainNatureId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        natureAttachment(fileData: string, complainNatureId: number, options?: any): AxiosPromise<ComplainNature> {
            return ComplainApiFp(configuration).natureAttachment(fileData, complainNatureId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save Odr Info
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        odrInfo(body: Complain, options?: any): AxiosPromise<Complain> {
            return ComplainApiFp(configuration).odrInfo(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Redirect the user to respective ODR Platform
         * @param {string} complaintUUID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectToOdr(complaintUUID: string, options?: any): AxiosPromise<SignedUrl> {
            return ComplainApiFp(configuration).redirectToOdr(complaintUUID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary MII Disputes to ODR
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regenerate(options?: any): AxiosPromise<Complain> {
            return ComplainApiFp(configuration).regenerate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Transfer Disputes to ODR
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toOdr(body: Complain, options?: any): AxiosPromise<Complain> {
            return ComplainApiFp(configuration).toOdr(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary MII Disputes to ODR
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        transfer(body: Complain, options?: any): AxiosPromise<Complain> {
            return ComplainApiFp(configuration).transfer(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the arbitral award detais
         * @param {ArbitrationReport} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArbitralAward(body: ArbitrationReport, options?: any): AxiosPromise<ArbitrationReport> {
            return ComplainApiFp(configuration).updateArbitralAward(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the conciliator award detais
         * @param {ConciliationReport} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConciliatorAward(body: ConciliationReport, options?: any): AxiosPromise<ConciliationReport> {
            return ComplainApiFp(configuration).updateConciliatorAward(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the Nature Type detais
         * @param {ComplainNature} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNatureType(body: ComplainNature, options?: any): AxiosPromise<ComplainNature> {
            return ComplainApiFp(configuration).updateNatureType(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the Pre Con outcome Type detais
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProconOutcome(body: Complain, options?: any): AxiosPromise<Complain> {
            return ComplainApiFp(configuration).updateProconOutcome(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Complaint Status
         * @summary Update Complaint Status
         * @param {ComplainUpdate} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStatus(body: ComplainUpdate, options?: any): AxiosPromise<ComplainUpdate> {
            return ComplainApiFp(configuration).updateStatus(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Update Complaint Sub Status
         * @summary Update Complaint Status
         * @param {Complain} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubStatus(body: Complain, options?: any): AxiosPromise<Complain> {
            return ComplainApiFp(configuration).updateSubStatus(body, options).then((request) => request(axios, basePath));
        },
        /**
         * Files upload for complain description
         * @summary Files upload for complain description
         * @param {string} file 
         * @param {number} [arbitrationReportId] 
         * @param {string} [documentType] 
         * @param {string} [summary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadArbitralFile(file: string, arbitrationReportId?: number, documentType?: string, summary?: string, options?: any): AxiosPromise<SignedUrl> {
            return ComplainApiFp(configuration).uploadArbitralFile(file, arbitrationReportId, documentType, summary, options).then((request) => request(axios, basePath));
        },
        /**
         * Files upload for complain description
         * @summary Files upload for complain description
         * @param {string} file 
         * @param {number} [conciliationReportId] 
         * @param {string} [documentType] 
         * @param {string} [summary] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConciliatorFile(file: string, conciliationReportId?: number, documentType?: string, summary?: string, options?: any): AxiosPromise<SignedUrl> {
            return ComplainApiFp(configuration).uploadConciliatorFile(file, conciliationReportId, documentType, summary, options).then((request) => request(axios, basePath));
        },
        /**
         * Files upload for complain description
         * @summary Files upload for complain description
         * @param {string} file 
         * @param {number} [subCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(file: string, subCategoryId?: number, options?: any): AxiosPromise<SignedUrl> {
            return ComplainApiFp(configuration).uploadFile(file, subCategoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * ODR Handbook file upload for complain description
         * @summary ODR Handbook file upload for complain description
         * @param {string} file 
         * @param {number} [subCategoryId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadOdrDemographic(file: string, subCategoryId?: number, options?: any): AxiosPromise<SignedUrl> {
            return ComplainApiFp(configuration).uploadOdrDemographic(file, subCategoryId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComplainApi - object-oriented interface
 * @export
 * @class ComplainApi
 * @extends {BaseAPI}
 */
export class ComplainApi extends BaseAPI {
    /**
     * Get all files for a complainId
     * @summary Get files for a complaint
     * @param {string} complainId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public allFiles(complainId: string, options?: any) {
        return ComplainApiFp(this.configuration).allFiles(complainId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get all updates for a complainId
     * @summary Get case updates for a complaint
     * @param {string} complainId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public allUpdates(complainId: string, options?: any) {
        return ComplainApiFp(this.configuration).allUpdates(complainId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get details of an handbook
     * @summary Get handbook's detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public apiKey(options?: any) {
        return ComplainApiFp(this.configuration).apiKey(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update the authorized person detais
     * @param {Complain} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public authorizedPersonDetais(body: Complain, options?: any) {
        return ComplainApiFp(this.configuration).authorizedPersonDetais(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Trigger Beckn Status and Update APIs
     * @param {Complain} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public cascade(body: Complain, options?: any) {
        return ComplainApiFp(this.configuration).cascade(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the complaints
     * @param {ComplainNature} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public complainDescription(body: ComplainNature, options?: any) {
        return ComplainApiFp(this.configuration).complainDescription(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Generate complain excel for MII
     * @summary Generate complain excel for MII
     * @param {ListComplainParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public complainExcel(body: ListComplainParams, options?: any) {
        return ComplainApiFp(this.configuration).complainExcel(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get details of requested complaint
     * @summary Get complaint's detail
     * @param {string} complaintUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public complaint(complaintUUID: string, options?: any) {
        return ComplainApiFp(this.configuration).complaint(complaintUUID, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create the arbitral award detais
     * @param {ArbitrationReport} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public createArbitralAward(body: ArbitrationReport, options?: any) {
        return ComplainApiFp(this.configuration).createArbitralAward(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create the arbitral award detais
     * @param {Complain} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public createArbitrators(body: Complain, options?: any) {
        return ComplainApiFp(this.configuration).createArbitrators(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Add the details of specific comment
     * @param {ComplainComment} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public createComment(body: ComplainComment, options?: any) {
        return ComplainApiFp(this.configuration).createComment(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create the conciliator award detais
     * @param {ConciliationReport} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public createConciliatorAward(body: ConciliationReport, options?: any) {
        return ComplainApiFp(this.configuration).createConciliatorAward(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Create a complaint update of a complaint
     * @summary Create a complaint update
     * @param {ComplainUpdate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public createUpdate(body: ComplainUpdate, options?: any) {
        return ComplainApiFp(this.configuration).createUpdate(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete the details of specific comment
     * @param {number} orgId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public deleteComment(orgId: number, options?: any) {
        return ComplainApiFp(this.configuration).deleteComment(orgId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Download File of a complaint
     * @summary Download files of a complaint
     * @param {NatureFile} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public downloadFile(body: NatureFile, options?: any) {
        return ComplainApiFp(this.configuration).downloadFile(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Extend the timeline of the dispute
     * @param {string} complaintUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public exportDisputePdf(complaintUUID: string, options?: any) {
        return ComplainApiFp(this.configuration).exportDisputePdf(complaintUUID, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Extend the timeline of the dispute
     * @param {string} complaintUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public extendTimeline(complaintUUID: string, options?: any) {
        return ComplainApiFp(this.configuration).extendTimeline(complaintUUID, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update the Nature Type detais
     * @param {string} complaintNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public fetchScoresComplain(complaintNumber: string, options?: any) {
        return ComplainApiFp(this.configuration).fetchScoresComplain(complaintNumber, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the complaints
     * @param {Complain} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public fileComplaint(body: Complain, options?: any) {
        return ComplainApiFp(this.configuration).fileComplaint(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the complaints
     * @param {Complain} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public fileComplaintIntermediary(body: Complain, options?: any) {
        return ComplainApiFp(this.configuration).fileComplaintIntermediary(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the complaints
     * @param {Complain} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public fileComplaintInvestor(body: Complain, options?: any) {
        return ComplainApiFp(this.configuration).fileComplaintInvestor(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the complaints
     * @param {Complain} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public fileScoresComplaint(body: Complain, options?: any) {
        return ComplainApiFp(this.configuration).fileScoresComplaint(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the arbitral award detais
     * @param {number} complainId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public getArbitralAward(complainId: number, options?: any) {
        return ComplainApiFp(this.configuration).getArbitralAward(complainId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the arbitral award detais
     * @param {number} complainId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public getArbitrators(complainId: number, options?: any) {
        return ComplainApiFp(this.configuration).getArbitrators(complainId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get the conciliator award detais
     * @param {number} complainId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public getConciliatorAward(complainId: number, options?: any) {
        return ComplainApiFp(this.configuration).getConciliatorAward(complainId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get details of an handbook
     * @summary Get handbook's detail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public handbooks(options?: any) {
        return ComplainApiFp(this.configuration).handbooks(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary List of all the complaints
     * @param {ListComplainParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public listComplaints(body: ListComplainParams, options?: any) {
        return ComplainApiFp(this.configuration).listComplaints(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get all MII Names
     * @summary Get case updates for a complaint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public miis(options?: any) {
        return ComplainApiFp(this.configuration).miis(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary upload profile of the user
     * @param {string} fileData 
     * @param {number} complainNatureId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public natureAttachment(fileData: string, complainNatureId: number, options?: any) {
        return ComplainApiFp(this.configuration).natureAttachment(fileData, complainNatureId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Save Odr Info
     * @param {Complain} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public odrInfo(body: Complain, options?: any) {
        return ComplainApiFp(this.configuration).odrInfo(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Redirect the user to respective ODR Platform
     * @param {string} complaintUUID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public redirectToOdr(complaintUUID: string, options?: any) {
        return ComplainApiFp(this.configuration).redirectToOdr(complaintUUID, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary MII Disputes to ODR
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public regenerate(options?: any) {
        return ComplainApiFp(this.configuration).regenerate(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Transfer Disputes to ODR
     * @param {Complain} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public toOdr(body: Complain, options?: any) {
        return ComplainApiFp(this.configuration).toOdr(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary MII Disputes to ODR
     * @param {Complain} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public transfer(body: Complain, options?: any) {
        return ComplainApiFp(this.configuration).transfer(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update the arbitral award detais
     * @param {ArbitrationReport} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public updateArbitralAward(body: ArbitrationReport, options?: any) {
        return ComplainApiFp(this.configuration).updateArbitralAward(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update the conciliator award detais
     * @param {ConciliationReport} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public updateConciliatorAward(body: ConciliationReport, options?: any) {
        return ComplainApiFp(this.configuration).updateConciliatorAward(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update the Nature Type detais
     * @param {ComplainNature} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public updateNatureType(body: ComplainNature, options?: any) {
        return ComplainApiFp(this.configuration).updateNatureType(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update the Pre Con outcome Type detais
     * @param {Complain} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public updateProconOutcome(body: Complain, options?: any) {
        return ComplainApiFp(this.configuration).updateProconOutcome(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update Complaint Status
     * @summary Update Complaint Status
     * @param {ComplainUpdate} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public updateStatus(body: ComplainUpdate, options?: any) {
        return ComplainApiFp(this.configuration).updateStatus(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Update Complaint Sub Status
     * @summary Update Complaint Status
     * @param {Complain} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public updateSubStatus(body: Complain, options?: any) {
        return ComplainApiFp(this.configuration).updateSubStatus(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Files upload for complain description
     * @summary Files upload for complain description
     * @param {string} file 
     * @param {number} [arbitrationReportId] 
     * @param {string} [documentType] 
     * @param {string} [summary] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public uploadArbitralFile(file: string, arbitrationReportId?: number, documentType?: string, summary?: string, options?: any) {
        return ComplainApiFp(this.configuration).uploadArbitralFile(file, arbitrationReportId, documentType, summary, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Files upload for complain description
     * @summary Files upload for complain description
     * @param {string} file 
     * @param {number} [conciliationReportId] 
     * @param {string} [documentType] 
     * @param {string} [summary] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public uploadConciliatorFile(file: string, conciliationReportId?: number, documentType?: string, summary?: string, options?: any) {
        return ComplainApiFp(this.configuration).uploadConciliatorFile(file, conciliationReportId, documentType, summary, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Files upload for complain description
     * @summary Files upload for complain description
     * @param {string} file 
     * @param {number} [subCategoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public uploadFile(file: string, subCategoryId?: number, options?: any) {
        return ComplainApiFp(this.configuration).uploadFile(file, subCategoryId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * ODR Handbook file upload for complain description
     * @summary ODR Handbook file upload for complain description
     * @param {string} file 
     * @param {number} [subCategoryId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComplainApi
     */
    public uploadOdrDemographic(file: string, subCategoryId?: number, options?: any) {
        return ComplainApiFp(this.configuration).uploadOdrDemographic(file, subCategoryId, options).then((request) => request(this.axios, this.basePath));
    }
}
