import { act, Fragment, useEffect, useState } from 'react'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { ArbitrationReport, DisputeArbitrator, ODRConArbitrator, User } from '../../../swagger/models';
import { useCombinedStore } from '../../../store';
import PanelOfArbitrators from './PanelOfArbitrators';
import { notifyError } from '../../../helpers/helper';
import ConciliationReportComponent from './ConciliationReport';
import { dateHelper } from '../../common-module/helper';
import { Tooltip } from 'react-tippy';
import PreConciliationReport from './PreConciliationReport';
import { motion, AnimatePresence } from 'framer-motion';
import _ from "lodash";

interface OutcomeDetailsProps {
  disputeId: any;
  claimAmount: number;
  status: string;
  miiId: number;
  conciliationExtension: any;
  arbitrationExtension: any;
  odrAssigned: string;
  outcome: string
}

function OutcomeDetails({ disputeId, claimAmount, status, miiId, conciliationExtension, arbitrationExtension, odrAssigned, outcome }: OutcomeDetailsProps) {
  const { t } = useTranslation();
  const [selectedProcess, setSelectedProcess] = useState<string>('Pre-Conciliation')
  const [isSegmentDetailsEdit, setIsSegmentDetailsEdit] = useState<boolean>(false)
  const [isProcessdetailsEdit, setIsProcessdetailsEdit] = useState<boolean>(false)
  const [isOutcomedetailsEdit, setIsOutcomedetailsEdit] = useState<boolean>(false)
  const [isCompliancedetailsEdit, setIsCompliancedetailsEdit] = useState<boolean>(false)
  const [isPostArbEdit, setIsPostArbEdit] = useState<boolean>(false)
  const [isActionClientEdit, setIsActionClientEdit] = useState<boolean>(false)
  const [isOfficerDetailsEdit, setIsOfficerDetailsEdit] = useState<boolean>(false)
  const [isArbitratorEdit, setIsArbitratorEdit] = useState<boolean>(true)
  const [arbitratorAppointmentModal, setArbitratorAppointmentModal] = useState<boolean>(false)
  const [arbitratorsDetails, setArbitratorsDetails] = useState<DisputeArbitrator[]>([]);
  const [activeTab, setActiveTab] = useState(1);
  const [editingRowIndex, setEditingRowIndex] = useState<any>({
    arbIndex: 1,
    rowIndex: -1
  })
  const [filteredArbitrators, setFilteredArbitrators] = useState<ODRConArbitrator[]>([]);
  const [dropdown, setDropdown] = useState(false)
  const [arbitratorSearchTerm, setArbitratorSearchTerm] = useState<string>('');
  const delayedQuery = useRef(_.debounce(q => getListConciliatorArbitrators('Arbitrator', q), 500)).current;

  const [awardFileUploadDetails, setAwardFileUploadDetails] = useState<any>({
    file: '',
    documentType: '',
    summary: ''
  })
  const [awardUploadModal, setAwardUploadModal] = useState(false)

  const currentURL = window.location.href.split('/')[3]

  const [arbitrationReport, setArbitrationReport] = useState<ArbitrationReport>({
    complainId: disputeId,
  })
  const getListConciliatorArbitrators = useCombinedStore((state: any) => state.getListConciliatorArbitrators);
  const updateArbitralAward = useCombinedStore((state: any) => state.updateArbitralAward);
  const createArbitralAward = useCombinedStore((state: any) => state.createArbitralAward);
  const createArbitrator = useCombinedStore((state: any) => state.createArbitrator);
  const getArbitralAward = useCombinedStore((state: any) => state.getArbitralAward);
  const getArbitrators = useCombinedStore((state: any) => state.getArbitrators);
  const arbitralFiles = useCombinedStore((state: any) => state.arbitralFiles);
  const arbitralAwardDetails = useCombinedStore((state: any) => state.arbitralAwardDetails);
  const conciliatorAwardDetails = useCombinedStore((state: any) => state.conciliatorAwardDetails);
  const selectedArbitrators = useCombinedStore((state: any) => state.selectedArbitrators);
  const downloadFileOfComplaint = useCombinedStore((state: any) => state.downloadFile)
  const uploadArbitralFile = useCombinedStore((state: any) => state.uploadArbitralFile);
  const listGetUsers = useCombinedStore((state: any) => state.listGetUsers);
  const officers = useCombinedStore((state: any) => state.officers);
  const searchConArbitrators = useCombinedStore((state: any) => state.searchConArbitrators);
  const arbResolutionTime = useCombinedStore((state: any) => state.arbResolutionTime);

  const handleAddRow = () => {
    setArbitratorsDetails([
      ...arbitratorsDetails,
      {
        arbitratorName: '',
        arbitratorRejectionDate: '',
        arbitratorAcceptanceDate: '',
        arbitratorAppointedDate: '',
        isApproved: null,
        isReappointed: arbitratorsDetails.filter(i => i.arbIndex === activeTab).length > 1 ? true : false,
        complainId: disputeId,
        process: 'Arbitration',
        arbIndex: activeTab
      },
    ]);
  };

  const handleInputChange = (
    index: number,
    field: keyof DisputeArbitrator,
    value: string
  ) => {
    const updatingObject = arbitratorsDetails.filter(arb => arb.arbIndex === activeTab)[index]
    const findIndex = arbitratorsDetails.findIndex(arb => arb.arbIndex === activeTab && arb.arbitratorName === updatingObject.arbitratorName)

    const updatedDetails = [...arbitratorsDetails];
    updatedDetails[findIndex].reappointedBehalfOf = arbitratorsDetails.filter(arb => arb.arbIndex === activeTab).length > 0 ? (arbitratorsDetails.filter(arb => arb.arbIndex === activeTab)[index - 1]?.arbitratorPAN || null) : null;
    if (field === 'isApproved') {
      updatedDetails[findIndex].arbitratorRejectionDate = null;
      updatedDetails[findIndex].arbitratorAcceptanceDate = null;
    }
    updatedDetails[findIndex][field] = value;
    setArbitratorsDetails(updatedDetails);
  };

  const saveOutComeDetails = (detailType: string, e: any) => {
    if (e) {
      e.preventDefault();
    }
    if (arbitrationReport.arbitrationReportId) {
      updateArbitralAward(arbitrationReport, detailType)
    } else {
      createArbitralAward(arbitrationReport, detailType)
    }
    setIsOutcomedetailsEdit(false)
    setIsCompliancedetailsEdit(false)
    setIsProcessdetailsEdit(false)
    setIsSegmentDetailsEdit(false)
    setIsOfficerDetailsEdit(false)
    setIsPostArbEdit(false)
    setIsActionClientEdit(false)
  }

  const saveConArbDetails = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    if (arbitratorsDetails.length === 0) {
      notifyError(t('PleaseAddArbitrator'))
      return false;
    }
    if (arbitratorsDetails.filter((e: DisputeArbitrator) => !e.disputeArbitratorId && !e.odrConArbId).length > 0) {
      notifyError(t('PleaseSelectArbitrator'))
      return false;
    }
    createArbitrator({
      complainId: disputeId,
      DisputeArbitrators: arbitratorsDetails.filter((e: DisputeArbitrator) => e.arbitratorName.length > 2)
    }, 'Arbitration',
    )
    setIsArbitratorEdit(false)
  }

  const cancelForm = () => {
    const arbitrators = selectedArbitrators.filter((arbitrator: DisputeArbitrator) =>
      arbitrator.process.toLowerCase() === 'arbitration'
    );
    // Create deep copies to avoid reference issues
    const arbitratorsCopy = arbitrators.map((item: DisputeArbitrator) => ({ ...item }));
    setArbitratorsDetails(arbitratorsCopy);
  }

  const captureInputChange = (e: any) => {
    const { name, value } = e.target
    setArbitrationReport({
      ...arbitrationReport,
      [name]: value,
    });
  }

  useEffect(() => {
    setArbitrationReport({
      ...arbitrationReport,
      complainId: disputeId
    })
    if (disputeId) {
      getArbitralAward(disputeId)
      getArbitrators(disputeId)
    }
    if (currentURL === 'mii' || currentURL === 'odr') {
      listGetUsers(miiId)
    }
  }, [disputeId])

  useEffect(() => {
    if (arbitralAwardDetails?.arbitrationReportId) {
      setArbitrationReport(arbitralAwardDetails)
    } else {
      setArbitrationReport({
        ...arbitrationReport,
        officerName: conciliatorAwardDetails.officerName,
        officerId: conciliatorAwardDetails.officerId,
        segment: conciliatorAwardDetails.segment
      })
    }
  }, [arbitralAwardDetails, conciliatorAwardDetails])

  useEffect(() => {
    if (selectedArbitrators?.length > 0) {
      const arbitrators = selectedArbitrators.filter((arbitrator: DisputeArbitrator) =>
        arbitrator.process.toLowerCase() === 'arbitration'
      );
      // Create deep copies to avoid reference issues
      const arbitratorsCopy = arbitrators.map((item: DisputeArbitrator) => ({ ...item }));
      setArbitratorsDetails(arbitratorsCopy);
    }
  }, [selectedArbitrators])

  const handleUploadAwards = (e: any) => {
    const file = e.target.files;
    let fileSize = 0
    for (const f of file) {
      fileSize += f.size;
    }
    if (fileSize > 100 * 1024 * 1024) {
      notifyError(t('UploadFileExceeds'))
      return false;
    }
    setAwardFileUploadDetails({
      ...awardFileUploadDetails,
      file: file[0],
    })
  }

  const handleRemoveFile = () => {
    setAwardFileUploadDetails({
      ...awardFileUploadDetails,
      file: null,
    });
  }

  const submitFileDetails = (e: any) => {
    e.preventDefault();
    if (!awardFileUploadDetails.file) {
      notifyError(t('PleaseUploadDocument'))
      return false;
    }
    uploadArbitralFile(awardFileUploadDetails, arbitrationReport?.arbitrationReportId);
    setAwardUploadModal(false)
    handleRemoveFile()
  }

  const downloadFile = async (key: string) => {
    let res = await downloadFileOfComplaint(key, disputeId, null, null, null, arbitrationReport?.arbitrationReportId, null)
    if (!res.error) window.open(res.file)
  }

  const selectOfficer = (e: any) => {
    setArbitrationReport({
      ...arbitrationReport,
      officerName: officers.find((o: User) => o.userId === parseInt(e.target.value, 10)).userName,
      officerId: e.target.value,
    });
  }

  useEffect(() => {
    if (status === 'ARBITRATION_REQUESTED' || status === 'ARBITRATION_INITIATED' || status === 'ARBITRATION_IN_PROGRESS' || status === 'DISPUTE_RESOLVED_-_ARBITRATION') {
      setSelectedProcess('Arbitration')
    }
    if (status === 'CONCILIATION_INITIATED' || status === 'CONCILIATION_IN_PROGRESS' || status === 'CONCILIATION_FAILED' || status === 'DISPUTE_RESOLVED_-_CONCILIATION') {
      setSelectedProcess('Conciliation')
    }
  }, [status])

  const handleArbitratorSearch = (e: any, index: number) => {
    setEditingRowIndex({
      rowIndex: index,
      arbIndex: activeTab
    })
    const { value } = e.target;
    setArbitratorSearchTerm(value);
    delayedQuery(value);
    if (value.length > 2) {
      setDropdown(true)
    }
  }

  useEffect(() => {
    if (searchConArbitrators.length > 0) {
      if (arbitratorSearchTerm) {
        const filteredData = searchConArbitrators.filter((item: ODRConArbitrator) => {
          return item.userName.toLowerCase().includes(arbitratorSearchTerm.toLowerCase()) || item.panNumber.toLowerCase().includes(arbitratorSearchTerm.toLowerCase())
        }
        );
        setFilteredArbitrators(filteredData);
      } else {
        setFilteredArbitrators([])
      }
    }
  }, [searchConArbitrators])

  const showConciliation = status !== 'AWAITING_MII_REVIEW' && status !== 'DISPUTE_RESOLVED_-_PRECONCILIATION' && status !== 'DISPUTE_RETURNED';

  const showArbitration = status === 'ARBITRATION_REQUESTED' || status === 'ARBITRATION_INITIATED' || status === 'ARBITRATION_IN_PROGRESS' || status === 'DISPUTE_RESOLVED_-_ARBITRATION' || status === 'DISPUTE_WITHDRAWN';

  const visibleTabs = 1 + (showConciliation ? 1 : 0) + (showArbitration ? 1 : 0);

  const tabWidth = `calc(${100 / visibleTabs}% - 4px)`;

  const getTranslateX = () => {
    if (selectedProcess === 'Pre-Conciliation') return '2px';
    if (selectedProcess === 'Conciliation') return 'calc(100% + 2px)';
    return 'calc(200% + 2px)';
  };

  return (
    <div className="hidden sm:flex flex-col gap-4 border-l border-border-grey">
      <div className='pl-8 pb-4'>
        <div className="w-full border rounded-lg p-1 bg-gray-50">
          <div className="flex relative">
            <div
              className="absolute h-10 transition-all duration-300 rounded-md bg-white shadow-sm"
              style={{
                width: tabWidth,
                transform: `translateX(${getTranslateX()})`,
                display: visibleTabs > 0 ? 'block' : 'none'
              }}
            />

            {/* Pre-Conciliation Tab - Always shown */}
            <button
              onClick={() => setSelectedProcess('Pre-Conciliation')}
              className={`flex-1 relative border-none bg-transparent flex items-center justify-center h-10 rounded-md transition-colors duration-200 z-10 font-medium text-sm ${selectedProcess === 'Pre-Conciliation' ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'
                }`}
            >
              <span className="hover:scale-105 active:scale-95 transition-transform">
                {t('Pre-Conciliation')}
              </span>
            </button>

            {/* Conciliation Tab - Conditionally shown */}
            {showConciliation && (
              <button
                onClick={() => setSelectedProcess('Conciliation')}
                className={`flex-1 relative border-none bg-transparent flex items-center justify-center h-10 rounded-md transition-colors duration-200 z-10 font-medium text-sm ${selectedProcess === 'Conciliation' ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'
                  }`}
              >
                <span className="hover:scale-105 active:scale-95 transition-transform">
                  {t('Conciliation')}
                </span>
              </button>
            )}

            {/* Arbitration Tab - Conditionally shown */}
            {showArbitration && (
              <button
                onClick={() => setSelectedProcess('Arbitration')}
                className={`flex-1 relative border-none bg-transparent flex items-center justify-center h-10 rounded-md transition-colors duration-200 z-10 font-medium text-sm ${selectedProcess === 'Arbitration' ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'
                  }`}
              >
                <span className="hover:scale-105 active:scale-95 transition-transform">
                  {t('Arbitration')}
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
      {selectedProcess === 'Pre-Conciliation' && (
        <PreConciliationReport
          disputeId={disputeId}
          claimAmount={claimAmount}
          status={status}
          isEditDisabled={true}
          outcome={outcome}
        />
      )}
      {selectedProcess === 'Conciliation' && (
        <ConciliationReportComponent
          disputeId={disputeId}
          claimAmount={claimAmount}
          status={status}
          miiId={miiId}
          isEditDisabled={true}
          odrAssigned={odrAssigned}
          conciliationExtension={conciliationExtension} />
      )}
      {selectedProcess === 'Arbitration' && (
        <>
          {/* Segment  */}
          {false &&
            <div className="flex flex-col gap-4 pl-8 pb-4 border-b border-border-grey">
              <div className="flex items-center justify-between">
                <p className="sm:text-sm text-highlight-blue text-xs font-semibold">{t('Segment')}</p>
                <div className='flex gap-2'>
                  {(currentURL === 'mii' || currentURL === 'odr') && status !== 'AWAITING_MII_REVIEW' &&
                    <button className="text-xs text-label-grey pl-3 pr-2" onClick={(e: any) => setIsSegmentDetailsEdit(true)}>{t('Edit')}</button>
                  }
                  {isSegmentDetailsEdit &&
                    <button onClick={(e) => saveOutComeDetails('Segment', e)} className="text-xs text-label-blue pl-3 pr-2">{t('Save')}</button>
                  }
                </div>
              </div>
              {!isSegmentDetailsEdit && arbitrationReport?.segment &&
                <span className="bg-white w-fit text-reports-grey rounded-lg text-xs font-semibold sm:text-sm px-4 py-2">{arbitrationReport?.segment}</span>
              }
              {isSegmentDetailsEdit &&
                <div className="flex sm:flex-row items-center flex-col gap-2 sm:justify-between w-full">
                  <select onChange={captureInputChange} value={arbitrationReport?.segment}
                    aria-label={'Segment'} required name='segment' id='segment'
                    className="text-xs py-3 pl-3 pr-7 border border-border-grey sm:w-2/3 bg-white rounded-lg"
                  >
                    <option>{t('SelectSegment')}</option>
                    <option value={'1'}>1</option>
                    <option value={'2'}>2</option>
                  </select>
                </div>
              }
            </div>
          }
          {/* Process Details  */}
          <form onSubmit={(e) => saveOutComeDetails('Process Details', e)}>
            <div className="flex flex-col gap-4 pl-8 pb-4 border-b border-border-grey">
              <div className="flex items-center justify-between">
                <p className="sm:text-sm text-xs font-semibold text-highlight-blue">{t('ProcessDetails')}</p>
                <div className='flex gap-2'>
                  {(currentURL === 'mii' || currentURL === 'odr' || currentURL === 'investor') && status !== 'AWAITING_MII_REVIEW' &&
                    <button type='button' className="text-xs text-label-grey pl-3 pr-2" onClick={(e: any) => setIsProcessdetailsEdit(true)}>{t('Edit')}</button>
                  }
                  {isProcessdetailsEdit &&
                    <button type='submit' className="text-xs text-label-blue pl-3 pr-2">{t('Save')}</button>
                  }
                </div>
              </div>
              <>
                <div>
                  {!isProcessdetailsEdit &&
                    <div className='flex flex-col gap-4'>
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('ClaimAmount')}</p>
                        <p className='text-highlight-blue w-1/2'>₹{claimAmount}</p>
                      </div>
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('ArbitrationInitiatedBy')}</p>
                        <p className='text-highlight-blue w-1/2'>{arbitrationReport?.arbitrationInitiatedBy}</p>
                      </div>
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('DateOfRequestArbitration')}</p>
                        <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateOfRequestArbitration)}</p>
                      </div>
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('InvoiceDate')}</p>
                        <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.invoiceDate)}</p>
                      </div>
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('FeePaidByApplicant')}</p>
                        <p className='text-highlight-blue w-1/2'>₹{arbitrationReport?.feePaidByApplicant}</p>
                      </div>
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('DateOfFeePaidByApplicant')}</p>
                        <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.applicantFeesDate)}</p>
                      </div>
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('RespondentInvoiceDate')}</p>
                        <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.respondentInvoiceDate)}</p>
                      </div>
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('FeePaidRespondent')}</p>
                        <p className='text-highlight-blue w-1/2'>₹{arbitrationReport?.feePaidByRespondent}</p>
                      </div>
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('DateFeePaidRespondent')}</p>
                        <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.respondentFeesDate)}</p>
                      </div>
                      <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('FeesPaidToODR')}</p>
                        <p className='text-highlight-blue w-1/2'>₹{arbitrationReport?.feesPaidToODR}</p>
                      </div>
                      <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('LateFees')}</p>
                        <p className='text-highlight-blue w-1/2'>₹{arbitrationReport?.lateFees}</p>
                      </div>
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('ExtensionProvided')}</p>
                        <p className='text-highlight-blue w-1/2'>{arbitrationExtension?.timelineExtendedAt ? 'Yes' : 'No'}</p>
                      </div>
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('QuantumOfExtension')}</p>
                        <p className='text-highlight-blue w-1/2'>{arbitrationExtension?.timelineExtendedAt ? '30' : 'NA'}</p>
                      </div>
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('DateOfReceiptInterim')}</p>
                        <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateOfInterimRelief)}</p>
                      </div>
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('DateOfReceiptUndertaking')}</p>
                        <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateOfUndertaking)}</p>
                      </div>
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('AmountReleased')}</p>
                        <p className='text-highlight-blue w-1/2'>₹{arbitrationReport?.amountReleased}</p>
                      </div>
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('DateOfRelease')}</p>
                        <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.relaseAmountDate)}</p>
                      </div>
                      <div className='flex items-center gap-8 sm:text-sm pb-4 w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('SolePanel')}</p>
                        <p className='text-highlight-blue w-1/2'>{arbitrationReport?.arbitratorType}</p>
                      </div>
                  </div>
                  }
                </div>
                <div className="flex flex-col w-full h-fit gap-14 overflow-hidden">
                  <div className="flex flex-col gap-4">
                    {isProcessdetailsEdit &&
                      <div className='flex flex-col gap-4'>
                        <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('ClaimAmount')}</p>
                          <input type="text" readOnly value={claimAmount} name="claimAmount" id="claimAmount" disabled
                            className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                        </div>
                        <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('ArbitrationInitiatedBy')}</p>
                          <input type="text" readOnly value={arbitralAwardDetails?.arbitrationInitiatedBy} name="arbitrationInitiatedBy" id="arbitrationInitiatedBy"
                            className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                        </div>
                        <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('DateOfRequestArbitration')}</p>
                          <input type="date" readOnly value={arbitralAwardDetails?.dateOfRequestArbitration} name="dateOfRequestArbitration" id="dateOfRequestArbitration"
                            className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                        </div>
                        {/* <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                        <p className="text-sm text-reports-light-grey w-1/2">{t('CounterClaimAmount')}</p>
                        <input type="number" step={0.001} min={0} onChange={captureInputChange} value={arbitrationReport?.counterClaimAmount} name="counterClaimAmount" id="counterClaimAmount"
                          className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                      </div> */}
                        <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('InvoiceDate')}</p>
                          <input type="date" onChange={captureInputChange} value={arbitrationReport?.invoiceDate} name="invoiceDate" id="invoiceDate"
                            className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                        </div>
                        <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('FeePaidByApplicant')}</p>
                          <input type="number" step={0.01} min={0} onChange={captureInputChange} value={arbitrationReport?.feePaidByApplicant} name="feePaidByApplicant" id="feePaidByApplicant"
                            className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                        </div>
                        <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('DateOfFeePaidByApplicant')}</p>
                          <input type="date" onChange={captureInputChange} value={arbitrationReport?.applicantFeesDate} name="applicantFeesDate" id="applicantFeesDate"
                            className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                        </div>
                        <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('RespondentInvoiceDate')}</p>
                          <input type="date" onChange={captureInputChange} value={arbitrationReport?.respondentInvoiceDate} name="respondentInvoiceDate" id="respondentInvoiceDate"
                            className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                        </div>
                        <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('FeePaidRespondent')}</p>
                          <input type="number" step={0.01} min={0} onChange={captureInputChange} value={arbitrationReport?.feePaidByRespondent} name="feePaidByRespondent" id="feePaidByRespondent"
                            className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                        </div>
                        <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('DateFeePaidRespondent')}</p>
                          <input type="date" onChange={captureInputChange} value={arbitrationReport?.respondentFeesDate} name="respondentFeesDate" id="respondentFeesDate"
                            className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                        </div>
                        <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('FeesPaidToODR')}</p>
                          <input type="number" min={0} step="0.001" onChange={captureInputChange} value={arbitrationReport?.feesPaidToODR} name="feesPaidToODR" id="feesPaidToODR"
                            className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                        </div>
                        <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('LateFees')}</p>
                          <input type="number" min={0} step="0.001" onChange={captureInputChange} value={arbitrationReport?.lateFees} name="lateFees" id="lateFees"
                            className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                        </div>
                        {currentURL === 'mii' &&
                          <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                            <p className="text-sm text-reports-light-grey w-1/2">{t('DateOfReceiptInterim')}</p>
                            <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateOfInterimRelief} name="dateOfInterimRelief" id="dateOfInterimRelief"
                              className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                          </div>
                        }
                        {(currentURL === 'mii' || currentURL === 'investor') &&
                          <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                            <p className="text-sm text-reports-light-grey w-1/2">{t('DateOfReceiptUndertaking')}</p>
                            <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateOfUndertaking} name="dateOfUndertaking" id="dateOfUndertaking"
                              className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                          </div>
                        }
                        {currentURL === 'mii' &&
                          <Fragment>
                            <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                              <p className="text-sm text-reports-light-grey w-1/2">{t('AmountReleased')}</p>
                              <input type="number" min={0} step="0.001" onChange={captureInputChange} value={arbitrationReport?.amountReleased} name="amountReleased" id="amountReleased"
                                className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                            </div>
                            <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                              <p className="text-sm text-reports-light-grey w-1/2">{t('DateOfRelease')}</p>
                              <input type="date" onChange={captureInputChange} value={arbitrationReport?.relaseAmountDate} name="relaseAmountDate" id="relaseAmountDate"
                                className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                            </div>
                          </Fragment>
                        }

                        {/* <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                        <p className="text-sm text-reports-light-grey w-1/2">{t('AdmissibleClaimDepositPaidMP')}</p>
                        <select onChange={captureInputChange} value={arbitrationReport?.admissibleClaimDepositPaidMP}
                          aria-label={'is Fee Paid By Investor'} required name='admissibleClaimDepositPaidMP' id='admissibleClaimDepositPaidMP'
                          className="text-xs py-3 pl-3 pr-7 border border-border-grey w-1/2 bg-white rounded-lg"
                        >
                          <option disabled selected>Select</option>
                          <option value={'true'}>{t('Yes')}</option>
                          <option value={'false'}>{t('No')}</option>
                        </select>
                      </div> */}
                        {/* <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                        <p className="text-sm text-reports-light-grey w-1/2">{t('DateOfAdmissibleClaimDepositMP')}</p>
                        <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateOfAdmissibleClaimDepositMP} name="dateOfAdmissibleClaimDepositMP" id="dateOfAdmissibleClaimDepositMP"
                          className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                      </div> */}
                        <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('SolePanel')}</p>
                          <select onChange={captureInputChange} value={arbitrationReport?.arbitratorType}
                            aria-label={t('SolePanel')} required name='arbitratorType' id='arbitratorType'
                            className="text-xs py-3 pl-3 pr-7 border border-border-grey sm:w-2/3 bg-white rounded-lg"
                          >
                            <option disabled selected>Select</option>
                            <option value={'Sole'}>{t('Sole')}</option>
                            <option value={'Panel'}>{t('Panel')}</option>
                          </select>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </>

            </div>
          </form>
          {/* Arbitrator details  */}
          {arbitrationReport?.arbitratorType && (
            <div className='pl-8 border-b border-border-grey pb-4'>
              {arbitrationReport?.arbitratorType === 'Sole' &&
                <>
                  {arbitratorsDetails?.map((arbitrator: DisputeArbitrator) => {
                    return <div className='flex flex-col gap-4 pb-2'>
                      {!arbitrator?.isReappointed && <>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('ArbitratorName')}:</p>
                          <p className='text-highlight-blue w-1/2'>{arbitrator?.arbitratorName}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('ArbitratorAppointmentApproval')}:</p>
                          <p className='text-highlight-blue w-1/2'>{(arbitrator?.isApproved !== null && arbitrator?.isApproved !== undefined) ? (arbitrator?.isApproved ? 'Yes' : 'No') : ''}</p>
                        </div>
                        {arbitrator?.arbitratorAppointedDate &&
                          <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                            <p className="text-reports-light-grey w-1/2">{t('ArbitratorAppointmentDate')}:</p>
                            <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrator?.arbitratorAppointedDate)}</p>
                          </div>
                        }
                        {arbitrator?.arbitratorRejectionDate &&
                          <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                            <p className="text-reports-light-grey w-1/2">{t('ArbitratorRejectedDate')}:</p>
                            <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrator?.arbitratorRejectionDate)}</p>
                          </div>
                        }
                        {arbitrator?.arbitratorAcceptanceDate &&
                          <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                            <p className="text-reports-light-grey w-1/2">{t('ArbitratorAcceptanceDate')}:</p>
                            <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrator?.arbitratorAcceptanceDate)}</p>
                          </div>
                        }
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('FeesPaidArbitrator', {
                            arbIndex: 1
                          })}:</p>
                          <p className='text-highlight-blue w-1/2'>₹{arbitrator?.feesPaid}</p>
                        </div>
                      </>
                      }
                      {/* {arbitrator?.Replacements?.map((replacement: DisputeArbitrator, index: number) => {
                      return <>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('ArbitratorReappointed')}:</p>
                          <p className='text-highlight-blue w-1/2'>Yes</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('ArbitratorReappointedName')} {index + 1}:</p>
                          <p className='text-highlight-blue w-1/2'>{replacement?.arbitratorName}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('ArbitratorAcceptanceDate')} {index + 1}:</p>
                          <p className='text-highlight-blue w-1/2'>{replacement?.arbitratorAcceptanceDate}</p>
                        </div>
                      </>
                    })} */}

                    </div>
                  })}
                  {(currentURL === 'odr') &&
                    <p className="text-sm text-document-blue hover:underline cursor-pointer" onClick={() => { setArbitratorAppointmentModal(true) }}>{t('EditArbitratorDetails')}</p>
                  }
                </>
              }
              {arbitrationReport?.arbitratorType === 'Panel' && (currentURL === 'odr') &&
                <div>
                  <PanelOfArbitrators arbitrators={arbitratorsDetails}></PanelOfArbitrators>
                  <p className="text-sm pl-2 text-document-blue hover:underline cursor-pointer" onClick={() => { setArbitratorAppointmentModal(true) }}>{t('EditArbitratorDetails')}</p>
                </div>
              }

            </div>
          )}
          {/* Outcome details  */}
          <form onSubmit={(e) => saveOutComeDetails('Outcome Details', e)}>
            <div className="flex flex-col gap-4 pl-8 pb-4 border-b border-border-grey">
              <div className="flex items-center justify-between">
                <p className="sm:text-sm text-xs font-semibold text-highlight-blue">{t('OutcomeDetails')}</p>
                <div className='flex gap-2'>
                  {(currentURL === 'mii' || currentURL === 'odr') && status !== 'AWAITING_MII_REVIEW' &&
                    <button type='button' className="text-xs text-label-grey pl-3 pr-2" onClick={(e: any) => setIsOutcomedetailsEdit(true)}>{t('Edit')}</button>
                  }
                  {isOutcomedetailsEdit &&
                    <button type='submit' className="text-xs text-label-blue pl-3 pr-2">{t('Save')}</button>
                  }
                </div>
              </div>
              <>
                <div className="grid grid-cols-2 xl:grid-cols-3 gap-4">
                  {!isOutcomedetailsEdit && arbitralFiles?.map((file: any) =>
                    <div className="border flex flex-col gap-3 rounded-lg p-4 bg-white">
                      <h3 className="text-sm text-highlight-blue font-semibold">{file?.documentType}</h3>
                      <p className="text-xs text-low-grey mt-2">
                        {file?.summary?.length > 60 ? `${file.summary.slice(0, 60)}...` : file.summary}
                      </p>
                      <hr />
                      <div onClick={(e) => downloadFile(file?.file)} className='flex gap-2 items-center'>
                        <button className="text-xs text-low-grey w-fit hover:underline">{t('Download')}</button>
                        <i className='ph ph-file-arrow-down text-low-grey'></i>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                    <p className="text-reports-light-grey w-1/2">{t('ResolutionTime')}:</p>
                    <p className='text-highlight-blue w-1/2'>{arbResolutionTime} Days</p>
                  </div>

                  {!isOutcomedetailsEdit &&
                    <>
                      <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('NumberofHearings')}</p>
                        <p className='text-highlight-blue w-1/2'>{arbitrationReport?.noOfHearings}</p>
                      </div>
                      <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('ArbitrationOutcome')}</p>
                        <p className='text-highlight-blue w-1/2'>{arbitrationReport?.arbOutcome}</p>
                      </div>

                      {status === 'DISPUTE_WITHDRAWN' &&
                        <Fragment>
                          <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                            <p className="text-reports-light-grey w-1/2">{t('DateOfArbitrationWithdrwal')}</p>
                            <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateOfArbitrationWithdrawal)}</p>
                          </div>
                          <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                            <p className="text-reports-light-grey w-1/2">{t('DisputeWithdrawnBy')}</p>
                            <p className='text-highlight-blue w-1/2'>{arbitrationReport?.disputeWithdrawnBy}</p>
                          </div>
                        </Fragment>
                      }
                      <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('DocumentOnlyArbitration')}:</p>
                        <p className='text-highlight-blue w-1/2'>{(arbitrationReport?.documentOnlyArbitration !== null && arbitrationReport?.documentOnlyArbitration !== undefined) ? (arbitrationReport?.documentOnlyArbitration ? 'Yes' : 'No') : ''}</p>
                      </div>
                      <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('WasArbitralAwardExParte')}:</p>
                        <p className='text-highlight-blue w-1/2'>{(arbitrationReport?.wasArbitralAwardExParte !== null && arbitrationReport?.wasArbitralAwardExParte !== undefined) ? (arbitrationReport?.wasArbitralAwardExParte ? 'Yes' : 'No') : ''}</p>
                      </div>
                      <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('DateGivingNoticeNonCooperativeParty')}</p>
                        <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateGivingNoticeNonCooperativeParty)}</p>
                      </div>
                      {/* <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('AwardReceivedDate')}</p>
                        <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.receiptOfArbitralAwardDate)}</p>
                      </div>
                      <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('AwardinFavorof')}</p>
                        <p className='text-highlight-blue w-1/2'>{arbitrationReport?.awardInFavor}</p>
                      </div>
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('AwardSentDate')}</p>
                        <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.arbitralAwardSentDate)}</p>
                      </div> */}
                      <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('AwardDate')}</p>
                        <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.arbitralAwardDate)}</p>
                      </div>
                      <div className='flex items-center gap-8 pt-4 pb-4 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('ArbitralAwardAmount')}</p>
                        <p className='text-highlight-blue w-1/2'>₹{arbitrationReport?.arbitralAwardAmount}</p>
                      </div>
                    </>
                  }
                </div>
                <div className="flex flex-col w-full h-fit gap-14 overflow-hidden">
                  <div className="flex flex-col gap-4">
                    {isOutcomedetailsEdit &&
                      <>
                        <div className="grid grid-cols-2 xl:grid-cols-3 gap-4">
                          {arbitralFiles?.map((file: any) =>
                            <div className="border flex flex-col gap-3 rounded-lg p-4 bg-white">
                              <h3 className="text-sm text-highlight-blue font-semibold">{file?.documentType}</h3>
                              <p className="text-xs text-low-grey mt-2">
                                {file?.summary?.length > 60 ? `${file.summary.slice(0, 60)}...` : file.summary}
                              </p>
                              <hr />
                              <div onClick={(e) => downloadFile(file?.file)} className='flex gap-2 items-center'>
                                <button className="text-xs text-low-grey w-fit hover:underline">{t('Download')}</button>
                                <i className='ph ph-file-arrow-down text-low-grey'></i>
                              </div>
                            </div>
                          )}
                          <label htmlFor='upload-award' className="flex flex-col gap-1">
                            <Tooltip
                              html={
                                <div className=" text-left flex flex-col gap-1">
                                  <p className="">{t('AddDocuments')}</p>
                                </div>
                              }
                              position="top"
                              arrow={false}
                              animation="shift"
                              size="small"
                              {...({
                                children: <i className="text-base text-highlight-blue cursor-pointer hover:text-highlight-blue/50 border border-highlight-blue h-8 w-8 items-center flex justify-center ph-bold ph-plus"
                                  onClick={(e) => {
                                    e.preventDefault(); // Stop the form from submitting
                                    setAwardUploadModal(true);
                                  }} style={{ borderRadius: '50%' }}></i>
                              } as any)}
                            >
                              {/* <i className="text-base text-highlight-blue cursor-pointer hover:text-highlight-blue/50 border border-highlight-blue w-fit px-2 py-1 items-center flex justify-center ph-bold ph-plus" style={{ borderRadius: '50%' }}></i> */}
                              {/* <button onClick={() => setAwardUploadModal(true)} id="upload-award" style={{ display: 'none' }} /> */}
                            </Tooltip>

                          </label>
                        </div>
                        <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('NumberofHearings')}</p>
                          <input type="number" min={0} step={1} onChange={captureInputChange} value={arbitrationReport?.noOfHearings} name="noOfHearings" id="noOfHearings"
                            className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                        </div>
                        {status === 'DISPUTE_WITHDRAWN' &&
                          <Fragment>
                            <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                              <p className="text-sm text-reports-light-grey w-1/2">{t('DateOfArbitrationWithdrwal')}</p>
                              <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateOfArbitrationWithdrawal} name="dateOfArbitrationWithdrawal" id="dateOfArbitrationWithdrawal"
                                className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                            </div>
                            <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                              <p className="text-sm text-reports-light-grey w-1/2">{t('DisputeWithdrawnBy')}</p>
                              <select onChange={captureInputChange} value={arbitrationReport?.disputeWithdrawnBy}
                                aria-label={'DisputeWithdrawnBy'} required name='disputeWithdrawnBy' id='disputeWithdrawnBy'
                                className="text-xs py-3 pl-3 pr-7 border border-border-grey sm:w-2/3 bg-white rounded-lg"
                              >
                                <option selected disabled>{t('Select')}</option>
                                <option value={'Market Participant'}>{t('MarketParticipant')}</option>
                                <option value={'Investor'}>{t('Investor')}</option>
                              </select>
                            </div>
                          </Fragment>
                        }
                        <div className="flex sm:flex-row flex-col gap-2 justify-between w-full items-center">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('SelectArbOutcome')}:</p>
                          <select
                            aria-label={t('SelectArbOutcome')} required name='arbOutcome' id='arbOutcome'
                            className="text-xs py-3 pl-3 pr-7 border border-border-grey sm:w-2/3 bg-white rounded-lg"
                            value={arbitrationReport?.arbOutcome}
                            onChange={captureInputChange}
                          >
                            <option disabled selected value={""}>Select</option>
                            <option value={'In Favor of Market Participant'}>{t('InFavorofMarketParticipant')}</option>
                            <option value={'In Favor of Investor'}>{t('InFavorofInvestor')}</option>
                            <option value={'Matter Dismissed'}>{t('MatterDismissed')}</option>
                          </select>
                        </div>
                        <div className="flex sm:flex-row flex-col gap-2 items-center justify-between w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('DocumentOnlyArbitration')}</p>
                          <select
                            aria-label={'Settlement Agreement Executed'} required name='documentOnlyArbitration' id='documentOnlyArbitration'
                            className="text-xs py-3 pl-3 pr-7 border border-border-grey sm:w-2/3 bg-white rounded-lg"
                            onChange={captureInputChange} value={arbitrationReport?.documentOnlyArbitration}
                          >
                            <option disabled selected value={""}>Select</option>
                            <option value={'true'}>{t('Yes')}</option>
                            <option value={'false'}>{t('No')}</option>
                          </select>
                        </div>
                        <div className="flex sm:flex-row flex-col gap-2 items-center justify-between w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('WasArbitralAwardExParte')}</p>
                          <select
                            aria-label={'Settlement Agreement Executed'} required name='wasArbitralAwardExParte' id='wasArbitralAwardExParte'
                            className="text-xs py-3 pl-3 pr-7 border border-border-grey sm:w-2/3 bg-white rounded-lg"
                            onChange={captureInputChange} value={arbitrationReport?.wasArbitralAwardExParte}
                          >
                            <option disabled selected>Select</option>
                            <option value={'true'}>{t('Yes')}</option>
                            <option value={'false'}>{t('No')}</option>
                          </select>
                        </div>
                        {(arbitrationReport?.wasArbitralAwardExParte === "true" || arbitrationReport?.wasArbitralAwardExParte === true || arbitrationReport?.wasArbitralAwardExParte === "Yes") &&
                          <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                            <p className="text-sm text-reports-light-grey w-1/2">{t('DateGivingNoticeNonCooperativeParty')}</p>
                            <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateGivingNoticeNonCooperativeParty} name="dateGivingNoticeNonCooperativeParty" id="dateGivingNoticeNonCooperativeParty" className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                          </div>
                        }
                        {/* <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('AwardReceivedDate')}</p>
                          <input type="date" onChange={captureInputChange} value={arbitrationReport?.receiptOfArbitralAwardDate} name="receiptOfArbitralAwardDate" id="receiptOfArbitralAwardDate" className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                        </div>
                        <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('AwardinFavorof')}</p>
                          <select onChange={captureInputChange} value={arbitrationReport?.awardInFavor}
                            aria-label={'AwardinFavorof'} required name='awardInFavor' id='awardInFavor'
                            className="text-xs py-3 pl-3 pr-7 border border-border-grey w-1/2 bg-white rounded-lg"
                          >
                            <option>{t('Select')}</option>
                            <option value={'Market Participant'}>{t('MarketParticipant')}</option>
                            <option value={'Investor'}>{t('Investor')}</option>
                          </select>
                        </div>
                        <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('AwardSentDate')}</p>
                          <input type="date" onChange={captureInputChange} value={arbitrationReport?.arbitralAwardSentDate} name="arbitralAwardSentDate" id="arbitralAwardSentDate" className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                        </div> */}
                        <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('AwardDate')}</p>
                          <input type="date" onChange={captureInputChange} value={arbitrationReport?.arbitralAwardDate} name="arbitralAwardDate" id="arbitralAwardDate" className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                        </div>
                        <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('ArbitralAwardAmount')}</p>
                          <input type="number" min={0} step="0.001" onChange={captureInputChange} value={arbitrationReport?.arbitralAwardAmount} name="arbitralAwardAmount" id="arbitralAwardAmount" className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                        </div>
                      </>
                    }
                  </div>
                </div>
              </>
            </div>
          </form>

          {/* Compliance Status  */}
          {(status === 'DISPUTE_RESOLVED_-_ARBITRATION' || status === 'DISPUTE_WITHDRAWN') &&
            <form onSubmit={(e) => saveOutComeDetails('Compliance Status', e)}>
              <div className="flex flex-col gap-4 pb-4 pl-8 border-b border-border-grey">
                <div className="flex items-center justify-between">
                  <p className="sm:text-sm text-xs font-semibold text-highlight-blue">{t('ComplianceStatus')}</p>
                  <div className='flex gap-2'>
                    {(currentURL === 'intermediary' || currentURL === 'mii') &&
                      <button type='button' className="text-xs text-label-grey pl-3 pr-2" onClick={(e: any) => setIsCompliancedetailsEdit(true)}>{t('Edit')}</button>
                    }
                    {isCompliancedetailsEdit &&
                      <button type='submit' className="text-xs text-label-blue pl-3 pr-2">{t('Save')}</button>
                    }
                  </div>
                </div>
                <>
                  {!isCompliancedetailsEdit &&
                    <>
                      {arbitrationReport?.complianceStatus &&
                        <span className="bg-white w-fit border border-reports-border text-reports-grey rounded-lg text-xs sm:text-sm px-4 py-2">{arbitrationReport?.complianceStatus}</span>
                      }
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('DateTransferOfMonetaryAwardClient')}</p>
                        <p className='text-highlight-blue w-1/2'>{arbitrationReport?.dateTransferOfMonetaryAwardClient}</p>
                      </div>
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('AmountTransferredToClient')}</p>
                        <p className='text-highlight-blue w-1/2'>₹{arbitrationReport?.amountTransferredToClient}</p>
                      </div>
                      {/* {arbitrationReport?.nonImplementationReason &&
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('ReasonForNonImplementation')}:</p>
                        <p className='text-highlight-blue w-1/2'>{arbitrationReport?.nonImplementationReason}</p>
                      </div>
                    } */}
                      {/* <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                      <p className="text-reports-light-grey w-1/2">{t('34IntentionReceived')}:</p>
                      <p className='text-highlight-blue w-1/2'>{(arbitrationReport?.isIntentionReceived !== null && arbitrationReport?.isIntentionReceived !== undefined) ? (arbitrationReport?.isIntentionReceived ? 'Yes' : 'No') : ''}</p>
                    </div> */}
                      {/* {arbitrationReport?.intentionReceivedDate &&
                      <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                        <p className="text-reports-light-grey w-1/2">{t('DateofIntentionReceived')}:</p>
                        <p className='text-highlight-blue w-1/2'>{arbitrationReport?.intentionReceivedDate}</p>
                      </div>
                    } */}
                    </>
                  }
                  {isCompliancedetailsEdit &&
                    <div className="flex flex-col w-full h-fit gap-14 overflow-hidden">
                      <div className="flex flex-col gap-4">
                        {currentURL === 'odr' &&
                          <div className="flex sm:flex-row flex-col gap-2 justify-between w-full items-center">
                            <p className="text-sm text-reports-light-grey w-1/2">{t('Compliedby')}:</p>
                            <select
                              aria-label={t('Compliedby')} required name='complianceStatus' id='complianceStatus'
                              className="sm:w-2/3 text-xs py-3 pl-3 pr-7 border border-border-grey bg-white rounded-lg"
                              value={arbitrationReport?.complianceStatus}
                              onChange={captureInputChange}
                            >
                              <option disabled selected>Select</option>
                              <option value={'Complied by Market Participant'}>{t('CompliedbyMarketParticipant')}</option>
                              <option value={'Complied by Investor'}>{t('CompliedbyInvestor')}</option>
                              <option value={'Not Complied by Market Participant'}>{t('NotCompliedByMarketParticipant')}</option>
                              <option value={'Not Complied by Investor'}>{t('NotCompliedByInvestor')}</option>
                            </select>
                          </div>
                        }
                        {currentURL === 'intermediary' &&
                          <Fragment>
                            <div className="flex sm:flex-row flex-col gap-2 items-center justify-between w-full">
                              <p className="text-sm text-reports-light-grey">{t('DateTransferOfMonetaryAwardClient')}:</p>
                              <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateTransferOfMonetaryAwardClient} name="dateTransferOfMonetaryAwardClient" id="dateTransferOfMonetaryAwardClient"
                                className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                            </div>
                            <div className="flex sm:flex-row flex-col gap-2 items-center justify-between w-full">
                              <p className="text-sm text-reports-light-grey">{t('AmountTransferredToClient')}:</p>
                              <input type="number" step={0.01} min={0} onChange={captureInputChange} value={arbitrationReport?.amountTransferredToClient} name="amountTransferredToClient" id="amountTransferredToClient"
                                className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                            </div>
                          </Fragment>
                        }
                        {/* {(arbitrationReport?.complianceStatus === 'Not Complied by Market Participant' || arbitrationReport?.complianceStatus === 'Not Complied by Investor') &&
                        <div className="flex sm:flex-row flex-col gap-2 items-center justify-between w-full">
                          <p className="text-sm text-reports-light-grey">{t('ReasonForNonImplementation')}:</p>
                          <textarea onChange={captureInputChange} name="nonImplementationReason" id="nonImplementationReason" value={arbitrationReport?.nonImplementationReason}
                            className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                        </div>
                      } */}
                      </div>
                      {/* <div className="flex flex-col w-full h-fit gap-14">
                      <div className="flex flex-col gap-4">
                        <div className="flex sm:flex-row flex-col gap-2 items-center justify-between w-full">
                          <p className="text-sm text-reports-light-grey">{t('34IntentionReceived')}?:</p>
                          <select
                            aria-label={t('34IntentionReceived')} required name='isIntentionReceived' id='isIntentionReceived'
                            className="text-xs py-3 pl-3 pr-7 border border-border-grey sm:w-2/3 bg-white rounded-lg"
                            value={arbitrationReport?.isIntentionReceived}
                            onChange={(event: any) => setArbitrationReport({
                              ...arbitrationReport,
                              isIntentionReceived: event.target.value
                            })}
                          >
                            <option>{t('Select')}</option>
                            <option value={'true'}>{t('Yes')}</option>
                            <option value={'false'}>{t('No')}</option>
                          </select>
                        </div>
                        <div className="flex sm:flex-row flex-col gap-2 items-center justify-between w-full">
                          <p className="text-sm text-reports-light-grey w-1/2">{t('DateofIntentionReceived')}:</p>
                          <input type="date" onChange={captureInputChange} value={arbitrationReport?.intentionReceivedDate} name="intentionReceivedDate" id="intentionReceivedDate"
                            className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                        </div>
                      </div>
                    </div> */}
                    </div>
                  }
                </>

              </div>
            </form>
          }

          {status === 'DISPUTE_RESOLVED_-_ARBITRATION' &&
            <Fragment>
              {/* Post Arbitration Proceedings  */}
              <form onSubmit={(e) => saveOutComeDetails('Post Arbitration Proceedings', e)}>
                <div className="flex flex-col gap-4 pb-4 pl-8 border-b border-border-grey">
                  <div className="flex items-center justify-between">
                    <p className="sm:text-sm text-xs font-semibold text-highlight-blue">{t('PostArbitrationProceedings')}</p>
                    <div className='flex gap-2'>
                      {currentURL !== 'odr' &&
                        <button type='button' className="text-xs text-label-grey pl-3 pr-2" onClick={(e: any) => setIsPostArbEdit(true)}>{t('Edit')}</button>
                      }
                      {isPostArbEdit &&
                        <button type='submit' className="text-xs text-label-blue pl-3 pr-2">{t('Save')}</button>
                      }
                    </div>
                  </div>
                  <>
                    {!isPostArbEdit &&
                      <>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('DateOfFilingIntentCourt')}</p>
                          <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateOfFilingIntentCourt)}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('DateOfReceivingStay')}:</p>
                          <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateOfReceivingStay)}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('DateOfDepositingAmount')}:</p>
                          <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateOfDepositingAmount)}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('DateReceiptApplication')}:</p>
                          <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateReceiptApplication)}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('DateReceiptUndertakingInterimRelief')}:</p>
                          <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateReceiptUndertakingInterimRelief)}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('AmountReleased')}:</p>
                          <p className='text-highlight-blue w-1/2'>₹{arbitrationReport?.nonImplementationReason}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('DateOfReleaseOfAmount')}:</p>
                          <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateOfReleaseOfAmount)}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('StatusFinalOrder')}:</p>
                          <p className='text-highlight-blue w-1/2'>{arbitrationReport?.statusFinalOrder}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('DateFinalOrder')}:</p>
                          <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateFinalOrder)}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('AmountDeterminedReturned')}:</p>
                          <p className='text-highlight-blue w-1/2'>₹{arbitrationReport?.amountDeterminedReturned}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('DateReturnAmountByClient')}:</p>
                          <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateReturnAmountByClient)}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('FinalOrderInFavor')}:</p>
                          <p className='text-highlight-blue w-1/2'>{arbitrationReport?.finalOrderInFavor}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('ConciliationReportArbitralAward')}:</p>
                          <p className='text-highlight-blue w-1/2'>{(arbitrationReport?.conciliationReportArbitralAward !== null && arbitrationReport?.conciliationReportArbitralAward !== undefined) ? (arbitrationReport?.conciliationReportArbitralAward ? 'Yes' : 'No') : ''}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('ArbitralAwardCourtOrder')}:</p>
                          <p className='text-highlight-blue w-1/2'>{(arbitrationReport?.arbitralAwardCourtOrder !== null && arbitrationReport?.arbitralAwardCourtOrder !== undefined) ? (arbitrationReport?.arbitralAwardCourtOrder ? 'Yes' : 'No') : ''}</p>
                        </div>
                      </>
                    }
                    {isPostArbEdit &&
                      <div className="flex flex-col w-full h-fit gap-14 overflow-hidden">
                        <div className="flex flex-col gap-4">
                          {currentURL !== 'odr' &&
                            <Fragment>
                              <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                                <p className="text-sm text-reports-light-grey w-1/2">{t('DateOfFilingIntentCourt')}</p>
                                <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateOfFilingIntentCourt} name="dateOfFilingIntentCourt" id="dateOfFilingIntentCourt"
                                  className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                              </div>
                              <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                                <p className="text-sm text-reports-light-grey w-1/2">{t('DateOfReceivingStay')}</p>
                                <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateOfReceivingStay} name="dateOfReceivingStay" id="dateOfReceivingStay"
                                  className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                              </div>
                              <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                                <p className="text-sm text-reports-light-grey w-1/2">{t('DateOfDepositingAmount')}</p>
                                <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateOfDepositingAmount} name="dateOfDepositingAmount" id="dateOfDepositingAmount"
                                  className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                              </div>
                              <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                                <p className="text-sm text-reports-light-grey w-1/2">{t('DateReceiptApplication')}</p>
                                <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateReceiptApplication} name="dateReceiptApplication" id="dateReceiptApplication"
                                  className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                              </div>
                              <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                                <p className="text-sm text-reports-light-grey w-1/2">{t('DateReceiptUndertakingInterimRelief')}</p>
                                <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateReceiptUndertakingInterimRelief} name="dateReceiptUndertakingInterimRelief" id="dateReceiptUndertakingInterimRelief"
                                  className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                              </div>
                            </Fragment>
                          }
                          {(currentURL === 'mii' || currentURL === 'intermediary') &&
                            <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                              <p className="text-sm text-reports-light-grey w-1/2">{t('AmountReleased')}</p>
                              <input type="number" min={0} step={0.01} onChange={captureInputChange} value={arbitrationReport?.nonImplementationReason} name="nonImplementationReason" id="nonImplementationReason"
                                className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                            </div>
                          }
                          {currentURL !== 'odr' &&
                            <Fragment>
                              <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                                <p className="text-sm text-reports-light-grey w-1/2">{t('DateOfReleaseOfAmount')}</p>
                                <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateOfReleaseOfAmount} name="dateOfReleaseOfAmount" id="dateOfReleaseOfAmount"
                                  className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                              </div>
                            </Fragment>
                          }
                          {currentURL === 'mii' &&
                            <Fragment>
                              <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                                <p className="text-sm text-reports-light-grey w-1/2">{t('StatusFinalOrder')}</p>
                                <input type="text" onChange={captureInputChange} value={arbitrationReport?.statusFinalOrder} name="statusFinalOrder" id="statusFinalOrder"
                                  className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                              </div>
                              <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                                <p className="text-sm text-reports-light-grey w-1/2">{t('DateFinalOrder')}</p>
                                <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateFinalOrder} name="dateFinalOrder" id="dateFinalOrder"
                                  className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                              </div>
                              <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                                <p className="text-sm text-reports-light-grey w-1/2">{t('AmountDeterminedReturned')}</p>
                                <input type="number" step={0.01} min={0} onChange={captureInputChange} value={arbitrationReport?.amountDeterminedReturned} name="amountDeterminedReturned" id="amountDeterminedReturned"
                                  className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                              </div>
                              <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                                <p className="text-sm text-reports-light-grey w-1/2">{t('DateReturnAmountByClient')}</p>
                                <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateReturnAmountByClient} name="dateReturnAmountByClient" id="dateReturnAmountByClient"
                                  className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                              </div>
                              <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                                <p className="text-sm text-reports-light-grey w-1/2">{t('FinalOrderInFavor')}</p>
                                <select onChange={captureInputChange} value={arbitrationReport?.finalOrderInFavor}
                                  aria-label={'FinalOrderInFavor'} required name='finalOrderInFavor' id='finalOrderInFavor'
                                  className="text-xs py-3 pl-3 pr-7 border border-border-grey sm:w-2/3 bg-white rounded-lg"
                                >
                                  <option>{t('Select')}</option>
                                  <option value={'Market Participant'}>{t('MarketParticipant')}</option>
                                  <option value={'Investor'}>{t('Investor')}</option>
                                </select>
                              </div>
                              <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                                <p className="text-sm text-reports-light-grey w-1/2">{t('ConciliationReportArbitralAward')}</p>
                                <select
                                  aria-label={t('ConciliationReportArbitralAward')} required name='conciliationReportArbitralAward' id='conciliationReportArbitralAward'
                                  className="text-xs py-3 pl-3 pr-7 border border-border-grey sm:w-2/3 bg-white rounded-lg"
                                  onChange={captureInputChange} value={arbitrationReport?.conciliationReportArbitralAward}
                                >
                                  <option disabled selected>Select</option>
                                  <option value={'true'}>{t('Yes')}</option>
                                  <option value={'false'}>{t('No')}</option>
                                </select>
                              </div>
                              <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                                <p className="text-sm text-reports-light-grey w-1/2">{t('ArbitralAwardCourtOrder')}</p>
                                <select
                                  aria-label={t('ArbitralAwardCourtOrder')} required name='arbitralAwardCourtOrder' id='arbitralAwardCourtOrder'
                                  className="text-xs py-3 pl-3 pr-7 border border-border-grey sm:w-2/3 bg-white rounded-lg"
                                  onChange={captureInputChange} value={arbitrationReport?.arbitralAwardCourtOrder}
                                >
                                  <option disabled selected>Select</option>
                                  <option value={'true'}>{t('Yes')}</option>
                                  <option value={'false'}>{t('No')}</option>
                                </select>
                              </div>
                            </Fragment>
                          }
                        </div>
                      </div>
                    }
                  </>

                </div>
              </form>
              {/* Details of Action against Client  */}
              <form onSubmit={(e) => saveOutComeDetails('Details of Action against Client', e)}>
                <div className="flex flex-col gap-4 pb-4 pl-8 border-b border-border-grey">
                  <div className="flex items-center justify-between">
                    <p className="sm:text-sm text-xs font-semibold text-highlight-blue">{t('DetailsActionClient')}</p>
                    <div className='flex gap-2'>
                      {currentURL === 'mii' &&
                        <button type='button' className="text-xs text-label-grey pl-3 pr-2" onClick={(e: any) => setIsActionClientEdit(true)}>{t('Edit')}</button>
                      }
                      {isActionClientEdit &&
                        <button type='submit' className="text-xs text-label-blue pl-3 pr-2">{t('Save')}</button>
                      }
                    </div>
                  </div>
                  <>
                    {!isActionClientEdit &&
                      <>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('DateSendingFirstIntimation')}</p>
                          <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateSendingFirstIntimation)}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('DateSendingSecondIntimation')}:</p>
                          <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateSendingSecondIntimation)}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('DateDisablementTradingAccount')}:</p>
                          <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateDisablementTradingAccount)}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('DateFreezingDematAccount')}:</p>
                          <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateFreezingDematAccount)}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('DateRealizationSecurity')}:</p>
                          <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateRealizationSecurity)}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('AmountRealizedSaleSecurity')}:</p>
                          <p className='text-highlight-blue w-1/2'>₹{arbitrationReport?.amountRealizedSaleSecurity}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('DateReturnInvestor')}:</p>
                          <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateReturnInvestor)}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('AmountReturnedClient')}:</p>
                          <p className='text-highlight-blue w-1/2'>₹{arbitrationReport?.amountReturnedClient}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('DateEnablementTradingAccount')}:</p>
                          <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateEnablementTradingAccount)}</p>
                        </div>
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('DateUnfreezingDematAccount')}:</p>
                          <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrationReport?.dateUnfreezingDematAccount)}</p>
                        </div>
                      </>
                    }
                    {isActionClientEdit &&
                      <div className="flex flex-col w-full h-fit gap-14 overflow-hidden">
                        <div className="flex flex-col gap-4">
                          <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                            <p className="text-sm text-reports-light-grey w-1/2">{t('DateSendingFirstIntimation')}</p>
                            <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateSendingFirstIntimation} name="dateSendingFirstIntimation" id="dateSendingFirstIntimation"
                              className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                          </div>
                          <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                            <p className="text-sm text-reports-light-grey w-1/2">{t('DateSendingSecondIntimation')}</p>
                            <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateSendingSecondIntimation} name="dateSendingSecondIntimation" id="dateSendingSecondIntimation"
                              className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                          </div>
                          <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                            <p className="text-sm text-reports-light-grey w-1/2">{t('DateDisablementTradingAccount')}</p>
                            <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateDisablementTradingAccount} name="dateDisablementTradingAccount" id="dateDisablementTradingAccount"
                              className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                          </div>
                          <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                            <p className="text-sm text-reports-light-grey w-1/2">{t('DateFreezingDematAccount')}</p>
                            <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateFreezingDematAccount} name="dateFreezingDematAccount" id="dateFreezingDematAccount"
                              className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                          </div>
                          <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                            <p className="text-sm text-reports-light-grey w-1/2">{t('DateRealizationSecurity')}</p>
                            <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateRealizationSecurity} name="dateRealizationSecurity" id="dateRealizationSecurity"
                              className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                          </div>
                          <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                            <p className="text-sm text-reports-light-grey w-1/2">{t('AmountRealizedSaleSecurity')}</p>
                            <input type="number" min={0} step={0.01} onChange={captureInputChange} value={arbitrationReport?.amountRealizedSaleSecurity} name="amountRealizedSaleSecurity" id="amountRealizedSaleSecurity"
                              className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                          </div>
                          <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                            <p className="text-sm text-reports-light-grey w-1/2">{t('DateReturnInvestor')}</p>
                            <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateReturnInvestor} name="dateReturnInvestor" id="dateReturnInvestor"
                              className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                          </div>
                          <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                            <p className="text-sm text-reports-light-grey w-1/2">{t('AmountReturnedClient')}</p>
                            <input type="number" min={0} step={0.01} onChange={captureInputChange} value={arbitrationReport?.amountReturnedClient} name="amountReturnedClient" id="amountReturnedClient"
                              className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                          </div>
                          <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                            <p className="text-sm text-reports-light-grey w-1/2">{t('DateEnablementTradingAccount')}</p>
                            <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateEnablementTradingAccount} name="dateEnablementTradingAccount" id="dateEnablementTradingAccount"
                              className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                          </div>
                          <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                            <p className="text-sm text-reports-light-grey w-1/2">{t('DateUnfreezingDematAccount')}</p>
                            <input type="date" onChange={captureInputChange} value={arbitrationReport?.dateUnfreezingDematAccount} name="dateUnfreezingDematAccount" id="dateUnfreezingDematAccount"
                              className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg" />
                          </div>
                        </div>
                      </div>
                    }
                  </>

                </div>
              </form>
            </Fragment>
          }


          {/* Officer Details  */}
          <form onSubmit={(e) => saveOutComeDetails('Officer Name', e)}>
            <div className="flex flex-col gap-4 pl-8 pb-4">
              <div className="flex items-center justify-between">
                <p className="sm:text-sm text-highlight-blue text-xs font-semibold">{t('OfficerName')}</p>
                <div className='flex gap-2'>
                  {(currentURL === 'mii') && status !== 'AWAITING_MII_REVIEW' &&
                    <button type='button' className="text-xs text-label-grey pl-3 pr-2" onClick={(e: any) => setIsOfficerDetailsEdit(true)}>{t('Edit')}</button>
                  }
                  {isOfficerDetailsEdit &&
                    <button type='submit' className="text-xs text-label-blue pl-3 pr-2">{t('Save')}</button>
                  }
                </div>
              </div>
              {!isOfficerDetailsEdit && arbitrationReport?.officerName &&
                <span className="bg-white w-fit text-reports-grey rounded-lg text-xs font-semibold sm:text-sm px-4 py-2">{arbitrationReport?.officerName}</span>
              }
              {isOfficerDetailsEdit &&
                <div className="flex sm:flex-row items-center flex-col gap-2 sm:justify-between w-full">
                  <select
                    aria-label={' Select Officer Name'} required
                    name='officerName' id='officerName'
                    className="text-xs py-3 pl-3 pr-7 border border-border-grey sm:w-2/3 bg-white rounded-lg"
                    onChange={selectOfficer}
                  >
                    <option selected disabled>{t('SelectOfficer')}</option>
                    {officers?.map((officer: User) =>
                      <option value={officer?.userId}>{officer?.userName}</option>
                    )}
                  </select>
                </div>
              }
            </div>
          </form>
          {awardUploadModal &&
            (
              <div
                className="fixed z-10 inset-0 flex items-center justify-center "
                id="modal"
              >
                <div className="px-8 sm:px-0 sm:w-1/2">
                  <div className="flex justify-center pt-4 pb-20 w-full h-fit">
                    <div
                      className="fixed inset-0 transition-opacity"
                      onClick={() => setAwardUploadModal(false)}
                    >
                      <div className="absolute inset-0 bg-gray-900 opacity-75" />
                    </div>
                    <div
                      className="inline-block align-center w-full bg-white rounded-lg py-4 text-left shadow-xl transform transition-all "
                      role="dialog"
                      aria-modal="true"
                      aria-labelledby="modal-headline"
                    >
                      <form onSubmit={submitFileDetails}>
                        <div className="flex flex-col gap-4 px-8 py-5">
                          <div className='flex gap-4 items-center'>
                            <i className='ph ph-buildings text-2xl px-2 py-1 border border-border-grey rounded-md'></i>
                            <div className='flex flex-col w-full'>
                              <div className='flex justify-between w-full items-center'>
                                <p className='font-semibold sm:text-lg text-sm'>{t('AddOutcomeDocument')}</p>
                                <i onClick={() => { setAwardUploadModal(false); handleRemoveFile() }} className='ph ph-x hover:text-modal-grey cursor-pointer'></i>
                              </div>
                              <p className='text-xs text-modal-grey'>{t('AttachConArbReport')}</p>
                            </div>
                          </div>
                          <hr />
                          <div className='flex flex-col gap-6 text-label-grey'>
                            <div className="flex sm:flex-row flex-col gap-2 sm:gap-8 justify-between items-baseline">
                              <p className="text-sm font-medium">{t('DocumentType')}*</p>
                              <select
                                aria-label={'Settlement Agreement Executed'} required name='documentType' id='documentType'
                                className="text-xs py-3 pl-3 pr-7 border border-border-grey sm:w-2/3 bg-white rounded-lg"
                                onChange={(e) => {
                                  setAwardFileUploadDetails({
                                    ...awardFileUploadDetails,
                                    documentType: e.target.value
                                  })
                                }} value={awardFileUploadDetails?.documentType}
                              >
                                <option selected>Select</option>
                                <option value={'Arbitration Award'}>{t('ArbitrationAward')}</option>
                                <option value={'Redacted Award'}>{t('RedactedAward')}</option>
                              </select>
                            </div>
                            <hr />
                            <div className="flex sm:flex-row flex-col gap-2 sm:gap-0 justify-between items-baseline">
                              <div className="flex flex-row gap-2 items-center">
                                <label htmlFor="ReferenceNo" className="sm:whitespace-nowrap text-sm font-medium">{t('UploadFile')}*</label>
                              </div>
                              <div className="flex flex-row gap-3 items-center w-2/3 justify-end rounded-lg">
                                <div className="flex gap-2 items-center">
                                  <div className="flex p-2.5 bg-base-gray" style={{ borderRadius: '50%' }}>
                                    <i className="ph text-4xl ph-image-square"></i>
                                  </div>
                                  <label htmlFor="fileInput" className="cursor-pointer">
                                    <div className="w-full 
                                      border
                                      border-dashed 
                                      border-border-secondary py-4
                                      rounded-lg
                                      flex 
                                      flex-col gap-3
                                      items-center px-4
                                      justify-center border-border-secondary">
                                      <div className="flex flex-col gap-1 items-center">
                                        <p className="text-xs sm:whitespace-nowrap"><span className="text-document-blue font-semibold">{t('ClickToUpload')}</span> {t('orDragAndDrop')}</p>
                                        <p className="text-xs sm:whitespace-nowrap">{t('FileFormat')}</p>
                                      </div>
                                    </div>

                                  </label>
                                  <input aria-label={t('Choosefile')} id="fileInput" type="file" style={{ display: 'none' }} accept={".pdf, .docx"}
                                    onChange={handleUploadAwards} /> {/* Call handleFileChange on file change */}
                                </div>

                              </div>
                            </div>
                            {/* Display Uploaded File Names */}
                            {awardFileUploadDetails?.file && (
                              <div className="flex flex-wrap justify-end gap-2">
                                <span className="bg-gray-200 text-xs px-2 py-1 rounded-full">
                                  {awardFileUploadDetails.file.name}
                                  <span onClick={() => handleRemoveFile()} className="ml-2 cursor-pointer text-red-500">×</span>
                                </span>
                              </div>
                            )}
                            <hr />
                            <div className="flex sm:flex-row flex-col gap-2 sm:gap-8 justify-between items-baseline">
                              <label htmlFor="Summary" className="text-sm font-medium">{t('Summary')}*</label>
                              <textarea name="summary" onChange={(e) => {
                                setAwardFileUploadDetails({
                                  ...awardFileUploadDetails,
                                  summary: e.target.value
                                })
                              }} required placeholder={t('Add10Keywords')} id="Summary" className={`border rounded-lg w-3/4 text-sm p-2 border-border-grey`} />
                            </div>
                            <hr />
                          </div>
                          <div className='flex flex-col sm:flex-row w-full gap-4'>
                            <button aria-label={t('cancel')} onClick={() => setAwardUploadModal(false)} className=" hover:bg-base-gray w-1/2 border-border-grey border cursor-pointer hover:text-white font-semibold justify-center text-sm text-modal-grey px-12 py-3 rounded-lg">
                              {t('cancel')}
                            </button>
                            <button type="submit" className="hover:bg-document-blue/[0.4] w-1/2 bg-document-blue justify-center text-white flex flex-row gap-2 text-sm py-3 rounded-lg disabled:bg-document-blue/[0.4] px-12" aria-label={t('Proceed')}
                              onClick={(e) => {
                              }}
                            >
                              <p>{t('AddDocument')}</p>
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

              </div>
            )
          }
          {/* Arbitrator Appointment Modal  */}
          {arbitratorAppointmentModal &&
            (
              <div
                className="fixed z-10 inset-0 flex items-center justify-center "
                id="modal"
              >
                <div className="px-8 sm:px-0 sm:w-2/3">
                  <div className="flex justify-center pt-4 pb-20 w-full h-fit">
                    <div
                      className="fixed inset-0 transition-opacity"
                      onClick={() => setArbitratorAppointmentModal(false)}
                    >
                      <div className="absolute inset-0 bg-gray-900 opacity-75" />
                    </div>
                    <div
                      className="inline-block align-center w-full bg-white rounded-lg py-4 text-left shadow-xl transform transition-all h-full"
                      role="dialog"
                      aria-modal="true"
                      aria-labelledby="modal-headline"
                    >
                      <form onSubmit={(e) => saveConArbDetails(e)}>
                        <div className="flex flex-col gap-4 px-8 py-5 overflow-auto">
                          <div className="flex flex-col gap-2 w-full">
                            <div className="flex justify-between w-full items-center">
                              <p className="font-bold text-highlight-blue sm:text-lg text-sm">
                                {t('ArbitratorAppointmentDetails')}
                              </p>
                              <div className="flex justify-end" >
                                <i className="ph ph-x text-modal-grey hover:text-dash-grey cursor-pointer" onClick={() => setArbitratorAppointmentModal(false)} />
                              </div>
                            </div>
                            <div className='flex justify-between w-full items-center'>
                              <p className="text-xs w-1/3 text-modal-grey">
                                {t('ViewEditArbitrators')}
                              </p>
                              <div className="flex gap-4 items-center">
                                <button
                                  type="button"
                                  onClick={() => { setIsArbitratorEdit(!isArbitratorEdit); cancelForm() }}
                                  className="text-reports-light-grey text-xs hover:underline"
                                >
                                  {isArbitratorEdit ? t('cancel') : t('Edit')}
                                </button>
                                <button
                                  type="submit"
                                  className="text-reports-light-grey text-xs hover:underline"
                                >
                                  {isArbitratorEdit ? t('Save') : ''}
                                </button>
                              </div>
                            </div>
                          </div>
                          {isArbitratorEdit && (
                            <div className="bg-light-blue px-3 py-3 rounded-lg text-xs text-highlight-blue font-medium">
                              <p>Date of Approval / Date of Rejection will be successfully changed only upon saving the data.</p>
                            </div>
                          )}
                          <div className='h-[36rem] overflow-auto'>
                            <table className="table-auto w-full">
                              <thead>
                                <tr>
                                  <th className="p-3 whitespace-nowrap rounded-tl-lg rounded-bl-lg bg-base-gray font-normal text-left text-sm text-label-grey">
                                    {t('ArbitratorName')} {activeTab}
                                  </th>
                                  <th className="p-3 whitespace-nowrap bg-base-gray font-normal text-left text-sm text-label-grey">
                                    {t('DateOfAppointment')}
                                  </th>
                                  <th className="p-3 whitespace-nowrap bg-base-gray font-normal text-left text-sm text-label-grey">
                                    {t('ArbitratorApproved')}
                                  </th>
                                  <th className="p-3 whitespace-nowrap bg-base-gray font-normal text-left text-sm text-label-grey">
                                    {t('ArbitratorReappointed')}?
                                  </th>

                                  <th className="p-3 whitespace-nowrap bg-base-gray font-normal text-left text-sm text-label-grey">
                                    {t('DateOfRejection')}
                                  </th>
                                  <th className="p-3 whitespace-nowrap bg-base-gray font-normal text-left text-sm text-label-grey">
                                    {t('DateOfApproval')}
                                  </th>
                                  <th className="p-3 whitespace-nowrap bg-base-gray font-normal text-left text-sm text-label-grey">
                                    {t('FeesPaidArbitrator', {
                                      arbIndex: activeTab
                                    })}
                                  </th>
                                  <th className="p-3 rounded-tr-lg rounded-br-lg bg-base-gray font-normal text-left text-sm text-label-grey">
                                    {' '}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {arbitratorsDetails?.filter((detail: DisputeArbitrator) => detail.arbIndex === activeTab).map((detail, index) => (
                                <tr key={`${detail?.odrConArbId} - ${index} - ${activeTab}`}>
                                    <td className="border-b p-3 font-normal text-xs">
                                    {isArbitratorEdit ? (
                                        <Fragment>
                                          <input
                                            type="text"
                                            value={(editingRowIndex.rowIndex === index && editingRowIndex.arbIndex === activeTab) ? arbitratorSearchTerm : detail.arbitratorName}
                                            onChange={(e) => handleArbitratorSearch(e, index)}
                                            className="text-xs py-3 sm:w-72 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg"
                                          />
                                          {filteredArbitrators.length > 0 && dropdown && editingRowIndex.rowIndex === index && editingRowIndex.arbIndex === activeTab && (
                                            <ul className="origin-top-right absolute w-fit rounded-lg bg-white text-xs px-1 py-2">
                                              {filteredArbitrators?.map((item: ODRConArbitrator, filteredIndex: number) => (
                                                <li
                                                  className="px-3 py-1 hover:bg-back-gray rounded-lg cursor-pointer"
                                                  key={item?.userName} onClick={() => {
                                                    setArbitratorSearchTerm(item.userName)
                                                    handleInputChange(index, 'arbitratorName', item.userName)
                                                    handleInputChange(index, 'odrConArbId', item.odrConArbId)
                                                    handleInputChange(index, 'arbitratorPAN', item.panNumber)
                                                    setFilteredArbitrators([])
                                                    setDropdown(false)
                                                  }}>
                                                  {item.userName}
                                                </li>
                                              ))}
                                            </ul>
                                          )}
                                        </Fragment>
                                      ) : (
                                        <span>{detail.arbitratorName}</span>
                                      )}
                                    </td>
                                    <td className="border-b p-3 font-normal text-xs">
                                      {isArbitratorEdit ? (
                                        <input
                                          type="date"
                                          required
                                          value={detail.arbitratorAppointedDate}
                                          onChange={(e) =>
                                            handleInputChange(index, 'arbitratorAppointedDate', e.target.value)
                                          }
                                          className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg"
                                        />
                                      ) : (
                                        <span>{dateHelper(detail.arbitratorAppointedDate)}</span>
                                      )}
                                    </td>
                                    <td className="border-b p-3 font-normal text-xs">
                                      {isArbitratorEdit ? (
                                        <select onChange={(e) =>
                                          handleInputChange(index, 'isApproved', e.target.value)
                                        }
                                          value={
                                            detail.isApproved === null ? '' :
                                              detail.isApproved === true || detail.isApproved === 'Yes' ? 'Yes' : 'No'
                                          }
                                          aria-label={'IsApproved'} name='isApproved' id='isApproved'
                                          className="text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg"
                                        >
                                          <option selected disabled value="">{t('Select')}</option>
                                          <option value="Yes">Yes</option>
                                          <option value="No">No</option>
                                        </select>
                                      ) : (
                                        <span>{detail.isApproved === null ? '' : ((detail.isApproved === true || detail.isApproved === 'Yes') ? 'Yes' : 'No')}</span>
                                      )}
                                    </td>
                                    <td className="border-b p-3 font-normal text-xs">
                                      <span>{detail.isReappointed === null ? '' : ((detail.isReappointed === true || detail.isReappointed === 'Yes') ? 'Yes' : 'No')}</span>
                                    </td>
                                    <td className="border-b p-3 font-normal text-xs">
                                      {isArbitratorEdit ? (
                                        <input
                                          type="date"
                                          value={detail.arbitratorRejectionDate}
                                          min={detail?.arbitratorAppointedDate}
                                          max={new Date().toString()}
                                          disabled={detail.isApproved === null || detail.isApproved === true || detail.isApproved === "Yes"} // Disable if isApproved is false or "No"
                                          onChange={(e) =>
                                            handleInputChange(index, 'arbitratorRejectionDate', e.target.value)
                                          }
                                          className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg"
                                        />
                                      ) : (
                                        <span>{dateHelper(detail.arbitratorRejectionDate)}</span>
                                      )}
                                    </td>
                                    <td className="border-b p-3 font-normal text-xs">
                                      {isArbitratorEdit ? (
                                        <input
                                          type="date"
                                          value={detail.arbitratorAcceptanceDate}
                                          min={detail?.arbitratorAppointedDate}
                                          max={new Date().toString()}
                                          disabled={detail.isApproved === null || detail.isApproved === false || detail.isApproved === "No"} // Disable if isApproved is false or "No"
                                          onChange={(e) =>
                                            handleInputChange(index, 'arbitratorAcceptanceDate', e.target.value)
                                          }
                                          className="text-xs py-3 sm:w-2/3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg"
                                        />
                                      ) : (
                                        <span>{dateHelper(detail.arbitratorAcceptanceDate)}</span>
                                      )}
                                    </td>
                                    <td className="border-b p-3 font-normal text-xs">
                                      {isArbitratorEdit ? (
                                        <input
                                          type="number"
                                          step={0.01}
                                          min={0}
                                          value={detail.feesPaid}
                                          onChange={(e) =>
                                            handleInputChange(index, 'feesPaid', e.target.value)
                                          }
                                          className="text-xs py-3 text-label-grey pl-3 pr-7 border border-border-grey rounded-lg"
                                        />
                                      ) : (
                                        <span>₹{detail.feesPaid}</span>
                                      )}
                                    </td>
                                    <td className="border-b p-3 font-normal text-xs">
                                      <i className="ph ph-dots-three-vertical"></i>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          {isArbitratorEdit && (
                            <div className="flex w-full justify-end">
                              <button
                                type="button"
                                onClick={handleAddRow}
                                className="text-reports-light-grey text-xs hover:underline"
                              >
                                + {t('AddRow')}
                              </button>
                            </div>
                          )}
                        </div>
                      </form>
                      {arbitrationReport?.arbitratorType === 'Panel' &&
                        <div className='flex gap-2 items-center w-full justify-center'>
                          <div onClick={() => setActiveTab(1)} className={`w-2.5 h-2.5 transition-all duration-300 origin-left cursor-pointer ${activeTab === 1 ? 'bg-document-blue' : 'bg-base-gray'}`} style={{ borderRadius: '50%' }}>{" "}</div>
                          <div onClick={() => setActiveTab(2)} className={`w-2.5 h-2.5 transition-all duration-300 origin-left cursor-pointer ${activeTab === 2 ? 'bg-document-blue' : 'bg-base-gray'}`} style={{ borderRadius: '50%' }}>{" "}</div>
                          <div onClick={() => setActiveTab(3)} className={`w-2.5 h-2.5 transition-all duration-300 origin-left cursor-pointer ${activeTab === 3 ? 'bg-document-blue' : 'bg-base-gray'}`} style={{ borderRadius: '50%' }}>{" "}</div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </>
      )}
    </div>
  );
}

export default OutcomeDetails