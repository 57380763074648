import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useCombinedStore } from '../../../store';

interface PreConciliationReportProps {
  disputeId: any;
  claimAmount: number;
  status: string;
  isEditDisabled: boolean;
  outcome: string;
}

function PreConciliationReport({ disputeId, claimAmount, status, isEditDisabled, outcome }: PreConciliationReportProps) {

  const { t } = useTranslation();
  const currentURL = window.location.href.split('/')[3]

  const [isOutcomedetailsEdit, setIsOutcomedetailsEdit] = useState<boolean>(false)
  const [preConOutcome, setPreconOutcome] = useState<string>('')
  const updatePreConOutcome = useCombinedStore((state: any) => state.updatePreConOutcome);

  const saveOutComeDetails = (detailType: string, e: any) => {
    if (e) {
      e.preventDefault();
    }
    if (disputeId) {
      updatePreConOutcome(disputeId, preConOutcome)
    }
    setIsOutcomedetailsEdit(false)
  }


  useEffect(() => {
    setPreconOutcome(outcome)
  }, [outcome])

  return (
    <div className="hidden sm:flex flex-col gap-4">
      {/* Process Details  */}
      <form onSubmit={(e) => saveOutComeDetails('Process Details', e)}>
        <div className="flex flex-col gap-4 pl-8 pb-4 border-b border-border-grey">
          <div className="flex items-center justify-between">
            <p className="sm:text-sm text-xs font-semibold text-highlight-blue">{t('ProcessDetails')}</p>
          </div>
          <div>
            <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
              <p className="text-reports-light-grey w-1/2">{t('ClaimAmount')}</p>
              <p className='text-highlight-blue w-1/2'>₹{claimAmount}</p>
            </div>

          </div>
        </div>
      </form>
      {/* Outcome Details  */}
      <form onSubmit={(e) => saveOutComeDetails('Outcome Details', e)}>
        <div className="flex flex-col gap-4 pl-8 pb-4">
          <div className="flex items-center justify-between">
            <p className="sm:text-sm text-xs font-semibold text-highlight-blue">{t('OutcomeDetails')}</p>
            <div className='flex gap-2'>
              {(status == 'AWAITING_MII_REVIEW' || status === 'DISPUTE_RESOLVED_-_PRECONCILIATION') && currentURL === 'mii' && isEditDisabled &&
                <button type="button" className="text-xs text-label-grey pl-3 pr-2"
                  onClick={(e: any) => setIsOutcomedetailsEdit(true)}>{t('Edit')}</button>
              }
              {isOutcomedetailsEdit &&
                <button type="submit" className="text-xs text-label-blue pl-3 pr-2">{t('Save')}</button>
              }
            </div>
          </div>
          <div>
            {!isOutcomedetailsEdit &&
              <>
                <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                  <p className="text-reports-light-grey w-1/2">{t('PreConciliationOutcome')}</p>
                  <p className='text-highlight-blue w-1/2'>{preConOutcome}</p>
                </div>
              </>
            }
          </div>
          <div className="flex flex-col w-full h-fit gap-14 overflow-hidden">
            <div className="flex flex-col gap-4">
              {isOutcomedetailsEdit &&
                <>
                  {currentURL === 'mii' &&
                    <div className="flex sm:flex-row flex-col gap-2 justify-between items-center w-full">
                      <p className="text-sm text-reports-light-grey w-1/2">{t('PreConciliationOutcome')}</p>
                      <select
                        aria-label={t('PreConciliationOutcome')} required
                        className="sm:w-full text-xs p-2 border border-back-gray bg-white rounded-xl"
                        onChange={(e: any) => setPreconOutcome(e.target.value)} value={preConOutcome} name="preConOutcome" id="preConOutcome"
                      >
                        <option disabled selected value={""}>Select</option>
                        <option value={'In favour of Applicant'}>{t('InfavourofApplicant')}</option>
                        <option value={'Dispute Withdrawn'}>{t('DisputeWithdrawn')}</option>
                        <option value={'Matter Dismissed'}>{t('MatterDismissed')}</option>
                        <option value={'Mutual Settlement'}>{t('MutualSettlement')}</option>
                      </select>
                    </div>
                  }
                </>
              }
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default PreConciliationReport