/* tslint:disable */
/* eslint-disable */
/**
 * OCP Module
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.0.0
 * Contact: shraddha@odrways.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ListConArbOutput } from '../models';
import { ListConArbParams } from '../models';
import { ODRConArbitrator } from '../models';
/**
 * ConArbApi - axios parameter creator
 * @export
 */
export const ConArbApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a all arbitrator profile
         * @param {ListConArbParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allConArbitrators: async (body: ListConArbParams, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling allConArbitrators.');
            }
            const localVarPath = `/admin/conarb-management/all_con_arbitrators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a all arbitrator profile
         * @param {string} userType 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conArbitrators: async (userType: string, search?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userType' is not null or undefined
            if (userType === null || userType === undefined) {
                throw new RequiredError('userType','Required parameter userType was null or undefined when calling conArbitrators.');
            }
            const localVarPath = `/admin/conarb-management/con_arbitrators`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (userType !== undefined) {
                localVarQueryParameter['userType'] = userType;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new arbitrator profile
         * @param {ODRConArbitrator} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConArbitrator: async (body: ODRConArbitrator, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createConArbitrator.');
            }
            const localVarPath = `/admin/conarb-management/con_arbitrator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a new arbitrator profile
         * @param {number} odrConArbId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConArbitrator: async (odrConArbId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'odrConArbId' is not null or undefined
            if (odrConArbId === null || odrConArbId === undefined) {
                throw new RequiredError('odrConArbId','Required parameter odrConArbId was null or undefined when calling deleteConArbitrator.');
            }
            const localVarPath = `/admin/conarb-management/con_arbitrator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (odrConArbId !== undefined) {
                localVarQueryParameter['odrConArbId'] = odrConArbId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a arbitrator profile
         * @param {number} odrConArbId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConArbitrator: async (odrConArbId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'odrConArbId' is not null or undefined
            if (odrConArbId === null || odrConArbId === undefined) {
                throw new RequiredError('odrConArbId','Required parameter odrConArbId was null or undefined when calling getConArbitrator.');
            }
            const localVarPath = `/admin/conarb-management/con_arbitrator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (odrConArbId !== undefined) {
                localVarQueryParameter['odrConArbId'] = odrConArbId;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a new arbitrator profile
         * @param {ODRConArbitrator} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConArbitrator: async (body: ODRConArbitrator, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateConArbitrator.');
            }
            const localVarPath = `/admin/conarb-management/con_arbitrator`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Token required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                query.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof body !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConArbApi - functional programming interface
 * @export
 */
export const ConArbApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a all arbitrator profile
         * @param {ListConArbParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async allConArbitrators(body: ListConArbParams, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListConArbOutput>> {
            const localVarAxiosArgs = await ConArbApiAxiosParamCreator(configuration).allConArbitrators(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a all arbitrator profile
         * @param {string} userType 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async conArbitrators(userType: string, search?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ODRConArbitrator>>> {
            const localVarAxiosArgs = await ConArbApiAxiosParamCreator(configuration).conArbitrators(userType, search, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create a new arbitrator profile
         * @param {ODRConArbitrator} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConArbitrator(body: ODRConArbitrator, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ODRConArbitrator>> {
            const localVarAxiosArgs = await ConArbApiAxiosParamCreator(configuration).createConArbitrator(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a new arbitrator profile
         * @param {number} odrConArbId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConArbitrator(odrConArbId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ConArbApiAxiosParamCreator(configuration).deleteConArbitrator(odrConArbId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a arbitrator profile
         * @param {number} odrConArbId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConArbitrator(odrConArbId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ODRConArbitrator>> {
            const localVarAxiosArgs = await ConArbApiAxiosParamCreator(configuration).getConArbitrator(odrConArbId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update a new arbitrator profile
         * @param {ODRConArbitrator} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConArbitrator(body: ODRConArbitrator, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ODRConArbitrator>> {
            const localVarAxiosArgs = await ConArbApiAxiosParamCreator(configuration).updateConArbitrator(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ConArbApi - factory interface
 * @export
 */
export const ConArbApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get a all arbitrator profile
         * @param {ListConArbParams} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allConArbitrators(body: ListConArbParams, options?: any): AxiosPromise<ListConArbOutput> {
            return ConArbApiFp(configuration).allConArbitrators(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a all arbitrator profile
         * @param {string} userType 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        conArbitrators(userType: string, search?: string, options?: any): AxiosPromise<Array<ODRConArbitrator>> {
            return ConArbApiFp(configuration).conArbitrators(userType, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new arbitrator profile
         * @param {ODRConArbitrator} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConArbitrator(body: ODRConArbitrator, options?: any): AxiosPromise<ODRConArbitrator> {
            return ConArbApiFp(configuration).createConArbitrator(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a new arbitrator profile
         * @param {number} odrConArbId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConArbitrator(odrConArbId: number, options?: any): AxiosPromise<void> {
            return ConArbApiFp(configuration).deleteConArbitrator(odrConArbId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a arbitrator profile
         * @param {number} odrConArbId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConArbitrator(odrConArbId: number, options?: any): AxiosPromise<ODRConArbitrator> {
            return ConArbApiFp(configuration).getConArbitrator(odrConArbId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a new arbitrator profile
         * @param {ODRConArbitrator} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConArbitrator(body: ODRConArbitrator, options?: any): AxiosPromise<ODRConArbitrator> {
            return ConArbApiFp(configuration).updateConArbitrator(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConArbApi - object-oriented interface
 * @export
 * @class ConArbApi
 * @extends {BaseAPI}
 */
export class ConArbApi extends BaseAPI {
    /**
     * 
     * @summary Get a all arbitrator profile
     * @param {ListConArbParams} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConArbApi
     */
    public allConArbitrators(body: ListConArbParams, options?: any) {
        return ConArbApiFp(this.configuration).allConArbitrators(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get a all arbitrator profile
     * @param {string} userType 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConArbApi
     */
    public conArbitrators(userType: string, search?: string, options?: any) {
        return ConArbApiFp(this.configuration).conArbitrators(userType, search, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Create a new arbitrator profile
     * @param {ODRConArbitrator} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConArbApi
     */
    public createConArbitrator(body: ODRConArbitrator, options?: any) {
        return ConArbApiFp(this.configuration).createConArbitrator(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Delete a new arbitrator profile
     * @param {number} odrConArbId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConArbApi
     */
    public deleteConArbitrator(odrConArbId: number, options?: any) {
        return ConArbApiFp(this.configuration).deleteConArbitrator(odrConArbId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Get a arbitrator profile
     * @param {number} odrConArbId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConArbApi
     */
    public getConArbitrator(odrConArbId: number, options?: any) {
        return ConArbApiFp(this.configuration).getConArbitrator(odrConArbId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @summary Update a new arbitrator profile
     * @param {ODRConArbitrator} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConArbApi
     */
    public updateConArbitrator(body: ODRConArbitrator, options?: any) {
        return ConArbApiFp(this.configuration).updateConArbitrator(body, options).then((request) => request(this.axios, this.basePath));
    }
}
