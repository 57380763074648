import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { Link, useNavigate, useParams } from "react-router-dom"
import { newConArbValidationSchema } from "../../../helpers/validation"
import { useCombinedStore } from "../../../store"
import { ODRConArbitrator } from "../../../swagger/models"
import { useTranslation } from 'react-i18next';
import moment from "moment";

function ConArbitratorSettings() {
  const { t } = useTranslation();
  const [conArbDetail, setConArbDetails] = useState<ODRConArbitrator>({})
  const params = useParams()
  const odrConArbId: string = params.odrConArbId!
  const navigate = useNavigate()

  const conArbProfile = useCombinedStore((state: any) => state.conArbProfile)
  const addConArbProfile = useCombinedStore((state: any) => state.addConArbProfile)
  const updateConArbProfile = useCombinedStore((state: any) => state.updateConArbProfile)
  const getConArbProfile = useCombinedStore((state: any) => state.getConArbProfile)
  const currentURL = window.location.href.split('/')[3]

  const formOptions = {
    resolver: yupResolver(newConArbValidationSchema),
  }

  const { register, handleSubmit, formState, reset } = useForm(formOptions)
  const { errors } = formState

  useEffect(() => {
    if (odrConArbId) {
      getConArbProfile(odrConArbId);
    }
  }, [odrConArbId]);

  /**
   * 
   * @param e 
   */
  const onInputChange = (e: any) => {
    const { name, value } = e.target
    setConArbDetails({
      ...conArbDetail,
      [name]: value,
    })
  }

  /**
   * 
   * @param e 
   * @returns 
   */
  async function onSubmit(e: any) {
    if (conArbDetail.odrConArbId) {
      updateConArbProfile(conArbDetail)
    } else {
      addConArbProfile(conArbDetail)
    }
    return false
  }

  const redirect = () => {
    navigate(`/${currentURL}/settings/con-arb-list`)
  }


  useEffect(() => {
    if (conArbProfile && conArbProfile.odrConArbId && odrConArbId) {
      setConArbDetails(conArbProfile)
      reset(conArbProfile)
    }
  }, [conArbProfile])
  return (
    <div className="pt-7 w-full h-full pr-4 pl-1 pb-7">
      <div
        style={{ background: '#F4F4F4' }}
        className="flex w-full h-full flex-initial  px-8 py-7 flex-col gap-2 rounded-2xl">
        <p className='hidden sm:block text-xs text-back-gray'><Link to={`/${currentURL}/settings`}>{t("Settings")}</Link> &gt;
          <Link to={`/${currentURL}/settings/con-arb-list`}>{t('UserManagement')}</Link> &gt;
          {t('NeutralManagement')}</p>
        <h1 className="sm:text-3xl text-lg font-semibold text-highlight-blue pb-3">{t('Settings')}</h1>
        <div className="bg-white w-full flex flex-col rounded-xl gap-4">
          <Link to={`/${currentURL}/settings/con-arb-list`} className="py-3 border-b border-F5F5F5 text-xs pl-7 w-fit">{t('Back')}</Link>
          <div className="flex flex-row pb-4 gap-3 pl-7 border-b border-F5F5F5">
            <i className="ph-bold ph-user-circle-plus sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
            <div className="flex flex-col sm:gap-2 gap-1">
              <p className="sm:text-sm text-xs">{odrConArbId && conArbDetail?.userName}</p>
              <p className="sm:text-xs text-[10px] text-back-gray">{odrConArbId && conArbDetail?.userName}</p>
              {!conArbDetail?.odrConArbId && <p className="text-sm">{t('AddConciliator')}</p>}
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} >
            <div className="flex flex-col gap-2 px-7 sm:w-1/2">
              <label htmlFor="userName" className="text-xs">{t('Name')} <span className="text-highlight-red">*</span> </label>
              <input {...register('userName')} onChange={onInputChange} value={conArbDetail?.userName}
                autoComplete="off" // Try "off", "new-password", or "nope"
                required aria-label={t('Name')} type="text" name='userName' id='userName' className='rounded-xl border p-2 text-xs border-border-grey border-solid' />
              <span className="text-xs text-highlight-red">{errors?.userName?.message}</span>

              <label htmlFor="UserType" className="text-xs">{t('UserType')} <span className="text-highlight-red">*</span> </label>
              <select
                required
                aria-label={t('SelectType')} name='userType' id='userType' onChange={onInputChange}
                className="rounded-lg border p-3 border-border-grey border-solid text-xs" value={conArbDetail?.userType}
              >
                <option value={""} disabled selected>{t('Select')}</option>
                <option value={'Conciliator'}>{t('Conciliator')}</option>
                <option value={'Arbitrator'}>{t('Arbitrator')}</option>
              </select>

              <label htmlFor="PAN" className="text-xs">{t('PANNumber')} <span className="text-highlight-red">*</span> </label>
              <input {...register('panNumber')} required onChange={onInputChange} value={conArbDetail?.panNumber}
                autoComplete="off" // Try "off", "new-password", or "nope"
                aria-label={t('PANNumber')} type="text" name='panNumber' id='panNumber' className={`rounded-xl border p-2 text-xs border-solid ${errors?.panNumber?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors?.panNumber?.message}</span>

              <label htmlFor="dateOfBirth" className="text-xs">{t('DateOfBirth')}</label>
              <input {...register('dateOfBirth')}
                onChange={onInputChange}
                max={moment().add(-18, 'years').format('YYYY-MM-DD')}
                value={conArbDetail?.dateOfBirth} aria-label={t('DateOfBirth')} type="date" name='dateOfBirth' id='dateOfBirth'
                className={`rounded-xl border p-2 text-xs border-solid ${errors?.dateOfBirth?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors?.dateOfBirth?.message}</span>

              <label htmlFor="dateOfEmpanelment" className="text-xs">{t('DateOfEmpanelment')} <span className="text-highlight-red">*</span></label>
              <input {...register('dateOfEmpanelment')} max={moment().format('YYYY-MM-DD')} min={moment(conArbDetail?.dateOfBirth).format('YYYY-MM-DD')} onChange={onInputChange} value={conArbDetail?.dateOfEmpanelment} required aria-label={t('DateOfEmpanelment')} type="date" name='dateOfEmpanelment' id='dateOfEmpanelment' className={`rounded-xl border p-2 text-xs border-border-grey border-solid ${conArbDetail?.odrConArbId ? 'disabled: bg-disable-grey' : ''}`} />
              <span className="text-xs text-highlight-red">{errors?.dateOfEmpanelment?.message}</span>

              <label htmlFor="dateOfLeaving" className="text-xs">{t('DateOfLeaving')}</label>
              <input  {...register('dateOfLeaving')} max={moment().format('YYYY-MM-DD')} min={moment(conArbDetail?.dateOfEmpanelment).format('YYYY-MM-DD')} onChange={onInputChange} value={conArbDetail?.dateOfLeaving} aria-label={t('dateOfLeaving')} type="date" name='dateOfLeaving' id='dateOfLeaving'
                className={`rounded-xl border p-2 text-xs border-solid ${errors?.dateOfLeaving?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors?.dateOfLeaving?.message}</span>

              <label htmlFor="noOfYearsExp" className="text-xs">{t('NoOfYears')} <span className="text-highlight-red">*</span></label>
              <input required {...register('noOfYearsExp')} onChange={onInputChange}
                autoComplete="off" // Try "off", "new-password", or "nope"
                value={conArbDetail?.noOfYearsExp} aria-label={t('noOfYearsExp')} type="number" step={1} min={0} name='noOfYearsExp' id='noOfYearsExp'
                className={`rounded-xl border p-2 text-xs border-solid ${errors?.noOfYearsExp?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors?.noOfYearsExp?.message}</span>

              <label htmlFor="trainingProvided" className="text-xs">{t('TrainingProvided')} <span className="text-highlight-red">*</span></label>
              <select
                required
                aria-label={t('SelectType')} name='trainingProvided' id='trainingProvided' onChange={onInputChange}
                className="rounded-lg border p-3 border-border-grey border-solid text-xs" value={conArbDetail?.trainingProvided}
              >
                <option value={""} disabled selected>{t('Select')}</option>
                <option value={'true'}>{t('Yes')}</option>
                <option value={'false'}>{t('No')}</option>
              </select>
              <span className="text-xs text-highlight-red">{errors?.trainingProvided?.message}</span>

              <label htmlFor="isNISMCertificationCompleted" className="text-xs">{t('NISMCertificationCompleted')}</label>
              <input  {...register('nismCertificationCompleted')} onChange={onInputChange} value={conArbDetail?.nismCertificationCompleted}
                autoComplete="off" // Try "off", "new-password", or "nope"
                aria-label={t('nismCertificationCompleted')} type="text" name='nismCertificationCompleted' id='nismCertificationCompleted'
                className={`rounded-xl border p-2 text-xs border-solid ${errors?.nismCertificationCompleted?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors?.nismCertificationCompleted?.message}</span>

              <label htmlFor="primarySkillSet" className="text-xs">{t('PrimarySkillSet')} <span className="text-highlight-red">*</span> </label>
              <input  {...register('primarySkillSet')} required onChange={onInputChange} value={conArbDetail?.primarySkillSet}
                autoComplete="off" // Try "off", "new-password", or "nope"
                aria-label={t('primarySkillSet')} type="text" name='primarySkillSet' id='primarySkillSet'
                className={`rounded-xl border p-2 text-xs border-solid ${errors?.primarySkillSet?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors?.primarySkillSet?.message}</span>

              <label htmlFor="secondarySkillSet" className="text-xs">{t('SecondarySkillSet')}</label>
              <input  {...register('secondarySkillSet')} onChange={onInputChange}
                autoComplete="off" // Try "off", "new-password", or "nope"
                value={conArbDetail?.secondarySkillSet} aria-label={t('secondarySkillSet')} type="text" name='secondarySkillSet' id='secondarySkillSet'
                className={`rounded-xl border p-2 text-xs border-solid ${errors?.secondarySkillSet?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors?.secondarySkillSet?.message}</span>

              <label htmlFor="profileURL" className="text-xs">{t('profileURL')}</label>
              <input  {...register('profileURL')} onChange={onInputChange} value={conArbDetail?.profileURL}
                autoComplete="off" // Try "off", "new-password", or "nope"
                aria-label={t('profileURL')} type="text" name='profileURL' id='profileURL'
                className={`rounded-xl border p-2 text-xs border-solid ${errors?.profileURL?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors?.profileURL?.message}</span>

              <label htmlFor="profilePicture" className="text-xs">{t('ProfilePicture')}</label>
              <input  {...register('profilePicture')} onChange={onInputChange} value={conArbDetail?.profilePicture}
                autoComplete="off" // Try "off", "new-password", or "nope"
                aria-label={t('profilePicture')} type="text" name='profilePicture' id='profilePicture'
                className={`rounded-xl border p-2 text-xs border-solid ${errors?.profilePicture?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors?.profilePicture?.message}</span>

              <label htmlFor="dateOfAnnualEvaluation" className="text-xs">{t('DateOfAnnualEvaluation')} <span className="text-highlight-red">*</span> </label>
              <input  {...register('dateOfAnnualEvaluation')} required onChange={onInputChange} value={conArbDetail?.dateOfAnnualEvaluation} aria-label={t('dateOfAnnualEvaluation')} type="date" name='dateOfAnnualEvaluation' id='dateOfAnnualEvaluation'
                className={`rounded-xl border p-2 text-xs border-solid ${errors?.dateOfAnnualEvaluation?.message ? 'border-highlight-red' : 'border-border-grey'}`} />
              <span className="text-xs text-highlight-red">{errors?.dateOfAnnualEvaluation?.message}</span>

            </div>
            <div className="flex flex-row gap-3 pl-7 pt-2">
              <button className="hover:bg-highlight-blue/[0.4] bg-highlight-blue items-center text-white flex flex-row gap-2 text-xs px-12 py-2.5 rounded-lg disabled:bg-highlight-blue/[0.4]" aria-label={t('Save')}>
                 {t('SaveChanges')}
              </button>
              <button onClick={redirect} aria-label={t('cancel')} className=" hover:bg-black/[0.4] text-xs  text-back-gray px-8 py-2.5 rounded-lg">
                {t('cancel')}
              </button>
            </div>
          </form>
          <p className="pb-3 text-xs pl-7">{" "}</p>
        </div>

      </div>
    </div>
  )
}

export default ConArbitratorSettings