import { DisputeArbitrator } from "../../../swagger/models"
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { dateHelper } from "../../common-module/helper";

interface PanelOfArbitratorsProps {
  arbitrators: DisputeArbitrator[]
}

function PanelOfArbitrators({ arbitrators }: PanelOfArbitratorsProps) {
  const { t } = useTranslation();

  console.log(arbitrators?.filter((arbitrator: DisputeArbitrator, index: number) => !arbitrator?.isReappointed)?.
    sort((a: DisputeArbitrator, b: DisputeArbitrator) => a.arbIndex - b.arbIndex))

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 3000,
  };

  return (
    <div className="pt-2 pb-16">
      <div className="rounded-lg px-2 py-2 pb-8 w-full items-center justify-center">
        <Slider {...settings}>
          {arbitrators?.filter((arbitrator: DisputeArbitrator, index: number) => !arbitrator?.isReappointed)
            ?.sort((a: DisputeArbitrator, b: DisputeArbitrator) => a.arbIndex - b.arbIndex)
            ?.map((arbitrator: DisputeArbitrator, index: number) => {
              return (
                <div key={index} className='flex h-fit items-center mt-2'>
                  <div className="flex flex-col justify-between">
                    <div className="relative">
                      {!arbitrator?.isReappointed && <>
                        <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('ArbitratorName')} {arbitrator?.arbIndex}:</p>
                          <p className='text-highlight-blue w-1/2'>{arbitrator?.arbitratorName}</p>
                        </div>
                        {arbitrator?.arbitratorAppointedDate &&
                          <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                            <p className="text-reports-light-grey w-1/2">{t('ArbitratorAppointmentDate')}:</p>
                            <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrator?.arbitratorAppointedDate)}</p>
                          </div>
                        }
                        {arbitrator?.arbitratorRejectionDate &&
                          <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                            <p className="text-reports-light-grey w-1/2">{t('ArbitratorRejectedDate')}:</p>
                            <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrator?.arbitratorRejectionDate)}</p>
                          </div>
                        }
                        {arbitrator?.arbitratorAcceptanceDate &&
                          <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                            <p className="text-reports-light-grey w-1/2">{t('ArbitratorAcceptanceDate')}:</p>
                            <p className='text-highlight-blue w-1/2'>{dateHelper(arbitrator?.arbitratorAcceptanceDate)}</p>
                          </div>
                        }
                        <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                          <p className="text-reports-light-grey w-1/2">{t('FeesPaidArbitrator', {
                            arbIndex: arbitrator.arbIndex
                          })}:</p>
                          <p className='text-highlight-blue w-1/2'>₹{arbitrator?.feesPaid}</p>
                        </div>
                      </>
                      }
                      {/* {arbitrator?.Replacements?.map((replacement: DisputeArbitrator, index: number) => {
                        return <>
                          <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                            <p className="text-reports-light-grey w-1/2">{t('ArbitratorReappointed')} {index + 1}:</p>
                            <p className='text-highlight-blue w-1/2'>Yes</p>
                          </div>
                          <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                            <p className="text-reports-light-grey w-1/2">{t('ArbitratorReappointedName')} {index + 1}:</p>
                            <p className='text-highlight-blue w-1/2'>{replacement?.arbitratorName}</p>
                          </div>
                          <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                            <p className="text-reports-light-grey w-1/2">{t('ArbitratorAcceptanceDate')} {index + 1}:</p>
                            <p className='text-highlight-blue w-1/2'>{dateHelper(replacement?.arbitratorAcceptanceDate)}</p>
                          </div>
                          <div className='flex items-center gap-8 pb-4 sm:text-sm w-full text-xs'>
                            <p className="text-reports-light-grey w-1/2">{t('ArbitratorRejectedDate')} {index + 1}:</p>
                            <p className='text-highlight-blue w-1/2'>{dateHelper(replacement?.arbitratorRejectionDate)}</p>
                          </div>
                          <div className='flex items-center gap-8 sm:text-sm w-full text-xs'>
                            <p className="text-reports-light-grey w-1/2">{t('FeesPaidArbitrator', {
                              arbIndex: arbitrator.arbIndex
                            })}:</p>
                            <p className='text-highlight-blue w-1/2'>₹{arbitrator?.feesPaid}</p>
                          </div>
                        </>
                      })} */}
                    </div>
                  </div>
                </div>
              )
            })}
        </Slider>
      </div>
    </div>
  )
}

export default PanelOfArbitrators;