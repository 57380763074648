import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useCombinedStore } from '../../../store';
import { Link } from 'react-router-dom';
import { Tooltip } from "react-tippy";
import { ArrowLeft, ArrowRight } from 'phosphor-react';
import { ListConArbParams, ODRConArbitrator } from '../../../swagger/models';
import _ from "lodash";

function ConArbitrators() {
  const { t } = useTranslation();
  const conciliatorArbitrators: ODRConArbitrator[] = useCombinedStore((state: any) => state.conciliatorArbitrators);
  const listConciliatorArbitrators = useCombinedStore((state: any) => state.listConciliatorArbitrators);
  const conArbCount = useCombinedStore((state: any) => state.conArbCount);
  const deleteConArbProfile = useCombinedStore((state: any) => state.deleteConArbProfile);
  const currentURL = window.location.href.split('/')[3]
  const [conArbParams, setConArbParams] = useState<ListConArbParams>({});
  const delayedQuery = useRef(_.debounce((conArbParams) => listConciliatorArbitrators(conArbParams), 500)).current;

  const [limit, setLimit] = useState<number>(10)
  const [page, setPage] = useState<number>(1)
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const totalPages = Math.ceil(conArbCount / limit);
  const maxPageButtons = 5;

  let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
  let endPage = startPage + maxPageButtons - 1;

  if (endPage > totalPages) {
    endPage = totalPages;
    startPage = Math.max(1, endPage - maxPageButtons + 1);
  }

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      setPage(newPage);
    }
  };

  const searchUser = (e: any) => {
    setConArbParams({
      ...conArbParams,
      search: e
    })
  }

  useEffect(() => {
    setLoading(true);
    delayedQuery(conArbParams);
  }, [conArbParams])

  const deleteUser = (odrConArbId: number) => {
    deleteConArbProfile(odrConArbId)
  }

  useEffect(() => {
    setConArbParams({
      ...conArbParams,
      limit: limit,
      offset: (page - 1) * limit,
    })
  }, [page, limit]);

  useEffect(() => {
    if (conciliatorArbitrators.length > 0) {
      setLoading(false);
    }
  }, [conciliatorArbitrators])

  return (
    <div className="pt-7 w-full h-full pr-4 pl-1 pb-7">
      <div
        style={{ background: '#F4F4F4' }}
        className="flex w-full h-full flex-initial  px-8 py-7 flex-col gap-2 rounded-2xl">
        <p className='hidden sm:block text-xs text-back-gray'>
          <Link to={`/${currentURL}/settings`}>{t('Settings')}</Link> &gt; {t('NeutralManagement')}</p>
        <h1 className="sm:text-3xl text-lg font-semibold text-highlight-blue pb-3">{t('Settings')}</h1>
        <div className="bg-white w-full h-full flex flex-col rounded-xl gap-7">
          <Link to={`/${currentURL}/settings`} className="py-3 border-b border-F5F5F5 text-xs pl-7 w-fit">{t('Back')}</Link>
          <div className="flex flex-row pb-4 gap-3 pl-7 border-b border-F5F5F5">
            <i className="ph-bold ph-user-circle-plus sm:p-3 p-2 border border-F5F5F5 rounded-full"></i>
            <div className="flex flex-col sm:gap-2 gap-1">
              <p className="sm:text-sm text-xs">{t('NeutralManagement')}</p>
              <p className="sm:text-xs text-[10px] text-back-gray">{t('AdditionalConArbs')}</p>
            </div>
          </div>
          <div className="flex sm:flex-row flex-col sm:justify-between px-7">
            <div className="flex flex-row sm:w-1/2 rounded-xl border border-border-grey pl-2">
              <i className="ph-bold ph-magnifying-glass p-2 "></i>
              <input onChange={(e) => searchUser(e.target.value)} type="text" name="search" id="search" aria-label={t('search')} className="text-xs rounded-xl w-full py-2.5 px-2" />
            </div>
            <Link to="con-arb-settings" className="text-xs text-back-gray pr-7 pt-3 hover:text-black/60 cursor-pointer">{t('AddConciliator')}</Link>
          </div>
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                  {t('UserName')}
                </th>
                <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                  {t('UserType')}
                </th>
                <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                  {t('PANNumber')}
                </th>
                <th className="border-b p-3 font-normal text-left text-sm text-label-grey">
                </th>
              </tr>
            </thead>
            <tbody>
              {conciliatorArbitrators?.map((user: ODRConArbitrator) => {
                return (
                  <tr key={user?.odrConArbId}>
                    <td className="border-b flex flex-col p-3 font-normal text-xs">
                      <p>{user?.userName} </p>
                    </td>
                    <td className="border-b p-3 font-normal text-xs ">
                      <p>{user?.userType}</p>
                    </td>
                    <td className="border-b p-3 font-normal text-xs ">
                      <p>{user?.panNumber}</p>
                    </td>
                    <td className="border-b font-normal text-xs">
                      <Tooltip
                        title={t('EditConciliator')}
                        position="bottom"
                        arrow={false}
                        animation="shift"
                        size="small"
                        {...({ children: <Link to={`con-arb-settings/${user?.odrConArbId}`}><i className="ph-bold ph-pencil-simple-line cursor-pointer hover:text-border-grey mr-4 text-base"></i></Link> } as any)} // Type assertion to bypass TS errors
                      >
                      </Tooltip>
                      <Tooltip
                        title={t('DeleteConciliator')}
                        position="bottom"
                        arrow={false}
                        animation="shift"
                        size="small"
                        {...({ children: <i className="ph-bold cursor-pointer text-base hover:text-border-grey ph-user-circle-minus" onClick={(e) => deleteUser(user?.odrConArbId)}></i> } as any)} // Type assertion to bypass TS errors
                      >
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="flex items-center justify-between w-full py-4 px-5">
            <button
              className="outline-none flex items-center gap-2 text-sm text-gray-700 hover:text-gray-900 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <i className="ph ph-caret-left"></i>
              <p>{t('Previous')}</p>
            </button>
            <div className="flex gap-2">
              {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((pageNum) => (
                <div
                  key={pageNum}
                  className={`w-8 h-8 text-sm rounded-lg flex items-center justify-center text-gray-700 font-semibold
                                    hover:bg-gray-50 transition-all duration-300 cursor-pointer
                                    ${pageNum === currentPage ? 'bg-gray-200' : 'bg-gray-50'}`}
                  onClick={() => handlePageChange(pageNum)}
                  aria-label={`Page ${pageNum}`}
                >
                  {pageNum}
                </div>
              ))}
            </div>
            <button
              className="outline-none flex items-center gap-2 text-sm text-gray-700 hover:text-gray-900 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <p>{t('Next')}</p>
              <i className="ph ph-caret-right"></i>
            </button>
          </div>
        </div>

      </div>
    </div>
  )
}

export default ConArbitrators